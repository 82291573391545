import * as React from "react";
import styled from "styled-components";
import {useTranslation} from "@unibuddy/intl";
import {VisuallyHidden} from "@unibuddy/patron";
import {Link} from "react-router-dom";
import omit from "lodash/omit";
import {IntlNamespaces} from "../../translations/types";
import {INTL_OPENS_IN_A_NEW_WINDOW} from "../../translations/common";

type ThemedLinkProps = {
    external?: boolean;
    to?: string;
    hideUnderline?: boolean;
} & React.AnchorHTMLAttributes<React.ReactNode>;

const StyledATag = styled.a<{$hideUnderline?: boolean}>`
    word-break: break-word;
    text-decoration: ${(props) => (props.$hideUnderline ? "none" : "underline")};
    color: ${({theme}) => theme.colors.universityColor};

    &:hover {
        color: ${({theme}) => theme.colors.universityHoverColor};
    }

    &:hover,
    &:focus {
        text-decoration: underline;
    }

    &:active {
        color: ${({theme}) => theme.colors.universityColor};
        opacity: 0.8;
    }
`;

const StyledLinkTag = styled(Link)<{$hideUnderline?: boolean}>`
    word-break: break-word;
    text-decoration: ${(props) => (props.$hideUnderline ? "none" : "underline")};
    color: ${({theme}) => theme.colors.universityColor};

    &:hover {
        color: ${({theme}) => theme.colors.universityHoverColor};
    }

    &:hover,
    &:focus {
        text-decoration: underline;
    }

    &:active {
        color: ${({theme}) => theme.colors.universityColor};
        opacity: 0.8;
    }
`;

export const ThemedLink: React.FC<ThemedLinkProps> = React.memo((props) => {
    const {t} = useTranslation(IntlNamespaces.COMMON);
    const anchorProps = React.useMemo(() => omit(props, ["external", "to"]), [props]);

    return props.external ? (
        <StyledATag
            data-test-id="a-tag"
            {...anchorProps}
            href={props.to}
            rel="noreferrer noopener"
            target="_blank"
            $hideUnderline={props.hideUnderline}
        >
            {props.children}
            <VisuallyHidden>
                {t(INTL_OPENS_IN_A_NEW_WINDOW.key, INTL_OPENS_IN_A_NEW_WINDOW.defaultValue)}
            </VisuallyHidden>
        </StyledATag>
    ) : (
        <StyledLinkTag
            data-test-id="link-tag"
            {...anchorProps}
            to={props.to || ""}
            $hideUnderline={props.hideUnderline}
        >
            {props.children}
        </StyledLinkTag>
    );
});

ThemedLink.displayName = "ThemedLink";
