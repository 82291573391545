import React, {useState} from "react";
import {Select, Stack, Text, Box} from "@unibuddy/patron";
import {useTranslation} from "@unibuddy/intl";

type FilterDropdownProps = {
    id: string;
    name: string;
    placeholder: string;
    options: {label: string; value: string}[];
    noOptionsMessage: string;
    setValue?: string;
    ariaLabel: string;
    onUpdateFilter: (label: string, value: string) => void;
};

export function FilterDropdown({
    id,
    name,
    placeholder,
    onUpdateFilter,
    ariaLabel,
    options,
    setValue = "",
    noOptionsMessage,
}: FilterDropdownProps) {
    const {t} = useTranslation();
    const optionsDropdown = () => {
        if (options.length > 0) {
            return (
                <>
                    <option value="">{t("noneSelected", "None Selected")}</option>
                    {options.map((val) => (
                        <option value={val.label} key={val.value}>
                            {val.label}
                        </option>
                    ))}
                </>
            );
        }
        return <option value={noOptionsMessage}>{noOptionsMessage}</option>;
    };

    const [option, setOption] = useState(setValue);
    const filterToValMap = new Map(options.map((val) => [val.label, val.value]));
    return (
        <Stack space="xsmall">
            <Box as="label" htmlFor={id} id={name}>
                <Text weight="700">{placeholder}</Text>
            </Box>
            <Select
                value={option}
                id={id}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setOption(e.target.value);
                    onUpdateFilter(e.target.value, filterToValMap.get(e.target.value));
                }}
                aria-label={ariaLabel}
                aria-labelledby={name}
                aria-live="polite"
                formatValue={(e) => e}
            >
                {optionsDropdown()}
            </Select>
        </Stack>
    );
}
