import {MentorField, MentorFilterOptionField} from "../../../../types/generated";

export type InfoArrayProps = {
    text: string;
    numberOfLines?: number;
    color?: string;
};

/**
 * Returns an array of InfoArrayProps based on the provided mentor and other parameters
 * @param mentor - The mentor object.
 * @param isEnterprise - A boolean indicating whether the institution is part of an enterprise.
 * @param universityName - A string containing the university name.
 * @param universityDegreeLevels - An array of degree levels available at the university.
 * @param isDegreeTypesEnabled - A boolean indicating whether the degree types feature is enabled.
 * @returns An array of InfoArrayProps.
 */
export function getInfoArray(
    mentor: MentorField,
    isEnterprise: boolean,
    universityName: string,
    universityDegreeLevels: MentorFilterOptionField[],
    isDegreeTypesEnabled: boolean,
): InfoArrayProps[] {
    let infoArray: InfoArrayProps[] = [];

    const degreeName = !isDegreeTypesEnabled
        ? mentor.degree?.name
            ? mentor.degree.name
            : undefined
        : mentor.degrees && Array.isArray(mentor.degrees)
        ? mentor.degrees
              .filter((d) => d.degreeType === "MAJOR")
              .map((d) => d.degree.name)
              .join(", ")
        : undefined;

    if (degreeName) {
        infoArray.push({
            text: degreeName,
        });
    }
    if (isEnterprise && universityName) {
        infoArray.push({
            text: universityName,
        });
    }
    const degreeLevel = mentor.degreeLevel ? {text: mentor.degreeLevel} : undefined;
    const placeName = mentor.placeName ? {text: mentor.placeName} : undefined;

    if (universityDegreeLevels?.length > 1 && degreeLevel) {
        infoArray.push(degreeLevel);
    }

    if (placeName) {
        infoArray.push(placeName);
    }

    if (infoArray.length >= 2) {
        const updatedInfoArray = [
            infoArray[0],
            {...infoArray[1], color: "grey600", numberOfLines: 1},
        ];
        infoArray = updatedInfoArray;
    }
    return infoArray;
}
