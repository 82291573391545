import React, {useEffect, useState} from "react";
import {Redirect, useHistory, useLocation, useParams} from "react-router-dom";
import startCase from "lodash/startCase";
import queryString from "qs";
import styled from "styled-components";

import {Box, Text} from "@unibuddy/patron";
import {useTranslation} from "@unibuddy/intl";
import {useNumberFeatureFlag, useStringFeatureFlag} from "ub-feature-flagging-react";

import {PoweredByUnibuddy} from "shared/General/components/PoweredByUnibuddy/PoweredByUnibuddy";
import {BUDDYCARD_UI_VERSION, NUMBER_OF_STUDENTS_TO_FETCH} from "shared/constants";
import {rootURL} from "shared/Utils/WhereAmI";
import {ErrorDisplay} from "shared/General/components/ErrorDisplay/ErrorDisplay";
import {ViewMoreLoaderButton} from "shared/General/components/ViewMoreLoaderButton/ViewMoreLoaderButton";
import MentorPlaceholder from "shared/Mentor/components/MentorPlaceholder/MentorPlaceholder";
import useErrorReporting from "shared/General/components/ErrorReporting/useErrorReporting";
import {isFeatureEnabled} from "shared/Utils/FeatureUtils";
import {getParameterByName} from "shared/Utils/UrlUtils";
import useAnalytics from "shared/AnalyticsNew/hooks/useAnalytics";
import {
    itemNames,
    pageNames,
    sharedEvents,
} from "shared/AnalyticsNew/constants/productSpecification";
import SourceTrackingProvider from "shared/SourceTracking/SourceTrackingProvider/SourceTrackingProvider";
import {IntlNamespaces} from "../../../../../translations/types";
import {useUniversity} from "../../../UniversityProvider/UniversityProvider";
import {MentorFilterField, PublicMentorField} from "../../../../../types/generated";
import {PageTitle} from "../../../../../components/PageTitle/PageTitle";
import MentorFilters, {MentorFiltersLoading} from "../../components/MentorFilters/MentorFilters";
import MentorPreviewCard from "../../components/MentorPreviewCard/MentorPreviewCard";
import {StudentListExtraSectionsWrapper} from "../../components/StudentListExtraSectionsWrapper/StudentListExtraSectionsWrapper";
import {
    FilterRawType,
    useSequenceFilterMentorListQuery,
} from "../../queries/sequenceFilterMentorListQuery";
import {WIDGET_PAGE} from "../../../../../widgets/modules/PageProvider/PageTitles";
import {StudentListPageHeader} from "./StudentListHeader";
import {PolaroidBuddycardLoading} from "../../../../../widgets/modules/BuddiesNew/containers/PolaroidBuddycard";

type StudentListParams = {
    degreeId?: string;
    buddyGroupType?: string;
};

const BuddyType = {
    STAFF: "staff",
    STUDENTS: "students",
    ALUMNI: "alumni",
};

const StudentPlaceholder = styled(MentorPlaceholder)`
    @supports (gap: 32px) {
        @media (min-width: 632px) {
            margin: 0;
        }
    }
`;

export const PreviewCardsV2Grid = styled.div`
    display: grid;
    width: 100%;
    gap: 0.75rem;
    margin-bottom: 0.5rem;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    @media (min-width: 632px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`;

const StudentPreviewCardsV3Grid = styled.div`
    display: grid;
    width: 100%;
    gap: 0.75rem;
    margin-bottom: 0.5rem;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    @media (min-width: 375px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (min-width: 632px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
`;

const LogoWrapper = styled(Box)`
    display: none;

    @media (min-width: 632px) {
        display: block;
    }
`;

function StudentListPlaceholders({buddycardVersion}: {buddycardVersion: string}) {
    return (
        <>
            {buddycardVersion === "v3" ? (
                <>
                    {Array.from({length: 6}).map((_, index) => (
                        <PolaroidBuddycardLoading
                            // eslint-disable-next-line react/no-array-index-key
                            key={`mentor-list-placeholder-${index}`}
                        />
                    ))}
                </>
            ) : (
                <>
                    {Array.from({length: 4}).map((_, index) => (
                        <StudentPlaceholder
                            // eslint-disable-next-line react/no-array-index-key
                            key={`mentor-list-placeholder-${index}`}
                            wide
                        />
                    ))}
                </>
            )}
        </>
    );
}

function useMentorFilterState(): {
    filters: FilterRawType[];
    updateMentorFilter: (filterKey: string, label: string, value: string) => void;
} {
    const [filterState, setFilterState] = useState<FilterRawType[]>([]);

    const updateMentorFilter = (filterKey: string, label: string, value: string) => {
        let tempFilters = filterState;
        tempFilters = tempFilters.filter((f) => f.field !== filterKey);

        if (value) {
            tempFilters.push({field: filterKey, value});
        }
        setFilterState(tempFilters);
    };

    return {filters: filterState, updateMentorFilter};
}

export function StudentList() {
    const {reportError} = useErrorReporting();
    const {trackEvent} = useAnalytics();
    const BUDDY_CARD_VERSION = useStringFeatureFlag(
        BUDDYCARD_UI_VERSION.key,
        BUDDYCARD_UI_VERSION.value,
    );
    const STUDENTS_TO_FETCH = useNumberFeatureFlag(
        NUMBER_OF_STUDENTS_TO_FETCH.key,
        NUMBER_OF_STUDENTS_TO_FETCH.value,
    );

    const history = useHistory();
    const {buddyGroupType, degreeId} = useParams<StudentListParams>();
    const location = useLocation();
    const {t} = useTranslation(IntlNamespaces.COMMON);
    const university = useUniversity();

    const {filters: selectedFilters, updateMentorFilter} = useMentorFilterState();
    const isDegreeTypesEnabled = isFeatureEnabled("enableDegreeTypes", university) as boolean;
    const applyDegreeFilter = !!degreeId;
    const {data, loading, error, refetch} = useSequenceFilterMentorListQuery({
        universitySlug: university.slug,
        filters: selectedFilters,
        isDegreeTypesEnabled,
        applyDegreeFilter,
        limit: STUDENTS_TO_FETCH,
        offset: 0,
    });

    const [mentors, setMentors] = useState<PublicMentorField[]>([]);
    const [filters, setFilters] = useState<MentorFilterField[]>([]);
    const [mentorSeed, setMentorSeed] = useState(null);
    const [fetchSize, setFetchSize] = useState(STUDENTS_TO_FETCH);
    const [offset, setOffset] = useState(0);
    const [viewMoreClicked, setViewMoreClicked] = useState(false);

    const isPoweredByUnibuddyClickDisabled = university?.features?.get(
        "widgetPoweredByUnibuddyNotClickable",
    );
    const baseUrl = rootURL(location.pathname);
    const pageTitle = t(buddyGroupType, startCase(buddyGroupType));
    const showLoadButton = fetchSize >= STUDENTS_TO_FETCH;
    const hasFinishedLoading = !loading || mentors.length > 0;

    const getTotalMessages = () => {
        if (buddyGroupType === BuddyType.STAFF) {
            // FIXME: Staff message count is currently not picked so leaving as is for now
            // return university?.staffMessagesCount ?? 0;
            return 0;
        }
        return university?.mentorMessagesCount ?? 0;
    };

    const onUpdateFilter = (
        filter: {id: string; label: string},
        {label: optionLabel, value: optionValue}: {label: string; value: string},
    ) => {
        if (BUDDY_CARD_VERSION) {
            trackEvent(sharedEvents.buddiesFiltered.name, {
                filterKey: filter.label,
                filterValue: optionLabel,
                buddyType: "mentor",
                buddyDesign: BUDDY_CARD_VERSION,
                buddyCardsCount: STUDENTS_TO_FETCH,
            });
        }

        updateMentorFilter(filter.id, optionLabel, optionValue);
        setOffset(0);
    };

    const onOpenChat = (mentorId: string, buddyPosition: number) => {
        const sourceTrackingParams = SourceTrackingProvider.getSourceTrackingParams();
        trackEvent(sharedEvents.buddyCardChatNowClicked.name, {
            universitySlug: university.slug,
            mentorId,
            position: buddyPosition,
            buddyType: "mentor",
            tab: "mentor",
            ...sourceTrackingParams,
        });

        trackEvent(sharedEvents.itemClicked.name, {
            prefix: itemNames.buddyCardChatNow,
            id: mentorId,
            buddyCardsCount: STUDENTS_TO_FETCH,
        });

        const params = new URLSearchParams(window.location.search);
        params.set("buddyPosition", (buddyPosition ?? "").toString());

        history.push(`${baseUrl}/inbox/chatwith/${mentorId}?${params.toString()}`);
    };

    const onSeeMoreClicked = (mentorId: string, buddyPosition?: number) => {
        trackEvent(sharedEvents.buddyCardSeeMoreClicked.name, {
            universitySlug: university.slug,
            buddyType: "mentor",
            tab: WIDGET_PAGE.WIDGET_STUDENTS,
            mentorId,
        });

        const params = new URLSearchParams(window.location.search);
        params.set("buddyPosition", (buddyPosition ?? "").toString());
        history.push(`${baseUrl}/buddies/students/${mentorId}?${params.toString()}`);
    };

    const onAvatarClicked = (mentorId: string, buddyPosition?: number) => {
        trackEvent(sharedEvents.buddyCardAvatarClicked.name, {
            universitySlug: university.slug,
            buddyType: "mentor",
            tab: WIDGET_PAGE.WIDGET_STUDENTS,
            mentorId,
        });
        const params = new URLSearchParams(window.location.search);
        params.set("buddyPosition", (buddyPosition ?? "").toString());
        history.push(`${baseUrl}/buddies/students/${mentorId}?${params.toString()}`);
    };

    const fetchMoreMentors = async (fetchMoreFilters: FilterRawType[]) => {
        try {
            setOffset(mentors.length);

            const {data: refetchData} = await refetch({
                universitySlug: university.slug,
                filters: fetchMoreFilters,
                isDegreeTypesEnabled,
                applyDegreeFilter,
                limit: STUDENTS_TO_FETCH,
                offset: mentors.length,
                mentorSeed,
            });

            const newMentors = (refetchData?.sequenceFilterMentorList?.mentors ??
                []) as PublicMentorField[];
            const fetchedSize = newMentors.length ?? 0;
            const newFilters = (refetchData?.sequenceFilterMentorList?.filters ??
                []) as MentorFilterField[];

            setMentors((prev) => [...prev, ...newMentors]);
            setFetchSize(fetchedSize);
            setFilters(newFilters);

            setViewMoreClicked(true);

            if (BUDDY_CARD_VERSION) {
                trackEvent(sharedEvents.buddiesListViewedMore.name, {
                    buddyType: "mentor",
                    pageNumber: Math.round(mentors.length / STUDENTS_TO_FETCH),
                    buddyDesign: BUDDY_CARD_VERSION,
                    buddyCardsCount: STUDENTS_TO_FETCH,
                });
            }
        } catch (e) {
            reportError(e);
        }
    };

    useEffect(() => {
        if (degreeId) {
            // Course Buddy Platform
            onUpdateFilter({id: "degree", label: "Degree"}, {label: degreeId, value: degreeId});
            trackEvent(sharedEvents.pageViewed.name, {
                prefix: pageNames.courseBuddiesList,
            });
        } else {
            trackEvent(sharedEvents.pageViewed.name, {
                prefix: pageNames.buddiesList,
            });
        }

        const values: {degree: string; filterLabel: string; level: string} = queryString.parse(
            location.search.substring(1),
        );

        if (values.degree) {
            onUpdateFilter(
                {id: "degree", label: values.filterLabel},
                {label: values.degree, value: values.degree},
            );
        }

        if (values.level) {
            onUpdateFilter(
                {id: "degree_level", label: values.filterLabel},
                {label: values.level, value: values.level},
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const template = getParameterByName(location.search, "template") || "unknown";
        const channel = getParameterByName(location.search, "channel") || "unknown";

        if (BUDDY_CARD_VERSION) {
            trackEvent(sharedEvents.buddiesListViewed.name, {
                buddyType: "mentor",
                template,
                channel,
                buddyDesign: BUDDY_CARD_VERSION,
            });
        }
    }, [BUDDY_CARD_VERSION, location.search, trackEvent]);

    useEffect(() => {
        if (data && !loading && !error && offset === 0) {
            const newMentors = (data?.sequenceFilterMentorList?.mentors ??
                []) as PublicMentorField[];
            const fetchedSize = newMentors.length ?? 0;
            const newFilters = (data?.sequenceFilterMentorList?.filters ??
                []) as MentorFilterField[];
            const newMentorSeed = data?.sequenceFilterMentorList?.mentorSeed ?? null;

            setMentors(newMentors);
            setFetchSize(fetchedSize);
            setFilters(newFilters);

            if (!mentorSeed) {
                setMentorSeed(newMentorSeed);
            }
        }
    }, [data, error, loading, mentorSeed, offset]);

    if (![BuddyType.STAFF, BuddyType.STUDENTS, BuddyType.ALUMNI].includes(buddyGroupType)) {
        const path = `/buddies/students/${buddyGroupType}`;
        const url = `${baseUrl}${path}`;

        return <Redirect to={url} />;
    }

    if (data && hasFinishedLoading && mentors.length === 0) {
        return (
            <Box width="100%" display="flex" justifyContent="center">
                <Text>Sorry, no students found.</Text>
            </Box>
        );
    }

    const StudentPreviewGrid =
        BUDDY_CARD_VERSION === "v3" ? StudentPreviewCardsV3Grid : PreviewCardsV2Grid;

    return (
        <ErrorDisplay error={error}>
            <div id="mentors-container">
                {/* HEADER */}
                <PageTitle title={pageTitle} />
                <Box
                    display="flex"
                    flexDirection={["row"]}
                    justifyContent={["space-between"]}
                    alignItems={["flex-start"]}
                    marginBottom="medium-large"
                >
                    <StudentListPageHeader
                        finishedLoading={hasFinishedLoading}
                        buddyGroupType={buddyGroupType}
                        totalMessages={getTotalMessages()}
                        uniLaunchDate={university.launchDate}
                    />

                    <LogoWrapper
                        display={["none", "block"]}
                        marginBottom={["small-medium"]}
                        justifyContent="center"
                        flexShrink={1}
                        minW={"166px"}
                    >
                        <PoweredByUnibuddy redirectOnClick={!isPoweredByUnibuddyClickDisabled} />
                    </LogoWrapper>
                </Box>

                {/* FILTERS */}
                <div>
                    {!degreeId && (
                        <Box marginBottom={24}>
                            {hasFinishedLoading ? (
                                <MentorFilters
                                    filters={filters}
                                    updateFilter={onUpdateFilter}
                                    university={university}
                                />
                            ) : (
                                <MentorFiltersLoading />
                            )}
                        </Box>
                    )}
                </div>

                {/* CARDS */}
                <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                >
                    <StudentPreviewGrid>
                        {hasFinishedLoading ? (
                            mentors.map((mentor, i) => (
                                <MentorPreviewCard
                                    key={mentor.id}
                                    mentor={mentor}
                                    onChatNow={() => onOpenChat(mentor.id, i + 1)}
                                    onSeeMoreClicked={() => onSeeMoreClicked(mentor.id, i + 1)}
                                    onAvatarClicked={() => onAvatarClicked(mentor.id, i + 1)}
                                    rootUrl={baseUrl}
                                    buddyPosition={i + 1}
                                    university={university}
                                    universityDegreeLevels={
                                        filters.find((filter) => filter.id === "degree_level")
                                            ?.options
                                    }
                                />
                            ))
                        ) : (
                            <StudentListPlaceholders buddycardVersion={BUDDY_CARD_VERSION} />
                        )}
                    </StudentPreviewGrid>
                    {showLoadButton && (
                        <Box marginTop="small">
                            <ViewMoreLoaderButton
                                fetchMore={() => fetchMoreMentors(selectedFilters)}
                                showButton={showLoadButton}
                                buddyType="STUDENT"
                            />
                        </Box>
                    )}
                </Box>

                {/* EXTRA SECTIONS */}
                {!viewMoreClicked && <StudentListExtraSectionsWrapper />}
            </div>
        </ErrorDisplay>
    );
}
