import {useEffect} from "react";
import {useApolloClient} from "react-apollo";
import {useHistory, useLocation} from "react-router-dom";
import {rootURL} from "shared/Utils/WhereAmI";
import {
    setToken,
    setTokenScheme,
    setMe,
    clearAuth,
    TokenScheme,
} from "shared/Auth/actions/authActions";
import MeQuery from "shared/Auth/queries/meQuery";
import Auth from "shared/Auth/auth";
import useAnalytics from "shared/AnalyticsNew/hooks/useAnalytics";
import {sharedEvents} from "shared/AnalyticsNew/constants/productSpecification";
import useErrorReporting from "shared/General/components/ErrorReporting/useErrorReporting";
import {getReduxDispatch} from "../../../App";
import {removeSSOErrorKeys, setSSOErrorUrl} from "./utils";
import Messenger from "../../../shared/Chat/messenger";

function userCreatedDateIsNew(created: string | null) {
    if (!created) return false;
    const newUserCheckDuration = 2 * 60 * 1000; // 2 minutes

    // Created Date is in UTC on the backend
    let utcCreatedDate = created;
    if (!utcCreatedDate.endsWith("Z")) {
        utcCreatedDate = `${utcCreatedDate}Z`;
    }
    const createdDate = new Date(utcCreatedDate);
    const now = new Date();
    const diff = now.getTime() - createdDate.getTime();

    return diff < newUserCheckDuration;
}

export const SSOListener = () => {
    const history = useHistory();
    const location = useLocation();
    const client = useApolloClient();
    const {trackEvent, aliasVisitor, setUser} = useAnalytics();
    const {reportError} = useErrorReporting();

    useEffect(() => {
        async function processListener(e: MessageEvent<unknown>) {
            if (!e.data || e.origin !== window.location.origin || typeof e.data !== "string") {
                return;
            }

            if (!e.data.startsWith("ub-sso-token=")) {
                setSSOErrorUrl();
                return;
            }
            const messageData = e.data.split("ub-sso-token=")[1];

            const {bearerToken} = JSON.parse(messageData);

            const dispatch = getReduxDispatch();
            try {
                dispatch(setToken(bearerToken));
                dispatch(setTokenScheme(TokenScheme.BEARER));

                const data = await client.query({query: MeQuery});
                const authMe = data.data.me;

                const isApplicant = Auth.isApplicant(data.data.me);
                if (!isApplicant) {
                    console.error("Only applicants can login here.");
                    throw new Error("Only applicants can login here.");
                }

                const applicantData = data.data.me.applicant;
                const hasExistingAccount =
                    applicantData.applicantUniversities?.length > 0 &&
                    applicantData.universitiesInterested?.length > 0;

                const created = (authMe.anyUser?.created as string) ?? null;
                const isNewUser = userCreatedDateIsNew(created);

                if (isNewUser) {
                    aliasVisitor(authMe.anyUser);
                } else {
                    setUser(authMe.anyUser);
                }

                dispatch(setMe(authMe));
                await Messenger.connect(bearerToken, {}, dispatch, TokenScheme.BEARER);

                if (isNewUser && !hasExistingAccount) {
                    trackEvent(sharedEvents.accountCreated.name, {
                        accountRole: "applicant",
                        authType: "Google",
                    });
                }

                removeSSOErrorKeys();

                // Navigate to auth step 2
                const url = `${rootURL(location.pathname)}/auth/register2`;

                history.push(url);
            } catch (error) {
                dispatch(clearAuth());
                setSSOErrorUrl();
                reportError(error);
            }
        }

        function successListener(e: MessageEvent<unknown>) {
            processListener(e);
        }

        window.addEventListener("message", successListener);

        return () => {
            window.removeEventListener("message", successListener);
        };
    }, [aliasVisitor, client, history, location.pathname, reportError, setUser, trackEvent]);

    return null;
};
