import React from "react";

import {BackButton} from "shared/General/components/BackButton/BackButton";
import PageHeader from "shared/Shared/components/PageHeader/PageHeader";
import {Box, Stack, TextBlock, Heading} from "@unibuddy/patron";

type AmbassadorRemovedCardProps = {
    university: {nickname?: string; name?: string};
};

export function AmbassadorRemovedCard({university}: AmbassadorRemovedCardProps) {
    return (
        <Stack space="medium">
            <PageHeader leftContent={<BackButton />} />
            <Box padding="medium" backgroundColor="grey700">
                <Stack space="small">
                    <Heading level="4" color="white">
                        Sorry! This Ambassador is unavailable
                    </Heading>
                    <TextBlock color="white">
                        This Ambassador is no longer active on Unibuddy. They may have graduated, or
                        are taking a break. Feel free to chat with other ambassadors from{" "}
                        {university?.nickname || university?.name || "University"}!
                    </TextBlock>
                </Stack>
            </Box>
        </Stack>
    );
}
