import React from "react";
import {render} from "react-dom";
import "url-search-params-polyfill";
import {getOrSetUBUUID} from "shared/Utils/uuidUtils";
/* This has to be imported before app */
import "./config";
import {pollSettingAnalyticsAdapters, setOneTrustCookies} from "./cookieSetting";
import App from "./App";
import {getenv} from "./util/getEnv";

if (process?.env?.NODE_ENV !== "development") {
    console.table({
        VERSION: getenv("VERSION"),
        UB_SERVICE: getenv("UB_SERVICE"),
        UB_ENVIRONMENT: getenv("UB_ENVIRONMENT"),
    });
}

export function main() {
    /* Schedule loading of analytics adapters */
    setOneTrustCookies();
    getOrSetUBUUID();
    pollSettingAnalyticsAdapters();

    render(<App />, document.getElementById("container"));
}

main();
