export enum REFERRER {
    WIDGET = "widget-ui",
    BUDDY_LIST = "widget-ui-buddies-list",

    CAROUSEL = "carousel",
    CAROUSEL_EXTERNAL = "carousel-external",

    BUDDY_CARD_INTERNAL = "buddy-card-internal",
    BUDDY_CARD_EXTERNAL = "buddy-card-external",

    COURSE_BUDDY_PLATFORM = "course-buddy-platform",

    BLOG_FEED = "blog-feed",

    EMBEDDED_WIDGET_TRAFFIC_DRIVER = "embedded-widget-traffic-driver",
}

function checkPathIsBuddyPage(path: string): boolean {
    const colourRegex = /\/colour\/\w{6}$/;

    if (path.endsWith("/buddies/students") || path.endsWith("/buddies") || colourRegex.test(path)) {
        return true;
    }

    return false;
}

export function getLogoReferrer(path: string): string {
    if (path.includes("/external/carousel/")) {
        return REFERRER.CAROUSEL_EXTERNAL;
    }
    if (path.endsWith("/carousel/") || path.endsWith("/carousel")) {
        return REFERRER.CAROUSEL;
    }
    if (path.includes("/course/")) {
        return REFERRER.COURSE_BUDDY_PLATFORM;
    }
    if (path.includes("/internal/")) {
        return REFERRER.BUDDY_CARD_INTERNAL;
    }
    if (path.includes("/external/")) {
        return REFERRER.BUDDY_CARD_EXTERNAL;
    }
    if (path.includes("/blog/topic/")) {
        return REFERRER.BLOG_FEED;
    }
    if (path.includes("/widget-traffic-driver-cta")) {
        return REFERRER.EMBEDDED_WIDGET_TRAFFIC_DRIVER;
    }
    if (checkPathIsBuddyPage(path)) {
        return REFERRER.BUDDY_LIST;
    }

    return REFERRER.WIDGET;
}

export function getApolloClientReferrer(path: string): string {
    if (path.includes("/external/carousel/")) {
        return REFERRER.CAROUSEL_EXTERNAL;
    }
    if (path.endsWith("/carousel/") || path.endsWith("/carousel")) {
        return REFERRER.CAROUSEL;
    }
    if (path.includes("/course/")) {
        return REFERRER.COURSE_BUDDY_PLATFORM;
    }
    if (path.includes("/internal/")) {
        return REFERRER.BUDDY_CARD_INTERNAL;
    }
    if (path.includes("/external/")) {
        return REFERRER.BUDDY_CARD_EXTERNAL;
    }
    if (path.includes("/blog/topic/")) {
        return REFERRER.BLOG_FEED;
    }
    if (path.includes("/widget-traffic-driver-cta")) {
        return REFERRER.EMBEDDED_WIDGET_TRAFFIC_DRIVER;
    }

    return REFERRER.WIDGET;
}
