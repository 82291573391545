import gql from "graphql-tag";

export const blogPostPreviewFragment = gql`
    fragment BlogPostPreviewFragment on BlogPostField {
        id
        title
        body
        coverImages(raw: true)
        youtubeVideo
        videoContent {
            id
            url {
                url
            }
        }
        author {
            id
            firstName
            archived
            profilePhoto
            degree {
                id
                name
            }
            country {
                id
                code
                name
            }
        }
    }
`;
