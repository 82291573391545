import React, {Fragment, useEffect} from "react";
import {Box, Shimmer, Tiles, Text} from "@unibuddy/patron";
import {ApolloError} from "apollo-client";

import {sharedEvents} from "shared/AnalyticsNew/constants/productSpecification";
import useAnalytics from "shared/AnalyticsNew/hooks/useAnalytics";
import {SHOW_RECOMMENDATION_PROMPT} from "shared/constants";

import {GenerateRecommendationsMutation} from "../../NextBestActions/NextBestActions";
import {CardContainer} from "../../NextBestActions/containers/NextBestActions";
import NextBestActionsCard from "../../NextBestActions/components/NextBestActionsCard";
import RecommendationGreeting from "../components/RecommendationGreeting/RecommendationGreeting";
import {
    useRecommendations,
    RECOMMENDATION_PAGE_BATCH_SIZE,
} from "../RecommendationsProvider/RecommendationsProvider";
import {useSearchQuery} from "../../../../hooks/useSearchQuery";
import {useUniversity} from "../../UniversityProvider/UniversityProvider";
import useShowPrompt from "../../Prompt/hooks/useShowPrompt";
import {AnyUserField} from "../../../../types/generated";
import {RecommendationPrompt} from "../components/RecommendationPrompt/RecommendationPrompt";
import {ProspectStageLocation} from "../../Chat/components/PopupQuestions/prospectStages";
import {UniversityQuestionCallOut} from "../components/UniversityQuestionCallOut/UniversityQuestionCallOut";
import {useUniversityMessageCount} from "../../Inbox/components/ApplicantInbox/hooks/useUniversityMessageCount";

type RecommendationsProps = {
    isLoading: boolean;
    error: ApolloError;
    data?: GenerateRecommendationsMutation;
};

function createShimmers(count: number) {
    const shimmers: number[] = new Array(count).fill(Math.random());
    return shimmers.map(() => (
        <Box key={`idx:${Math.random()}`} margin="small">
            <Shimmer height="229px" width="100%">
                <rect x="0" y="0" rx={4} ry={4} width="100%" height="229px" />
            </Shimmer>
        </Box>
    ));
}

const RecommendationLoading: React.FC = () => (
    <Box width="100%" marginBottom="xsmall" data-test-id="recommendation-page-loading-state">
        <Tiles columns={[1, 2, 3]}>{createShimmers(RECOMMENDATION_PAGE_BATCH_SIZE)}</Tiles>
    </Box>
);

const Recommendations: React.FC<RecommendationsProps> = (props) => {
    const {trackEvent} = useAnalytics();
    const university = useUniversity();
    const searchQuery = useSearchQuery();
    const template = searchQuery.get("template");
    const channel = searchQuery.get("channel");

    const {loading: promptLoading, showPrompt: showPopup} = useShowPrompt(
        SHOW_RECOMMENDATION_PROMPT,
    );

    // templateLinkedFrom and channel will show as {not set} in mixpanel
    // if user isn't directed here from email/sms/whatsapp
    useEffect(() => {
        trackEvent(sharedEvents.recommendationsPageViewed.name, {
            universityId: university.id,
            universityName: university.name,
            templateLinkedFrom: template,
            channel,
        });
    }, [university, trackEvent, template, channel]);

    if (props.isLoading || promptLoading || !props.data) {
        return <RecommendationLoading />;
    } else if (props.error) {
        return (
            <Text data-test-id="recommendation-page-error-state" color="red">
                Failed to load recommendations
            </Text>
        );
    }
    return (
        <Fragment>
            {showPopup && (
                <RecommendationPrompt
                    batchSize={RECOMMENDATION_PAGE_BATCH_SIZE}
                    showPopup={showPopup}
                    location={ProspectStageLocation.WIDGET_FOR_YOU}
                    hideTrigger
                />
            )}
            <Tiles columns={[1, 2, 3]} space="medium">
                {props?.data?.generateRecommendations?.map((content) => (
                    <CardContainer key={content.id}>
                        <NextBestActionsCard
                            contentId={content.contentId}
                            title={content.title}
                            description={content.description}
                            contentType={content.type}
                            url={content.url}
                            batchId={content.batchId}
                        />
                    </CardContainer>
                ))}
            </Tiles>
        </Fragment>
    );
};

type RecommendationPageProps = {
    me: {
        anyUser: AnyUserField;
    };
};

export const RecommendationPage: React.ExoticComponent<RecommendationPageProps> = React.memo(
    (props) => {
        const firstName = props?.authState?.me?.anyUser?.firstName ?? "";
        const {getRecommendationsCallback, data, loading, error} = useRecommendations();
        const messageCount = useUniversityMessageCount();
        React.useEffect(() => {
            getRecommendationsCallback(RECOMMENDATION_PAGE_BATCH_SIZE);
        }, [getRecommendationsCallback]);

        return (
            <Box marginBottom="medium">
                {messageCount > 0 ? (
                    <UniversityQuestionCallOut name={firstName} />
                ) : (
                    <RecommendationGreeting name={firstName} />
                )}
                <Recommendations data={data} isLoading={loading} error={error} />
            </Box>
        );
    },
);
