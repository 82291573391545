import React from "react";
import {Text} from "@unibuddy/patron";
import {MediaType, TileBody, TileDescription, TileActions} from "@unibuddy/content-ui";
import {useHistory} from "react-router";
import {useTranslation} from "@unibuddy/intl";

import useAnalytics from "shared/AnalyticsNew/hooks/useAnalytics";
import {sharedEvents} from "shared/AnalyticsNew/constants/productSpecification";

import {getContentType, removeHtmlTags} from "./utils";
import {useUniversity} from "../../../UniversityProvider/UniversityProvider";
import {BlogPostField} from "../../../../../types/generated";
import {WIDGET_PAGE} from "../../../../../widgets/modules/PageProvider/PageTitles";
import {IntlNamespaces} from "../../../../../translations/types";
import {INTL_WATCH_VIDEO, INTL_READ_ARTICLE} from "../../../../../translations/common";

export function ContentCardBody({
    post,
    contentUrl,
    mediaType,
    position,
}: {
    post: BlogPostField;
    contentUrl: string;
    mediaType: MediaType;
    position: number;
}) {
    const {trackEvent} = useAnalytics();
    const {slug} = useUniversity();
    const history = useHistory();
    const {t} = useTranslation(IntlNamespaces.COMMON);
    const onViewPost = (e: React.MouseEvent) => {
        e.preventDefault();
        const contentType = getContentType(post);
        trackEvent(sharedEvents.contentCardClicked.name, {
            universitySlug: slug,
            position,
            contentType,
            tab: WIDGET_PAGE.WIDGET_STUDENTS,
        });
        history.push(`${contentUrl}/post/${post?.id}`);
    };
    const viewPostButtonText =
        mediaType === MediaType.IMAGE
            ? t(INTL_READ_ARTICLE.key, INTL_READ_ARTICLE.defaultValue)
            : t(INTL_WATCH_VIDEO.key, INTL_WATCH_VIDEO.defaultValue);

    return (
        <TileBody
            bgColor={mediaType !== MediaType.IMAGE ? "transparent" : "white"}
            justifyContent={mediaType !== MediaType.IMAGE ? "flex-end" : "space-between"}
        >
            <TileDescription
                description={
                    <Text
                        color={mediaType !== MediaType.IMAGE ? "white" : null}
                        size="small"
                        truncate={2}
                    >
                        {removeHtmlTags(post?.body)}
                    </Text>
                }
                title={
                    <Text
                        as="h3"
                        color={mediaType !== MediaType.IMAGE ? "white" : null}
                        truncate
                        weight="bold"
                    >
                        {post?.title}
                    </Text>
                }
            />
            <TileActions
                callback={onViewPost}
                link={`${contentUrl}/post/${post?.id}`}
                theme={mediaType === MediaType.IMAGE ? "dark" : "light"}
                ariaLabel={`${viewPostButtonText} ${post?.title}`}
            >
                {viewPostButtonText}
            </TileActions>
        </TileBody>
    );
}
