import {MediaType} from "@unibuddy/content-ui";

import {BlogPostField} from "../../../../../types/generated";
import {getenv} from "../../../../../util/getEnv";

export const enum ContentType {
    SHORT_VIDEO = "SHORT_VIDEO",
    YOUTUBE_VIDEO = "YOUTUBE_VIDEO",
    BLOG = "BLOG",
}

export const removeHtmlTags = (inputString: string) =>
    inputString.replaceAll(/<\/?[^>]+(>|$)/gi, "") as string;

export function getMediaAndMediaType(post: BlogPostField) {
    const ASSETS_URL = getenv("ASSETS_URL");
    if (post?.videoContent)
        return {mediaType: MediaType.VIDEO, media: post?.videoContent?.url?.url};
    if (post?.youtubeVideo)
        return {
            mediaType: MediaType.YOUTUBE,
            media: post?.youtubeVideo,
        };
    if (post?.coverImages?.length > 0)
        return {
            mediaType: MediaType.IMAGE,
            media: `${ASSETS_URL as string}/${post?.coverImages[0]}`,
        };
    return {mediaType: null, media: null};
}

export function getContentType(post: BlogPostField): ContentType {
    if (post?.videoContent?.id) {
        return ContentType.SHORT_VIDEO;
    } else if (post?.youtubeVideo) {
        return ContentType.YOUTUBE_VIDEO;
    }
    return ContentType.BLOG;
}
