import React from "react";
import {Link} from "react-router-dom";

type CardAvatarClickHandlerProps = {
    children: React.ReactNode;
    link?: string;
    ariaLabel: string;
    handleClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    openInNewTab?: boolean;
};

export const CardAvatarClickHandler = ({
    children,
    link,
    ariaLabel,
    handleClick,
    openInNewTab = false,
}: CardAvatarClickHandlerProps) => {
    if (!link) return children;

    if (openInNewTab) {
        return (
            <a
                href={link}
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => handleClick(e)}
                target="_blank"
                rel="noreferrer noopener"
                aria-label={ariaLabel}
            >
                {children}
            </a>
        );
    }

    return (
        <Link
            onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => handleClick(e)}
            to={link}
            aria-label={ariaLabel}
        >
            {children}
        </Link>
    );
};
