import React from "react";
import {useHistory} from "react-router";
import {Trans, useTranslation} from "@unibuddy/intl";
import moment from "moment";
import {TFunction} from "i18next";

import {rootURL} from "shared/Utils/WhereAmI";

import {useUser} from "../../../User/useUser";
import {IntlNamespaces} from "../../../../../translations/types";
import {INTL_ACTIVITY_STAT, INTL_LAUNCHING} from "../../../../../translations/common";
import {ThemedLink} from "../../../../../components/ThemedLink/ThemedLink";

type ActivityStatProps = {
    uniLaunchDate: string;
    totalMessages: number;
    buddyGroupType?: string;
};

const getLaunchDate = (
    launchDate: string,
    t: TFunction<[IntlNamespaces.COMMON, "months"], undefined>,
): string => {
    if (launchDate) {
        const month = moment(launchDate).format("MMMM");
        const year = moment(launchDate).format("YYYY");

        const translatedMonth: string = t(`months:${month}.long`, month);
        return `${translatedMonth} ${year}`;
    }

    const translatedLaunching: string = t(
        `${IntlNamespaces.COMMON}:${INTL_LAUNCHING.key}`,
        INTL_LAUNCHING.defaultValue as string,
    );

    return translatedLaunching;
};

export const ActivityStat = ({
    uniLaunchDate,
    totalMessages,
    buddyGroupType = "students",
}: ActivityStatProps) => {
    const {t} = useTranslation([IntlNamespaces.COMMON, "months"]);
    const launchDate = getLaunchDate(uniLaunchDate, t);
    const buddyType = t(buddyGroupType, buddyGroupType).toLowerCase();
    const user = useUser();
    const history = useHistory();

    return (
        <Trans t={t} i18nKey={INTL_ACTIVITY_STAT.key}>
            Since {{launchDate}},
            <ThemedLink
                to={
                    user
                        ? `${rootURL(history.location.pathname)}/inbox`
                        : `${rootURL(history.location.pathname)}/auth`
                }
            >
                {" "}
                {{totalMessages}}
                {" questions "}
            </ThemedLink>
            have been answered by our {{buddyType}}.
        </Trans>
    );
};
