import React, {useState} from "react";
import {Box, Button, Heading, PatronThemeProvider, TextInput, View, colors} from "@unibuddy/patron";
import {useHistory} from "react-router";
import {useQuery} from "react-apollo";
import styled from "styled-components";
import useAnalytics from "shared/AnalyticsNew/hooks/useAnalytics";
import {sharedEvents} from "shared/AnalyticsNew/constants/productSpecification";
import {useUniversity} from "../../../UniversityProvider/UniversityProvider";
import {getRandomAmbassadors} from "../../Intro";
import {AvatarStack} from "./Avatar";
import {SearchPageQuestions} from "./SearchPageQuestions";
import {IconSend} from "../../../Icons/IconSend";
import {IntroPageCard} from "./IntroPageCard";
import useUniversityColor from "../../../UniversityProvider/hooks/useUniversityColor";

const AMBASSADORS_LIMIT = 3;

const StyledBox = styled(Box)`
    flex-direction: column;

    @media (min-width: 600px) {
        flex-direction: row;
    }
`;

const overrides = {
    TextInput: {
        borderRadius: "32px",
        borderWidth: "1px",
        borderColor: colors.grey300,
        color: colors.grey900,
        backgroundColor: "white",
        placeholderColor: colors.grey600,
    },
};

const StyledSendButton = styled(Button)<{$color: string}>`
    & svg {
        margin: 0;
        font-size: 1rem;
    }
    color: white;
    background-color: ${({$color}) => $color};
    border: ${({$color}) => `1px solid ${$color}`};

    &:hover {
        background-color: ${({$color}) => `${$color}e6`} !important;
    }
    &:focus {
        background-color: ${({$color}) => `${$color}cc`} !important;
        border: 1px solid white;
    }
`;

function getRandomIndex(): number {
    return Math.floor(Math.random() * AMBASSADORS_LIMIT);
}

export const SearchPageContainer = ({
    setUserHasSeenIntroPage,
}: {
    setUserHasSeenIntroPage: (b: boolean) => void;
}) => {
    const {trackEvent} = useAnalytics();
    const {slug} = useUniversity();
    const universityColor = useUniversityColor();
    const history = useHistory();
    const {data, loading, error} = useQuery(getRandomAmbassadors, {
        variables: {
            universitySlug: slug,
            limit: AMBASSADORS_LIMIT,
        },
        skip: !slug,
    });
    const [value, setValue] = useState("");

    if (!slug || loading) {
        return <div>loading...</div>;
    }

    if (!data || error || data.sequenceFilterMentorList.mentors.length < AMBASSADORS_LIMIT) {
        setUserHasSeenIntroPage(true);
        history.replace(window.location.pathname.replace("/intro", "/students"));
        return null;
    }

    const cardData = [
        {
            hero: (
                <AvatarStack
                    profilePhotos={[
                        data.sequenceFilterMentorList.mentors[0].profilePhoto,
                        data.sequenceFilterMentorList.mentors[1].profilePhoto,
                        data.sequenceFilterMentorList.mentors[2].profilePhoto,
                    ]}
                />
            ),
            text: "Chat with a student or staff member",
            buttonText: "View profiles",
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            relativeLink: "buddies/students",
        },
        {
            hero: (
                <img
                    src="https://images.unibuddy.co/409x185/smart/content-cards-images.png"
                    alt="ContentCards"
                />
            ),
            text: "Explore the student experience",
            buttonText: "View videos & articles",
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            relativeLink: "blog",
        },
    ];

    const redirectUserAndSetIntroPageSeen = (link: string) => {
        setUserHasSeenIntroPage(true);
        const updatedUrl = window.location.pathname.replace("/buddies/intro", "");
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        history.replace(`${updatedUrl}/${link}`);
    };

    const onClick = (buttonText: string, index: number, relativeLink: string) => {
        trackEvent(sharedEvents.searchPageActionClicked.name, {
            universitySlug: slug,
            page: "Intro page search box experiment",
            cta: buttonText,
        });
        redirectUserAndSetIntroPageSeen(relativeLink);
    };

    const onSend = () => {
        trackEvent(sharedEvents.searchPageActionClicked.name, {
            universitySlug: slug,
            page: "Intro page search box experiment",
            cta: "Submit question",
            searchText: value,
        });

        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        const link = `inbox/pre/${data.sequenceFilterMentorList.mentors[getRandomIndex()].id}`;
        redirectUserAndSetIntroPageSeen(link);
    };

    const onQuestionClick = (index) => {
        trackEvent(sharedEvents.searchPageActionClicked.name, {
            universitySlug: slug,
            page: "Intro page search box experiment",
            cta: "Suggested question",
            rank: index,
        });

        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        const link = `inbox/pre/${data.sequenceFilterMentorList.mentors[getRandomIndex()].id}`;
        redirectUserAndSetIntroPageSeen(link);
    };

    return (
        <View gap="large" justifyContent="center" alignItems="center">
            <Heading size="large" level="2" align="center">
                Get quick answers to your burning questions
            </Heading>
            <View gap="xlarge">
                <View gap="medium">
                    <Box display="flex" flexDirection="row" gap="xsmall" alignItems="center">
                        <PatronThemeProvider theme={overrides}>
                            <Box
                                width="100%"
                                borderWidth={1}
                                borderColor="grey300"
                                borderRadius="full"
                                shadow="medium"
                            >
                                <TextInput
                                    placeholder="Ask your question..."
                                    name="ask your question"
                                    value={value}
                                    onChange={(event) => {
                                        setValue(event.target.value);
                                    }}
                                />
                            </Box>
                        </PatronThemeProvider>
                        <StyledSendButton
                            $color={universityColor}
                            round
                            iconOnly
                            onClick={onSend}
                            aria-label="Search question"
                            size="md"
                            disabled={value.length === 0}
                        >
                            <IconSend style={{marginRight: -2, marginTop: -1}} />
                        </StyledSendButton>
                    </Box>
                    <SearchPageQuestions onQuestionClick={onQuestionClick} />
                </View>

                <StyledBox display="flex" gap="small-medium" paddingTop="small-medium">
                    {cardData.map((card, index) => (
                        <IntroPageCard
                            key={card.buttonText}
                            hero={card.hero}
                            text={card.text}
                            buttonText={card.buttonText}
                            relativeLink={card.relativeLink}
                            index={index}
                            onClick={onClick}
                        />
                    ))}
                </StyledBox>
            </View>
        </View>
    );
};
