import React, {useEffect, useRef} from "react";
import {useQuery} from "react-apollo";
import {Box, Stack} from "@unibuddy/patron";
import {useBooleanFeatureFlag} from "ub-feature-flagging-react";

import {
    SHOW_CONTENT_SECTION_IN_STUDENTS_TAB,
    SHOW_STAFF_SECTION_IN_STUDENTS_TAB,
} from "shared/constants";
import useAnalytics from "shared/AnalyticsNew/hooks/useAnalytics";
import {sharedEvents} from "shared/AnalyticsNew/constants/productSpecification";

import {useUniversity} from "../../../UniversityProvider/UniversityProvider";
import {blogPostsQuery} from "../../queries/blogPostsQuery";
import buddiesQuery from "../../../Buddies/queries/buddiesQuery";
import {StaffSection} from "../StaffSection/StaffSection";
import {ContentSection} from "../ContentSection/ContentSection";
import {WIDGET_PAGE} from "../../../PageProvider/PageTitles";

export function StudentListExtraSectionsWrapper() {
    const {slug: universitySlug, features} = useUniversity();
    const {trackEvent} = useAnalytics();
    const isStaffFeatureEnabled = features?.get("staffProfiles") ?? false;
    const isContentFeatureEnabled = features?.get("userGeneratedContent") ?? false;
    const isStaffSectionEnabled = useBooleanFeatureFlag(
        SHOW_STAFF_SECTION_IN_STUDENTS_TAB.key,
        SHOW_STAFF_SECTION_IN_STUDENTS_TAB.value,
    );
    const isContentSectionEnabled = useBooleanFeatureFlag(
        SHOW_CONTENT_SECTION_IN_STUDENTS_TAB.key,
        SHOW_CONTENT_SECTION_IN_STUDENTS_TAB.value,
    );

    const {
        data: staffData,
        loading: staffLoading,
        error: staffError,
    } = useQuery(buddiesQuery, {
        variables: {universitySlug, role: "staff", offset: 0, limit: 2},
        skip: !(isStaffSectionEnabled && isStaffFeatureEnabled),
    });

    const {
        data: contentData,
        loading: contentLoading,
        error: contentError,
    } = useQuery(blogPostsQuery, {
        variables: {
            universitySlug,
            topics: [],
            degrees: [],
            offset: 0,
            limit: 3,
            order: "-created",
        },
        skip: !(isContentSectionEnabled && isContentFeatureEnabled),
    });

    const hasStaffData = !!staffData?.buddies?.users?.length;
    const hasContentData = !!contentData?.allPostsInfo?.blogPosts?.length;

    const shouldShowStaffSection =
        isStaffSectionEnabled &&
        isStaffFeatureEnabled &&
        !staffError &&
        (staffLoading || hasStaffData);
    const shouldShowContentSection =
        isContentSectionEnabled &&
        isContentFeatureEnabled &&
        !contentError &&
        (contentLoading || hasContentData);

    const staffRef = useRef<HTMLDivElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const staffObserver = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    trackEvent(sharedEvents.sectionVisible.name, {
                        universitySlug,
                        sectionType: "staff",
                        tab: WIDGET_PAGE.WIDGET_STUDENTS,
                    });
                }
            },
            {threshold: 0.3},
        );

        const contentObserver = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    trackEvent(sharedEvents.sectionVisible.name, {
                        universitySlug,
                        sectionType: "content",
                        tab: WIDGET_PAGE.WIDGET_STUDENTS,
                    });
                }
            },
            {threshold: 0.3},
        );

        if (staffRef.current && shouldShowStaffSection) {
            staffObserver.observe(staffRef.current);
        }

        if (contentRef.current && shouldShowContentSection) {
            contentObserver.observe(contentRef.current);
        }

        return () => {
            if (staffRef.current) {
                staffObserver.unobserve(staffRef.current);
            }
            if (contentRef.current) {
                contentObserver.unobserve(contentRef.current);
            }
        };
    }, [shouldShowStaffSection, shouldShowContentSection, trackEvent, universitySlug]);

    if (!shouldShowStaffSection && !shouldShowContentSection) return null;

    return (
        <Stack space="large">
            <Box bgColor="grey150" height={1} marginTop="large" />
            {shouldShowStaffSection && (
                <div ref={staffRef}>
                    <StaffSection data={staffData} loading={staffLoading} error={staffError} />
                </div>
            )}
            {shouldShowContentSection && (
                <div ref={contentRef}>
                    <ContentSection
                        data={contentData}
                        loading={contentLoading}
                        error={contentError}
                    />
                </div>
            )}
        </Stack>
    );
}
