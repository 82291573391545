import gql from "graphql-tag";
import {useQuery} from "react-apollo";
import isArray from "lodash/isArray";

import {
    SequenceFilterMentorListQueryQuery,
    SequenceFilterMentorListQueryQueryVariables,
} from "../../../../types/generated";

export const sequenceFilterMentorListQuery = gql`
    query SequenceFilterMentorListQuery(
        $universitySlug: String
        $sequenceFilter: [MentorFilterInputField]
        $offset: Int
        $limit: LimitedInt
        $mentorSeed: Int
        $applyDegreeFilter: Boolean
        $isDegreeTypesEnabled: Boolean = false
        $domesticOrInternational: DomesticOrInternational
    ) {
        sequenceFilterMentorList(
            universitySlug: $universitySlug
            sequenceFilter: $sequenceFilter
            offset: $offset
            safeLimit: $limit
            mentorSeed: $mentorSeed
            applyDegreeFilter: $applyDegreeFilter
            domesticOrInternational: $domesticOrInternational
        ) {
            mentors {
                id
                firstName
                profilePhoto(raw: true)
                lastSeen
                degree @skip(if: $isDegreeTypesEnabled) {
                    id
                    name
                }
                degrees @include(if: $isDegreeTypesEnabled) {
                    degreeType
                    degree {
                        id
                        name
                    }
                }
                languages {
                    id
                    name
                }
                year
                favouriteModules {
                    id
                    name
                }
                pastQualification
                interests {
                    id
                    name
                }
                societies {
                    id
                    name
                }
                customAttributes(active: true) {
                    value
                    attribute {
                        id
                        name
                    }
                    ... on SingleChoiceValueField {
                        option {
                            id
                            value
                        }
                    }
                }
                placeName
                country {
                    id
                    code
                    name
                }
                university {
                    id
                    name
                }
                pastQualification
                shortBio
                bio
                degreeLevel
                mentorFields {
                    primaryFlag {
                        name
                        code
                    }
                    secondaryFlag {
                        name
                        code
                    }
                }
            }
            filters {
                id
                label
                options {
                    id
                    label
                }
            }
            mentorSeed
        }
    }
`;

export type FilterRawType = {
    field: string;
    value: string;
};
type SequenceFilterType =
    | FilterRawType
    | {
          field: string;
          valueList: string[];
      };
function parseFilters(filters: FilterRawType[]): SequenceFilterType[] {
    const sequenceFilter = filters.map((filter) => {
        /**
         * In case filter value is an array, we use a different input field called 'valueList'
         * instead of 'value' as graphql won't support multiple input types for the same field. */
        if (isArray(filter.value)) {
            return {
                field: filter.field,
                valueList: filter.value,
            };
        }
        return {
            field: filter.field,
            value: filter.value,
        };
    });

    return sequenceFilter;
}

type UseSequenceFilterMentorListQueryProps = {
    universitySlug: string;
    filters: FilterRawType[];
    isDegreeTypesEnabled: boolean;
    applyDegreeFilter: boolean;
    mentorSeed?: number;
    limit: number;
    offset: number;
};

export function useSequenceFilterMentorListQuery({
    universitySlug,
    filters,
    isDegreeTypesEnabled,
    applyDegreeFilter,
    mentorSeed,
    limit,
    offset,
}: UseSequenceFilterMentorListQueryProps) {
    const sequenceFilter = parseFilters(filters);

    const query = useQuery<
        SequenceFilterMentorListQueryQuery,
        SequenceFilterMentorListQueryQueryVariables
    >(sequenceFilterMentorListQuery, {
        displayName: "mentorListQuery",
        variables: {
            universitySlug,
            sequenceFilter,
            offset,
            limit,
            mentorSeed,
            applyDegreeFilter,
            isDegreeTypesEnabled,
        },
        fetchPolicy: "network-only",
        skip: !universitySlug,
    });

    const refetch = (variables: UseSequenceFilterMentorListQueryProps) => {
        const {filters: refetchFilters, ...vars} = variables;
        const refetchSequenceFilter = parseFilters(refetchFilters);
        return query.refetch({...vars, sequenceFilter: refetchSequenceFilter});
    };

    return {...query, refetch};
}
