import {Box, Shimmer, VisuallyHidden} from "@unibuddy/patron";
import React from "react";
import {useBooleanFeatureFlag} from "ub-feature-flagging-react";
import styled from "styled-components";
import {NEXT_BEST_ACTIONS_FLAG} from "shared/constants";

import {
    SMALL_SCREEN_WIDTH,
    NEXT_BEST_ACTIONS_FLAG_DEFAULT,
    WIDGET_HEIGHT_COLLAPSED,
    WIDGET_HEIGHT_COLLAPSED_MOBILE,
} from "../NextBestActions";

const LoadingState = styled(Shimmer)`
    height: ${WIDGET_HEIGHT_COLLAPSED};
    @media (max-width: ${SMALL_SCREEN_WIDTH}) {
        height: ${WIDGET_HEIGHT_COLLAPSED_MOBILE};
    }
`;

const LoadingStateContainer = styled(Box)`
    height: ${WIDGET_HEIGHT_COLLAPSED};
    @media (max-width: ${SMALL_SCREEN_WIDTH}) {
        height: ${WIDGET_HEIGHT_COLLAPSED_MOBILE};
    }
`;

export default function NextBestActionsLoading() {
    const SHOW_NEXT_BEST_ACTIONS = useBooleanFeatureFlag(
        NEXT_BEST_ACTIONS_FLAG,
        NEXT_BEST_ACTIONS_FLAG_DEFAULT,
    );

    if (!SHOW_NEXT_BEST_ACTIONS) return null;

    return (
        <LoadingStateContainer width="100%" data-test-id="nba-loading-state">
            <LoadingState width="100%">
                <rect x="0" y="0" rx={4} ry={4} width="100%" height="100%" />
                <VisuallyHidden>Loading...</VisuallyHidden>
            </LoadingState>
        </LoadingStateContainer>
    );
}
