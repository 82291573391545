import {IntlKeyValuePair, IntlNamespaces} from "./types";

export const INTL_READ_MORE: IntlKeyValuePair = {
    key: "readMore",
    defaultValue: (name: string) => `Read more about ${name} ...`,
    intlValue: "Read more about {{name}} ...",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_ABOUT_ME: IntlKeyValuePair = {
    key: "aboutMe",
    defaultValue: "About me",
    intlValue: "About me",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_I_COME_FROM: IntlKeyValuePair = {
    key: "iComeFrom",
    defaultValue: "I come from",
    intlValue: "I come from",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_PREVIOUS_STUDIES: IntlKeyValuePair = {
    key: "previousStudies",
    defaultValue: "Previous Qualification",
    intlValue: "Previous Qualification",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_I_SPEAK: IntlKeyValuePair = {
    key: "iSpeak",
    defaultValue: "I speak",
    intlValue: "I speak",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_CURRENTLY_IN: IntlKeyValuePair = {
    key: "currentlyIn",
    defaultValue: "I'm currently in",
    intlValue: "I'm currently in",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_AREA_OF_STUDY_SECTION: IntlKeyValuePair = {
    key: "areaOfStudySection",
    defaultValue: "Area(s) of Study",
    intlValue: "Area(s) of Study",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_UNDERGRADUATE_YEARS: IntlKeyValuePair = {
    key: "undergraduateYears",
    defaultValue:
        "{'foundation': 'Foundation', '1stYear': '1st Year', '2ndYear': '2nd Year', '3rdYear': '3rd Year', '4thYear': '4th Year', '5thYear': '5th Year', '6thYear': '6th Year'}",
    intlValue:
        "{'foundation': 'Foundation', '1stYear': '1st Year', '2ndYear': '2nd Year', '3rdYear': '3rd Year', '4thYear': '4th Year', '5thYear': '5th Year', '6thYear': '6th Year'}",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_POSTGRADUATE_YEARS: IntlKeyValuePair = {
    key: "postgraduateYears",
    defaultValue:
        "{'masters': 'Masters', 'phd': 'PhD', 'professional': 'Professional', 'doctorate': 'Doctorate'}",
    intlValue:
        "{'masters': 'Masters', 'phd': 'PhD', 'professional': 'Professional', 'doctorate': 'Doctorate'}",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_FAVORITE_COURSES: IntlKeyValuePair = {
    key: "favoriteCourses",
    defaultValue: "Favourite courses",
    intlValue: "Favourite courses",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_HOBBIES_INTERESTS: IntlKeyValuePair = {
    key: "hobbiesInterests",
    defaultValue: "Hobbies & interests",
    intlValue: "Hobbies & interests",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_SOCIETIES: IntlKeyValuePair = {
    key: "societies",
    defaultValue: "Clubs & Societies",
    intlValue: "Clubs & Societies",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_SOCIAL_LIFE: IntlKeyValuePair = {
    key: "socialLife",
    defaultValue: "Social life",
    intlValue: "Social life",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_ACADEMIC_LIFE: IntlKeyValuePair = {
    key: "academicLife",
    defaultValue: "Academic life",
    intlValue: "Academic life",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_SUMMARY: IntlKeyValuePair = {
    key: "summary",
    defaultValue: "In short",
    intlValue: "In short",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_ALL_BUDDIES: IntlKeyValuePair = {
    key: "allBuddies",
    defaultValue: "All Buddies",
    intlValue: "All Buddies",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_ABOUT_NAME: IntlKeyValuePair = {
    key: "aboutName",
    defaultValue: (name: string) => `About ${name}`,
    intlValue: "About {{name}}",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_NOT_PART_OF_SOCIETIES: IntlKeyValuePair = {
    key: "notPartOfSocieties",
    defaultValue: "I'm not part of any societies",
    intlValue: "I'm not part of any societies",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_ROLE_DESCRIPTION: IntlKeyValuePair = {
    key: "roleDescription",
    defaultValue: "Role Description",
    intlValue: "Role Description",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_SHARE: IntlKeyValuePair = {
    key: "share",
    defaultValue: "Copy profile link",
    intlValue: "Copy profile link",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_BLOG_POSTS: IntlKeyValuePair = {
    key: "blogPosts",
    defaultValue: "Blog posts",
    intlValue: "Blog posts",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_ROLE: IntlKeyValuePair = {
    key: "role",
    defaultValue: "Role",
    intlValue: "Role",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_DEPARTMENT: IntlKeyValuePair = {
    key: "department",
    defaultValue: "Department",
    intlValue: "Department",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_COUNTRIES: IntlKeyValuePair = {
    key: "countries",
    defaultValue: "Country/Region",
    intlValue: "Country/Region",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_AREA_OF_STUDY: IntlKeyValuePair = {
    key: "areaOfStudy",
    defaultValue: "Area Of Study",
    intlValue: "Area Of Study",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_DEGREE_LEVEL: IntlKeyValuePair = {
    key: "degreeLevel",
    defaultValue: "Degree Level",
    intlValue: "Degree Level",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_CHAT_WITH: IntlKeyValuePair = {
    key: "chatWith",
    defaultValue: (name: string) => `Chat with ${name}`,
    intlValue: "Chat with {{name}}",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_SEE_MORE_STUDENTS: IntlKeyValuePair = {
    key: "seeMoreStudents",
    defaultValue: "See more students",
    intlValue: "See more students",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_SEE_MORE: IntlKeyValuePair = {
    key: "seeMore",
    defaultValue: "See more",
    intlValue: "See more",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_SEE_MORE_DESCRIPTION: IntlKeyValuePair = {
    key: "seeMoreDescription",
    defaultValue: "Read their profiles <1></1> Ask them anything about their experience <3></3>",
    intlValue: "Read their profiles <1></1> Ask them anything about their experience <3></3>",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_HAPPY_CHATTING: IntlKeyValuePair = {
    key: "happyChatting",
    defaultValue: "Happy Chatting!",
    intlValue: "Happy Chatting!",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_SECONDS_ACTIVE: IntlKeyValuePair = {
    key: "secondsActive",
    defaultValue: (time: string) => `Active ${time} seconds ago`,
    intlValue: "Active {{time}} seconds ago",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_SECONDS_ACTIVE_SINGULAR: IntlKeyValuePair = {
    key: "secondsActiveSingular",
    defaultValue: (time: string) => `Active ${time} second ago`,
    intlValue: "Active {{time}} second ago",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_MINUTES_ACTIVE: IntlKeyValuePair = {
    key: "minutesActive",
    defaultValue: (time: string) => `Active ${time} minutes ago`,
    intlValue: "Active {{time}} minutes ago",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_MINUTES_ACTIVE_SINGULAR: IntlKeyValuePair = {
    key: "minutesActiveSingular",
    defaultValue: (time: string) => `Active ${time} minute ago`,
    intlValue: "Active {{time}} minute ago",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_HOURS_ACTIVE: IntlKeyValuePair = {
    key: "hoursActive",
    defaultValue: (time: string) => `Active ${time} hours ago`,
    intlValue: "Active {{time}} hours ago",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_HOURS_ACTIVE_SINGULAR: IntlKeyValuePair = {
    key: "hoursActiveSingular",
    defaultValue: (time: string) => `Active ${time} hour ago`,
    intlValue: "Active {{time}} hour ago",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_NO_OPTIONS_MESSAGE: IntlKeyValuePair = {
    key: "noOptionsMessage",
    defaultValue: "No options",
    intlValue: "No options",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_MAJOR: IntlKeyValuePair = {
    key: "major",
    defaultValue: "Major(s)",
    intlValue: "Major(s)",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_MINOR: IntlKeyValuePair = {
    key: "minor",
    defaultValue: "Minor(s)",
    intlValue: "Minor(s)",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_CONCENTRATION: IntlKeyValuePair = {
    key: "concentration",
    defaultValue: "Concentration(s)",
    intlValue: "Concentration(s)",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_HONORS: IntlKeyValuePair = {
    key: "honors",
    defaultValue: "Honors(s)",
    intlValue: "Honors(s)",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_JOINT_HONORS: IntlKeyValuePair = {
    key: "joint_honors",
    defaultValue: "Joint Honors(s)",
    intlValue: "Joint Honors(s)",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_SPECIAL_PROGRAMME: IntlKeyValuePair = {
    key: "special_programme",
    defaultValue: "Special Programme",
    intlValue: "Special Programme",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_SPECIALISATION: IntlKeyValuePair = {
    key: "specialisation",
    defaultValue: "Specialisation",
    intlValue: "Specialisation",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_FACULTY: IntlKeyValuePair = {
    key: "faculty",
    defaultValue: "Faculty",
    intlValue: "Faculty",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_CERTIFICATION: IntlKeyValuePair = {
    key: "certification",
    defaultValue: "Certification",
    intlValue: "Certification",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_DEGREE_TYPE: IntlKeyValuePair = {
    key: "degreeType",
    defaultValue: "Degree Type",
    intlValue: "Degree Type",
    namespace: IntlNamespaces.MENTOR_CARD,
};

export const INTL_COPIED_TO_CLIPBOARD: IntlKeyValuePair = {
    key: "copiedToClipboard",
    defaultValue: "Link copied to clipboard",
    intlValue: "Link copied to clipboard",
    namespace: IntlNamespaces.MENTOR_CARD,
};
