import {IntlKeyValuePair, IntlNamespaces} from "./types";

export const INTL_ACCESSIBILITY_POLICY: IntlKeyValuePair = {
    key: "accessibilityPolicy",
    defaultValue: "Unibuddy Accessibility Policy",
    intlValue: "Unibuddy Accessibility Policy",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_STUDENTS: IntlKeyValuePair = {
    key: "students",
    defaultValue: "Students",
    intlValue: "Students",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_STAFF: IntlKeyValuePair = {
    key: "staff",
    defaultValue: "Staff",
    intlValue: "Staff",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_ALUMNI: IntlKeyValuePair = {
    key: "alumni",
    defaultValue: "Alumni",
    intlValue: "Alumni",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_BLOG: IntlKeyValuePair = {
    key: "blog",
    defaultValue: "Blog",
    intlValue: "Blog",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_CONTENT: IntlKeyValuePair = {
    key: "content",
    defaultValue: "Content",
    intlValue: "Content",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_BUDDIES: IntlKeyValuePair = {
    key: "buddies",
    defaultValue: "Buddies",
    intlValue: "Buddies",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_INBOX: IntlKeyValuePair = {
    key: "inbox",
    defaultValue: "Inbox",
    intlValue: "Inbox",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_SETTINGS: IntlKeyValuePair = {
    key: "settings",
    defaultValue: "Settings",
    intlValue: "Settings",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_SIGNUP: IntlKeyValuePair = {
    key: "signup",
    defaultValue: "Sign Up",
    intlValue: "Sign Up",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_COOKIE_SETTINGS: IntlKeyValuePair = {
    key: "cookieSettings",
    defaultValue: "Cookie settings",
    intlValue: "Cookie settings",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_ACTIVITY_STAT: IntlKeyValuePair = {
    key: "activityStat",
    defaultValue:
        "Since {{launchDate}}, <3>{{totalMessages}} questions</3> have been answered by our {{buddyType}}.",
    intlValue:
        "Since {{launchDate}}, <3>{{totalMessages}} questions</3> have been answered by our {{buddyType}}.",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_LOAD_MORE: IntlKeyValuePair = {
    key: "loadMore",
    defaultValue: "Load More",
    intlValue: "Load More",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_NO_AMBASSADOR_MSG: IntlKeyValuePair = {
    key: "noAmbassadorMsg",
    defaultValue: "Sorry, no ambassador is matching your filters.",
    intlValue: "Sorry, no ambassador is matching your filters.",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_LAUNCHING: IntlKeyValuePair = {
    key: "launching",
    defaultValue: "launching",
    intlValue: "launching",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_CONTACT_US: IntlKeyValuePair = {
    key: "contactUs",
    defaultValue: "Contact us",
    intlValue: "Contact us",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_TELL_US_A_BIT_ABOUT_YOU: IntlKeyValuePair = {
    key: "tellUsABitAboutYou",
    defaultValue: (name: string) => `${name}, tell us a bit about you`,
    intlValue: "{{name}}, tell us a bit about you",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_LOGOUT: IntlKeyValuePair = {
    key: "logout",
    defaultValue: "Logout",
    intlValue: "Logout",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_LOGGED_OUT: IntlKeyValuePair = {
    key: "loggedOut",
    defaultValue: "You are now logged out",
    intlValue: "You are now logged out",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_CLOSE: IntlKeyValuePair = {
    key: "close",
    defaultValue: "Close",
    intlValue: "Close",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_OPENS_IN_A_NEW_WINDOW: IntlKeyValuePair = {
    key: "opensInANewWindow",
    defaultValue: "Opens in a new window",
    intlValue: "Opens in a new window",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_POWERED_BY_UNIBUDDY: IntlKeyValuePair = {
    key: "poweredByUnibuddy",
    defaultValue: "Powered By Unibuddy",
    intlValue: "Powered By Unibuddy",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_REQUIRED_FIELD_INFO: IntlKeyValuePair = {
    key: "requiredFieldInfo",
    defaultValue: "All fields marked with a red asterisks <1></1> need to be filled in",
    intlValue: "All fields marked with a red asterisks <1></1> need to be filled in",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_AREA_OF_STUDY: IntlKeyValuePair = {
    key: "areaOfStudy",
    defaultValue: "Area Of Study",
    intlValue: "Area Of Study",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_FORMER_AMBASSADOR: IntlKeyValuePair = {
    key: "formerAmbassador",
    defaultValue: "Former Ambassador",
    intlValue: "Former Ambassador",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_LOGO: IntlKeyValuePair = {
    key: "logo",
    defaultValue: "logo",
    intlValue: "logo",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_NONE_SELECTED: IntlKeyValuePair = {
    key: "noneSelected",
    defaultValue: "None Selected",
    intlValue: "None Selected",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_HOME: IntlKeyValuePair = {
    key: "home",
    defaultValue: "Home",
    intlValue: "Home",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_PROFILE: IntlKeyValuePair = {
    key: "profile",
    defaultValue: "Profile",
    intlValue: "Profile",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_CHOOSE_STUDENTS: IntlKeyValuePair = {
    key: "chooseStudents",
    defaultValue: "Choose a student to chat with",
    intlValue: "Choose a student to chat with",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_CHOOSE_STAFF: IntlKeyValuePair = {
    key: "chooseStaff",
    defaultValue: "Choose a member of staff to chat with",
    intlValue: "Choose a member of staff to chat with",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_SEE_MORE_STUDENTS: IntlKeyValuePair = {
    key: "seeMoreStudents",
    defaultValue: "See more students",
    intlValue: "See more students",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_SEE_MORE_STAFF: IntlKeyValuePair = {
    key: "seeMoreStaff",
    defaultValue: "See more staff",
    intlValue: "See more staff",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_CHAT_TO_STUDENTS: IntlKeyValuePair = {
    key: "chatToStudents",
    defaultValue: "Chat to students",
    intlValue: "Chat to students",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_CHAT_TO_STAFF: IntlKeyValuePair = {
    key: "chatToStaff",
    defaultValue: "Chat to staff",
    intlValue: "Chat to staff",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_EXPLORE_STUDENT_CONTENT: IntlKeyValuePair = {
    key: "exploreStudentContent",
    defaultValue: "Explore student content",
    intlValue: "Explore student content",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_VIEW_MORE_STUDENTS: IntlKeyValuePair = {
    key: "viewMoreStudents",
    defaultValue: "View more students",
    intlValue: "View more students",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_VIEW_MORE_STAFF: IntlKeyValuePair = {
    key: "viewMoreStaff",
    defaultValue: "View more staff",
    intlValue: "View more staff",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_VIEW_MORE_CONTENT: IntlKeyValuePair = {
    key: "viewMoreContent",
    defaultValue: "View more content",
    intlValue: "View more content",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_BACK: IntlKeyValuePair = {
    key: "back",
    defaultValue: "Back",
    intlValue: "Back",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_WATCH_VIDEO: IntlKeyValuePair = {
    key: "watchVideo",
    defaultValue: "Watch video",
    intlValue: "Watch video",
    namespace: IntlNamespaces.COMMON,
};

export const INTL_READ_ARTICLE: IntlKeyValuePair = {
    key: "readArticle",
    defaultValue: "Read article",
    intlValue: "Read article",
    namespace: IntlNamespaces.COMMON,
};
