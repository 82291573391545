import get from "lodash/get";
import find from "lodash/find";
import getTokenQuery from "./queries/getTokenQuery";
import {
    setMe,
    setToken,
    setInitialUniversityAuthData,
    setTokenScheme,
    TokenScheme,
} from "./actions/authActions";
import Messenger from "../Chat/messenger";

export default class Auth {
    /**
     * Get Applicants universities linked
     * from the user object
     * @returns {array(UniversityLink} the university links or empty array if not found
     */
    static getApplicantLinkedUniversities(user) {
        return get(user, "applicant.applicantUniversities", []);
    }

    static isApplicant(user) {
        return get(user, "anyUser.accountRole") === "applicant";
    }

    /**
     * Checks if the applicant's university is setupComplete
     * from the user object
     * @returns {Boolean}
     */
    static isApplicantUniversitySetup(user, universitySlug) {
        return !!find(Auth.getApplicantLinkedUniversities(user), {
            university: {slug: universitySlug},
            setupComplete: true,
        });
    }

    /**
     * Returns the applicant's linked university if setupComplete
     * @returns {object}
     */
    static getCurrentApplicantUniversity(user, universitySlug) {
        return find(Auth.getApplicantLinkedUniversities(user), {
            university: {slug: universitySlug},
            setupComplete: true,
        });
    }

    /**
     * Check if the locally saved user has the role provided and a taken.
     * If no role is passed then any role is matched.
     * @param {string=} role - the role to check for
     * @param {object=} authState - the authState from redux
     * @returns {boolean} true if it matches the stored role, false otherwise
     */
    static loggedIn(authState, role) {
        const anyRole = get(authState, "me.anyUser.accountRole", false);
        const token = get(authState, "token", false);
        // only check the role if it is passed as an argument
        const goodRole = role ? anyRole === role : anyRole;
        return goodRole && !!token;
    }

    /**
     * Check if the locally saved user has a token and is verified.
     * @param {object=} authState - the authState from redux
     * @returns {boolean} true if the user is verified, false otherwise
     */
    static isVerified(authState) {
        return !!(
            authState &&
            get(authState, "token", "") &&
            get(authState, "me", "") &&
            get(authState, "me.anyUser.verified", false)
        );
    }

    /**
     * Get a user's token and User object using a GraphQL request and store it locally.
     * If the data is already stored then just return it.
     * @param {ApolloClient} client - the client to make the request with
     * @param {object} authState - the authState from the redux store
     * @param {function} dispatch - the dispatch method from the redux store
     * @param email
     * @param password
     * @param redirectUrl - used to send password reset email if account locks
     * @param rootUrl - used to send password reset email if account locks
     * @returns {Promise.<*>} the User object
     */
    static async login(
        client,
        authState,
        dispatch,
        email,
        password,
        redirectUrl,
        rootUrl,
        setInitialUniToken = false,
    ) {
        // Check if the user is already saved in redux
        const me = get(authState, "me", false);
        const token = get(authState, "token", false);
        const tokenScheme = authState?.tokenScheme ?? "JWT";
        if (token && get(me, "anyUser.email", "") === email) {
            await Messenger.connect(token, {}, dispatch, tokenScheme);
            return me;
        }

        // fetch a new accessToken from the server
        try {
            const result = await client.query({
                query: getTokenQuery,
                variables: {
                    email,
                    password,
                    redirectUrl,
                    rootUrl,
                },
            });
            // save the accessToken and user data in redux
            const accessToken = result.data.getToken.accessToken;
            dispatch(setToken(accessToken));
            dispatch(setTokenScheme(TokenScheme.JWT));
            if (setInitialUniToken) {
                dispatch(setInitialUniversityAuthData(accessToken, result.data.getToken.me));
            }
            dispatch(setMe(result.data.getToken.me));
            await Messenger.connect(accessToken, {}, dispatch, TokenScheme.JWT);
            return result.data.getToken.me;
        } catch (error) {
            throw JSON.parse(JSON.stringify(error));
        }
    }
}
