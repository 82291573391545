import React from "react";
import get from "lodash/get";
import noop from "lodash/noop";
import {useTranslation} from "@unibuddy/intl";
import {Box, Stack, Tiles} from "@unibuddy/patron";
import {useStringFeatureFlag} from "ub-feature-flagging-react";
import {BUDDYCARD_UI_VERSION} from "shared/constants";
import Label from "shared/General/components/Label/Label";
import useAnalytics from "shared/AnalyticsNew/hooks/useAnalytics";
import {FilterDropdown} from "../../../../../components/FilterDropdown/FilterDropdown";

const MAX_FILTER_COUNT_FOR_ONE_LINE_RENDER_SMALL = 1;
const MAX_FILTER_COUNT_FOR_ONE_LINE_RENDER_MEDIUM = 2;
const MAX_FILTER_COUNT_FOR_ONE_LINE_RENDER_LARGE = 4;

export const FilterSelection = ({filters = {}, customAttributesFilters = [], onChange = noop}) => {
    return Object.keys(filters).map((filterkey) => {
        const customAttribute =
            customAttributesFilters && customAttributesFilters.find((a) => a.id === filterkey);
        if (customAttribute) {
            const option = customAttribute.options.find((o) => o.id === filters[filterkey]);
            if (option) {
                return (
                    <Label
                        key={filterkey}
                        text={option.value}
                        onRemove={() =>
                            onChange({
                                target: {name: filterkey, value: undefined},
                            })
                        }
                    />
                );
            }
        }
        return filters[filterkey] ? (
            <Label
                key={filterkey}
                text={filters[filterkey]}
                onRemove={() => onChange({target: {name: filterkey, value: undefined}})}
            />
        ) : null;
    });
};

const calculateNoOfFilters = (roles, departments, customAttributeFilters) => {
    let col = 0;
    if (roles && roles.length) col += 1;
    if (departments && departments.length) col += 1;
    if (customAttributeFilters && customAttributeFilters.length) {
        for (let i = 0; i < customAttributeFilters.length; i += 1) {
            col += 1;
        }
    }
    return col;
};

const StaffFilters = ({filters, setFilters, buddiesFilterOptionsData}) => {
    const {
        events: {buddiesFiltered},
    } = useAnalytics();
    const {t} = useTranslation("mentorCard");
    const BUDDY_CARD_VERSION = useStringFeatureFlag(
        BUDDYCARD_UI_VERSION.key,
        BUDDYCARD_UI_VERSION.value,
    );
    const onChange = (e) => {
        setFilters({...filters, [e.target.name]: e.target.value});
        if (e.target.name && e.target.value) {
            buddiesFiltered({
                filterKey: e.target.name,
                filterValue: e.target.value,
                buddyType: "staff",
                buddyDesign: BUDDY_CARD_VERSION,
            });
        }
    };

    const roles = get(buddiesFilterOptionsData, "buddiesFilterOptions.roles", []);
    const departments = get(buddiesFilterOptionsData, "buddiesFilterOptions.departments", []);
    const customAttributesFilters = get(
        buddiesFilterOptionsData,
        "buddiesFilterOptions.customAttributesFilters",
        [],
    );

    const totalNumberOfFilters = calculateNoOfFilters(roles, departments, customAttributesFilters);

    const columns = [
        totalNumberOfFilters >= MAX_FILTER_COUNT_FOR_ONE_LINE_RENDER_SMALL
            ? MAX_FILTER_COUNT_FOR_ONE_LINE_RENDER_SMALL
            : totalNumberOfFilters,
        totalNumberOfFilters >= MAX_FILTER_COUNT_FOR_ONE_LINE_RENDER_MEDIUM
            ? MAX_FILTER_COUNT_FOR_ONE_LINE_RENDER_MEDIUM
            : totalNumberOfFilters,
        totalNumberOfFilters >= MAX_FILTER_COUNT_FOR_ONE_LINE_RENDER_LARGE
            ? MAX_FILTER_COUNT_FOR_ONE_LINE_RENDER_LARGE
            : totalNumberOfFilters,
    ];

    return (
        <Box marginBottom="gutter">
            <Stack space="small">
                <Tiles columns={columns} space="small">
                    {roles ? (
                        <FilterDropdown
                            id="role-autocomplete"
                            placeholder={t("role", "Role")}
                            name="role"
                            aria-label={t("role", "Role")}
                            setValue={filters?.role}
                            options={roles.map((x) => ({label: x, value: x}))}
                            onUpdateFilter={(name, value) =>
                                onChange({target: {name: "role", value}})
                            }
                            noOptionsMessage={() => t("noOptionsMessage", "No options")}
                        />
                    ) : null}
                    {departments ? (
                        <FilterDropdown
                            noOptionsMessage={t("noOptionsMessage", "No options")}
                            id="department-autocomplete"
                            placeholder={t("department", "Department")}
                            name="department"
                            aria-label={t("department", "Department")}
                            setValue={filters?.department}
                            options={departments.map((x) => ({label: x, value: x}))}
                            onUpdateFilter={(name, value) =>
                                onChange({target: {name: "department", value}})
                            }
                        />
                    ) : null}
                    {customAttributesFilters
                        ? customAttributesFilters.map((attribute) => (
                              <FilterDropdown
                                  noOptionsMessage={t("noOptionsMessage", "No options")}
                                  key={`${attribute.id}-autocomplete`}
                                  id={`${attribute.name}-autocomplete`}
                                  placeholder={attribute.name}
                                  name={attribute.id}
                                  aria-label={attribute.name}
                                  setValue={filters[attribute.id]}
                                  options={attribute.options.map((x) => ({
                                      label: x.value,
                                      value: x.id,
                                  }))}
                                  onUpdateFilter={(name, value) => {
                                      onChange({
                                          target: {name: attribute.id, value},
                                      });
                                  }}
                              />
                          ))
                        : null}
                </Tiles>
            </Stack>
        </Box>
    );
};

const BuddiesFilterSection = ({buddyGroupType, ...rest}) => {
    if (buddyGroupType === "staff") {
        return <StaffFilters {...rest} />;
    }
    return null;
};

export default BuddiesFilterSection;
