import {Constants} from "@unibuddy/intl";
import {useEffect} from "react";
import {useParams} from "react-router";
import {useBooleanFeatureFlag, useStringFeatureFlag} from "ub-feature-flagging-react";
import {BUDDYCARD_UI_VERSION, EMBEDDED_WIDGET_PRODUCT_OPENED_EVENT_ENABLED} from "shared/constants";
import useAnalytics from "shared/AnalyticsNew/hooks/useAnalytics";
import {productSpec, sharedEvents} from "shared/AnalyticsNew/constants/productSpecification";
import {getBucketIdFromUBUUID, getOrSetUBUUID} from "shared/Utils/uuidUtils";

const INIT_TRACKING_VISIBILITY = "init-tracking-visibility";

type UseInitialProductAnalyticsProps = {
    i18nLanguage?: string;
};

export function useInitialProductAnalytics({i18nLanguage}: UseInitialProductAnalyticsProps): void {
    const {trackEvent} = useAnalytics();
    const params = useParams<{universitySlug?: string}>();
    const ubLang = i18nLanguage ?? Constants.DEFAULT_LOCALE;
    const universitySlug = params?.universitySlug;

    const BUDDY_CARD_VERSION = useStringFeatureFlag(
        BUDDYCARD_UI_VERSION.key,
        BUDDYCARD_UI_VERSION.value,
    );

    const isProductOpenedEventEnabled = useBooleanFeatureFlag(
        EMBEDDED_WIDGET_PRODUCT_OPENED_EVENT_ENABLED.key,
        EMBEDDED_WIDGET_PRODUCT_OPENED_EVENT_ENABLED.value,
    );

    useEffect(() => {
        window.parent.postMessage({name: INIT_TRACKING_VISIBILITY, value: {}}, "*");
    }, []);

    useEffect(() => {
        if (isProductOpenedEventEnabled) {
            trackEvent(sharedEvents.productOpened.name, {
                name: productSpec.embeddedUniversityWidget.NAME,
                universitySlug,
                ubLang,
            });
        }
    }, [isProductOpenedEventEnabled, trackEvent, ubLang, universitySlug]);

    useEffect(() => {
        const messageListener = (e: MessageEvent) => {
            if (e?.data?.type === "productVisible") {
                const testingBucket = getBucketIdFromUBUUID(getOrSetUBUUID());

                trackEvent(sharedEvents.productVisible.name, {
                    name: productSpec.embeddedUniversityWidget.NAME,
                    universitySlug,
                    visibleTimeInMs: e?.data?.visibleTimeInMs ?? 0,
                    visiblePercentage: e?.data?.visiblePercentage ?? 0,
                    buddyDesign: BUDDY_CARD_VERSION,
                    widgetCohort: testingBucket,
                });
            }
        };

        window.addEventListener("message", messageListener);

        return () => {
            window.removeEventListener("message", messageListener);
        };
    }, [BUDDY_CARD_VERSION, trackEvent, universitySlug]);
}
