import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useStringFeatureFlag} from "ub-feature-flagging-react";

import {isDiglett, rootURL} from "shared/Utils/WhereAmI";
import {BUDDYCARD_UI_VERSION} from "shared/constants";

import {
    MentorFilterOptionField,
    UniversityField,
    MentorField,
} from "../../../../../types/generated";

import {StudentBuddycard} from "../../../BuddiesNew/containers/StudentBuddycard";
import {PolaroidBuddycard} from "../../../BuddiesNew/containers/PolaroidBuddycard";

type PreviewCardTypes = {
    mentor: MentorField;
    university: UniversityField;
    rootUrl: string;
    buddyPosition: number;
    onAvatarClicked?: (mentorId: string) => void;
    onChatNow: (mentorId: string, position: number) => void;
    onSeeMoreClicked?: (mentorId: string) => void;
    universityDegreeLevels?: MentorFilterOptionField[];
    isInternalBuddycard?: boolean;
};

const MentorPreviewCard = ({
    mentor,
    university,
    onAvatarClicked,
    rootUrl,
    buddyPosition,
    onChatNow,
    onSeeMoreClicked,
    universityDegreeLevels,
    isInternalBuddycard,
}: PreviewCardTypes) => {
    const [orderedMentorField, setOrderedMentorField] = useState<string[]>([]);
    const BUDDY_CARD_VERSION = useStringFeatureFlag(
        BUDDYCARD_UI_VERSION.key,
        BUDDYCARD_UI_VERSION.value,
    );

    useEffect(() => {
        const mentorFieldOrder = university?.mentorFieldOrder ?? [];
        mentorFieldOrder.sort((field1, field2) => field1.order - field2.order);
        setOrderedMentorField(mentorFieldOrder.map((mentorField) => mentorField.field));
    }, [university]);

    const mentorId = mentor.id;

    const params = new URLSearchParams(window.location.search);
    params.set("buddyPosition", buddyPosition.toString());

    const location = useLocation();
    const chatLink = `${rootURL(
        location.pathname,
    )}/inbox/chatwith/${mentorId}?${params.toString()}`;

    function handleChatButtonLink(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        // Prevent navigation so we can intercept and fire analytics
        e.preventDefault();
        onChatNow(mentorId, buddyPosition);
    }
    function handleOnAvatarClicked(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        e.preventDefault();
        onAvatarClicked(mentorId);
    }

    function handleSeeMore(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        e.preventDefault();
        onSeeMoreClicked(mentorId);
    }

    if (BUDDY_CARD_VERSION.toLowerCase() === "v3" && !isDiglett(location.pathname)) {
        return (
            <PolaroidBuddycard
                mentor={mentor}
                handleAvatarClicked={handleOnAvatarClicked}
                rootUrl={rootUrl}
                handleChatButtonLink={handleChatButtonLink}
                chatLink={chatLink}
                universityDegreeLevels={universityDegreeLevels}
            />
        );
    }

    return (
        <StudentBuddycard
            mentor={mentor}
            handleAvatarClicked={handleOnAvatarClicked}
            rootUrl={rootUrl}
            handleButtonLink={handleChatButtonLink}
            chatLink={chatLink}
            buddyPosition={buddyPosition}
            universityDegreeLevels={universityDegreeLevels}
            handleSeeMore={handleSeeMore}
            orderedMentorField={orderedMentorField}
            university={university}
            isInternalBuddycard={isInternalBuddycard}
        />
    );
};

export default MentorPreviewCard;
