/* CHANGE THIS TO YOUR QA */
const QA_ENV = "qa-9";

const IS_DEV = process?.env?.NODE_ENV === "development" ?? false;
const IS_TEST = process?.env?.NODE_ENV === "TEST" ?? false;
const IS_E2E = (IS_E2E_ENVIRONMENT === "true" || IS_E2E_ENVIRONMENT === true) ?? false;

export const FUSION_AUTH_APPLICATION_ID = IS_DEV ? "local-widget-ui" : "widget-ui";

if (IS_DEV) {
    const isMobileDebug = process.argv.includes("--mobile");
    // LOCAL CONFIGURATION | modify to your hearts content | DON'T COMMIT!
    const localConfig = {
        API_URL: `https://api-${QA_ENV}.unibuddy.co`,
        AGE_VERIFICATION_URL: "https://unibuddy.api.v1.kidswebservices.com",
        ASSETS_URL: "https://images-dev.unibuddy.co",
        LOGO_URL: "https://logos-qa.unibuddy.co",
        CONTENT_FEDERATED_MODULES_APP_URL: "https://d4btwaca5cwzr.cloudfront.net",
        DEFAULT_PROFILE_PHOTO:
            "https://s3-eu-west-1.amazonaws.com/unibudy-prod/StaticAssets/default_profile.png",
        GATEWAY_URL: `https://gateway-${QA_ENV}.unibuddy.co`,
        HORSEA_URL: `https://dev-events-${QA_ENV}.unibuddy.co`,
        MEDIA_URL: "https://s3-eu-west-1.amazonaws.com/unibuddy-images-dev-eu-west-1/",
        MIXPANEL_ID: IS_E2E ? "testtoken" : "27f68c73044b2fb3f950b44bbdb38ea2",
        MIXPANEL_PROXY_HOST: "https://tukey.unibuddy.co",
        ONE_TRUST_DOMAIN_SCRIPT: "test",
        PUSHER_APP_KEY: "29543470517ba526ec5a",
        PUSHER_APP_CLUSTER: "eu",
        TESTING: IS_TEST,
        THEME_PRIMARY: "#0171e0",
        TRANSLATIONS_URL: "https://translations-dev.unibuddy.co",
        UB_LAUNCHDARKLY_CLIENT_ID: IS_E2E ? "6279f5b655909914cfbfd585" : "6279f5c646a60e15a96fa418",
        UB_ENVIRONMENT: "local",
        UB_SERVICE: "widget-ui",
        VERSION: "v.<local>",
        HOTJAR_SITE_ID: "hotjar-test",
        STANDALONE_ASSISTANT_URL: "https://prospect-guidance-ui-qa.unibuddy.co",
        MP_OPT_OUT_BY_DEFAULT_UNI_SLUGS: [],
    };

    if (IS_DEV && isMobileDebug) {
        localConfig.API_URL = `http://localhost:5000`;
    }

    window.env = localConfig;
}

if (global && global.JEST_ACTIVE) {
    global.window.env = {
        API_URL: "http://localhost:5000/graphql",
        AGE_VERIFICATION_URL: "http://localhost",
        ASSETS_URL: "https://images-dev.unibuddy.co",
        LOGO_URL: "https://logos-qa.unibuddy.co",
        CONTENT_FEDERATED_MODULES_APP_URL: "http://localhost:8000",
        DEFAULT_PROFILE_PHOTO:
            "https://s3-eu-west-1.amazonaws.com/unibudy-prod/StaticAssets/default_profile.png",
        GATEWAY_URL: "http://localhost:4001/graphql",
        HORSEA_URL: "http://0.0.0.0:4300",
        MEDIA_URL: "https://s3-eu-west-1.amazonaws.com/unibuddy-images-dev-eu-west-1/",
        MIXPANEL_ID: "testtoken",
        MIXPANEL_PROXY_HOST: "testhost",
        ONIX_URL: "https://unibuddy.co",
        PUSHER_APP_KEY: "pusher-key",
        PUSHER_APP_CLUSTER: "eu",
        TRANSLATIONS_URL: "http://translation/",
        TESTING: "true",
        THEME_PRIMARY: "#0171e0",
        UB_LAUNCHDARKLY_CLIENT_ID: "6279f5c646a60e15a96fa418",
        UB_ENVIRONMENT: "jest-test",
        UB_SERVICE: "widget-ui",
        VERSION: "v.<local>",
        HOTJAR_SITE_ID: "hotjar-test",
        STANDALONE_ASSISTANT_URL: "https://prospect-guidance-ui-qa.unibuddy.co",
        MP_OPT_OUT_BY_DEFAULT_UNI_SLUGS: ["unibuddytest", "test-uni"],
    };
}
