import React from "react";
import {useTranslation} from "@unibuddy/intl";
import {CardBodySection} from "@unibuddy/buddycard-ui";

type MentorFieldTypes = {
    translationKey: string;
    defaultLabel: string;
    fieldValue?: string | JSX.Element;
};

export const MentorField = (props: MentorFieldTypes) => {
    const {t} = useTranslation("mentorCard");

    if (!props.fieldValue) return null;

    return (
        <CardBodySection
            heading={props.defaultLabel ? t(props.translationKey, props.defaultLabel) : null}
            content={props.fieldValue}
        />
    );
};
