export const universitiesWithCookieConsentManager = [
    "staffordshire-university",
    "brunel-university-london",
    "cphbusiness",
    "hec-paris",
    "zuyd",
    "uwe-bristol",
    "the-american-university-of-rome",
    "jacobs-university",
    "technical-university-of-denmark",
    "tu-delft",
    "university-of-groningen",
    "careers-personal-development-staffordshire",
    "university-of-strathclyde",
    "hec",
    "thomas-more-university-of-applied-sciences",
    "ecole-polytechnique",
    "arteveldehogeschool",
    "berlin-school-of-business-and-innovation",
    "skema-business-school",
    "erasmus-se",
    "erasmus-school-of-health-policy-and-management",
    "erasmus-school-of-history-culture-and-communication",
    "erasmus-school-of-law",
    "erasmus-school-of-philosophy",
    "essb",
    "erasmus-university-rotterdam",
    "ktu-erasmus",
    "bishop-s-university",
    "study-in-finland",
    "tampere-university-of-applied-sciences",
    "tampere-school-of-applied-science",
    "university-at-buffalo",
];
