import React, {useState, useRef, useEffect} from "react";
import {Box, LoadingButton} from "@unibuddy/patron";
import {useTranslation} from "@unibuddy/intl";
import {IconChevronDown} from "@unibuddy/icons";
import styled from "styled-components";

import {INTL_SEE_MORE_STUDENTS, INTL_SEE_MORE_STAFF} from "../../../../translations/common";
import {IntlNamespaces} from "../../../../translations/types";

const StyledIconChevronDown = styled(IconChevronDown)`
    margin: 0 !important;
    margin-left: 4px !important;
`;

type ViewMoreLoaderButtonProps = {
    fetchMore: () => Promise<void>;
    showButton?: boolean;
    buddyType: "STUDENT" | "STAFF";
};

export const ViewMoreLoaderButton: React.FC<ViewMoreLoaderButtonProps> = ({
    fetchMore,
    showButton = true,
    buddyType,
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const itemsElement = useRef<HTMLDivElement | null>(null);
    const {t} = useTranslation(IntlNamespaces.COMMON);
    const studentCta = t(INTL_SEE_MORE_STUDENTS.key, INTL_SEE_MORE_STUDENTS.defaultValue);
    const staffCta = t(INTL_SEE_MORE_STAFF.key, INTL_SEE_MORE_STAFF.defaultValue);

    useEffect(() => {
        if (itemsElement.current) {
            itemsElement.current.scrollTop = itemsElement.current.scrollHeight;
        }
    }, []);

    useEffect(() => {
        if (itemsElement.current) {
            const snapshot = itemsElement.current.scrollHeight - itemsElement.current.scrollTop;
            return () => {
                if (itemsElement.current) {
                    itemsElement.current.scrollTop = itemsElement.current.scrollHeight - snapshot;
                }
            };
        }
    }, []);

    const loadItems = async () => {
        try {
            setLoading(true);
            await fetchMore();
        } catch (err) {
            console.error(err);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box marginY="xxsmall" display="flex" justifyContent="center" ref={itemsElement}>
            {showButton && (
                <LoadingButton loading={loading} onClick={loadItems} round>
                    {buddyType === "STUDENT" ? studentCta : staffCta}
                    <StyledIconChevronDown width="16px" height="16px" color="inherit" />
                </LoadingButton>
            )}
        </Box>
    );
};
