import gql from "graphql-tag";
import {blogPostPreviewFragment} from "./blogPostPreviewFragment";

export const blogPostsQuery = gql`
    query BlogPostsQuery(
        $topics: [String]
        $universityId: String
        $universitySlug: String
        $degrees: [String]
        $authorId: String
        $limit: LimitedInt
        $offset: Int
        $title: String
        $order: String
    ) {
        allPostsInfo(
            topics: $topics
            universityId: $universityId
            universitySlug: $universitySlug
            degrees: $degrees
            authorId: $authorId
            safeLimit: $limit
            offset: $offset
            title: $title
            order: $order
        ) {
            blogPosts {
                ...BlogPostPreviewFragment
            }
        }
    }
    ${blogPostPreviewFragment}
`;
