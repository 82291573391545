import React from "react";
import {connect} from "react-redux";
import compose from "lodash/flowRight";
import {Box, Spinner, Text} from "@unibuddy/patron";
import {useTranslation} from "@unibuddy/intl";

import {useLazyLogout} from "shared/Auth/hooks/useLogout";
import withAuth from "shared/Auth/withAuth/withAuth";
import {clearAuth} from "shared/Auth/actions/authActions";
import withAnalytics from "shared/AnalyticsNew/withAnalytics/withAnalytics";
import {AUTH_TRANSFER, IS_EMBEDDED} from "shared/Utils/Constants";

import {PageTitle} from "../../../components/PageTitle/PageTitle";
import {getenv} from "../../../../src/util/getEnv";
import {removeSSOErrorKeys, removeSSONextPath} from "../SSO/utils";
import {IntlNamespaces} from "../../../translations/types";
import {INTL_LOGGED_OUT} from "../../../translations/common";
import {INTL_GENERIC_ERROR} from "../../../translations/errorMessage";

export const LogoutComponent = ({dispatch, analytics, pusher}) => {
    const {t} = useTranslation([IntlNamespaces.COMMON, IntlNamespaces.ERROR_MESSAGE]);
    const {logout, isLoggedOut, error} = useLazyLogout();

    React.useEffect(() => {
        const HORSEA_URL = getenv("HORSEA_URL");
        if (IS_EMBEDDED) {
            window.parent.postMessage(
                {name: AUTH_TRANSFER, value: {token: false, me: false}},
                HORSEA_URL,
            );
        }
        logout();
        if (analytics) {
            analytics.unsetUser();
        }
        if (pusher) {
            pusher.disconnect();
        }
        dispatch(clearAuth());
        removeSSOErrorKeys();
        removeSSONextPath();
    }, [dispatch, analytics, logout, pusher]);

    let content = null;
    if (error) {
        content = (
            <Text>
                {t(
                    `${IntlNamespaces.ERROR_MESSAGE}:${INTL_GENERIC_ERROR.key}`,
                    INTL_GENERIC_ERROR.defaultValue,
                )}
            </Text>
        );
    }
    if (isLoggedOut) {
        content = <Text>{t(INTL_LOGGED_OUT.key, INTL_LOGGED_OUT.defaultValue)}</Text>;
    }
    if (!content) {
        content = <Spinner />;
    }

    return (
        <>
            <PageTitle title="Logout" />
            <Box display="flex" justifyContent="center" paddingTop="small">
                {content}
            </Box>
        </>
    );
};

export default compose(
    withAuth,
    withAnalytics,
    connect((state) => ({pusher: state.messengerState.pusher})),
)(LogoutComponent);
