import React from "react";
import {useTranslation} from "@unibuddy/intl";
import {TileHeader, TileAvatar, TileInfo} from "@unibuddy/content-ui";

import {BlogPostField} from "../../../../../types/generated";
import {IntlNamespaces} from "../../../../../translations/types";
import {INTL_FORMER_AMBASSADOR} from "../../../../../translations/common";
import {getenv} from "../../../../../util/getEnv";

export function ContentCardHeader({post}: {post: BlogPostField}) {
    const defaultProfilePhoto = getenv("DEFAULT_PROFILE_PHOTO") as string;
    const {t} = useTranslation(IntlNamespaces.COMMON);
    const countryName = post?.author?.country?.name;
    const countryCode = post?.author?.country?.code;
    const country = countryName && countryCode ? {name: countryName, code: countryCode} : undefined;
    const isAuthorArchived = post?.author?.archived ?? false;
    const authorName = isAuthorArchived
        ? t(
              `${IntlNamespaces.COMMON}:${INTL_FORMER_AMBASSADOR.key}`,
              INTL_FORMER_AMBASSADOR.defaultValue,
          )
        : post?.author?.firstName;

    return (
        <TileHeader>
            <TileAvatar
                alt={`${authorName} avatar`}
                url={post?.author.profilePhoto || defaultProfilePhoto}
            />
            <TileInfo
                areaOfStudy={post?.author?.degree?.name}
                country={country}
                name={authorName}
            />
        </TileHeader>
    );
}
