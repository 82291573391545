import React from "react";
import {Box, Text} from "@unibuddy/patron";
import {Flag as NewFlag} from "@unibuddy/buddycard-ui";

import {isFeatureEnabled} from "shared/Utils/FeatureUtils";

import {MentorFields, UniversityField} from "../../../../../types/generated";

type CountryTypes = {
    university: UniversityField;
    mentorFields: MentorFields;
    placeName: string;
};

export const Country = ({university, mentorFields, placeName}: CountryTypes) => {
    const isShowCountryFlagEnabled = isFeatureEnabled("showCountryFlag", university);

    const primaryFlag = mentorFields?.primaryFlag?.code ?? "";
    const secondaryFlag = mentorFields?.secondaryFlag?.code ?? "";

    return (
        <Box display="flex" alignItems="center">
            <Text size="small">{placeName}</Text>
            <Box display="flex" paddingX="xsmall" gap="xxsmall">
                {primaryFlag && isShowCountryFlagEnabled && (
                    <NewFlag
                        alt={`Primary country flag ${mentorFields?.primaryFlag?.name}`}
                        countryCode={primaryFlag}
                        width={20}
                        height={15}
                        borderWidth={0}
                        rounded={false}
                    />
                )}
                {secondaryFlag && isShowCountryFlagEnabled && (
                    <NewFlag
                        alt={`${primaryFlag ? "Secondary" : "Primary"} country flag ${
                            mentorFields?.secondaryFlag?.name
                        }`}
                        countryCode={secondaryFlag}
                        width={20}
                        height={15}
                        borderWidth={0}
                        rounded={false}
                    />
                )}
            </Box>
        </Box>
    );
};
