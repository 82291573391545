import classNames from "classnames";
import PropTypes from "prop-types";
import React, {Suspense} from "react";
import startCase from "lodash/startCase";
import get from "lodash/get";
import {useTranslation} from "@unibuddy/intl";
import ChatNowButton from "../../../Blog/components/ChatNowButton/ChatNowButton";
import MentorCardBanner from "../../../Mentor/components/MentorCardBanner/MentorCardBanner";
import MentorCardDegree from "../../../Mentor/components/MentorCardDegree/MentorCardDegree";
import MentorCardName from "../../../Mentor/components/MentorCardName/MentorCardName";
import MentorCardSection from "../../../Mentor/components/MentorCardSection/MentorCardSection";
import MentorCardSectionItem from "../../../Mentor/components/MentorCardSectionItem/MentorCardSectionItem";
import MentorCardSeparator from "../../../Mentor/components/MentorCardSeparator/MentorCardSeparator";
import Flag from "../../../Buddies/components/Flag/Flag";
import {HasUniversityFeature} from "../../../Feature/HasUniversityFeature";
import OtherSection from "../sections/OtherSection/OtherSection";
import {isFeatureEnabled} from "../../../Utils/FeatureUtils";
import s from "./MentorCard.pcss";

const MentorCardBlogPosts = React.lazy(() => import("../MentorCardBlogPosts/MentorCardBlogPosts"));

const itemSeparator = " · ";

export const yearsValueToTranslationKey = {
    Foundation: "foundation",
    "1st Year": "1stYear",
    "2nd Year": "2ndYear",
    "3rd Year": "3rdYear",
    "4th Year": "4thYear",
    "5th Year": "5thYear",
    "6th Year": "6thYear",
    Masters: "masters",
    PhD: "phd",
};

const MentorCard = (props) => {
    const languages = props.languages || [];
    const favouriteModules = props.favouriteModules || [];
    const interests = props.interests || [];
    const societies = props.societies || [];
    const blogPosts = props.blogPosts || [];
    const customAttributes = props.customAttributes || [];
    const hasCustomAttributes = customAttributes.filter((a) => !!a.value).length > 0;
    const {t} = useTranslation(["mentorCard", "languages"]);
    const degreeTypeOrder = get(props.university, "degreeTypeOrder", []);
    const isDegreeTypesEnabled = isFeatureEnabled("enableDegreeTypes", props.university);
    const isShowCountryFlagEnabled = isFeatureEnabled("showCountryFlag", props.university);
    const degrees = {};
    props.degrees.forEach((obj) => {
        if (obj.degree) {
            if (degrees[obj.degreeType]) degrees[obj.degreeType].push(obj.degree.name);
            else degrees[obj.degreeType] = [obj.degree.name];
        }
    });
    const orderedDegrees = [];
    degreeTypeOrder.forEach((degree) => {
        if (degrees[degree.type])
            orderedDegrees.push({
                degreeType: degree.type,
                degree: degrees[degree.type],
            });
    });

    return (
        <div className={classNames(s.container, props.className)}>
            <MentorCardBanner
                name={props.firstName}
                profilePhoto={props.profilePhoto}
                lastSeen={props.lastSeen}
                imageClickable={false}
                topLeft={props.degreeLevel === "Alumni" ? "Alumni" : null}
            />

            <MentorCardName name={props.firstName} />

            {!isDegreeTypesEnabled ? <MentorCardDegree degree={props.degreeName} /> : null}

            {props.showChatNow ? (
                <div className={s.chatNow}>
                    <ChatNowButton onChatNow={props.onChatNow} name={props.firstName} />
                </div>
            ) : null}

            <MentorCardSeparator />

            <MentorCardSection title={t("summary", "In short")}>
                <MentorCardSectionItem label={t("iComeFrom", "I come from")}>
                    {props.placeLabel}
                    &nbsp;
                    {isShowCountryFlagEnabled && (
                        <Flag
                            countryCode={props.countryCode}
                            primaryFlag={props.primaryFlag}
                            secondaryFlag={props.secondaryFlag}
                        />
                    )}
                </MentorCardSectionItem>

                <MentorCardSectionItem label={t("iSpeak", "I speak")}>
                    {languages.map((l) => t(`languages:${l.name}`, l.name)).join(", ")}
                </MentorCardSectionItem>

                {props.degreeLevel !== "Alumni" ? (
                    <MentorCardSectionItem
                        label={t("currentlyIn", "I'm currently in")}
                        last
                        data-test-id="yearSection"
                    >
                        {props.degreeLevel === "Undergraduate"
                            ? t(
                                  `undergraduateYears.${get(
                                      yearsValueToTranslationKey,
                                      props.year,
                                  )}`,
                                  props.year,
                              )
                            : t(
                                  `postgraduateYears.${get(
                                      yearsValueToTranslationKey,
                                      props.year,
                                  )}`,
                                  props.year,
                              )}
                    </MentorCardSectionItem>
                ) : null}
            </MentorCardSection>

            {isDegreeTypesEnabled ? (
                <MentorCardSection
                    title={t("areaOfStudySection", "Area(s) of Study")}
                    data-test-id="areaOfStudySection"
                >
                    {orderedDegrees.map((degree) => (
                        <MentorCardSectionItem
                            label={t(
                                degree.degreeType.toLowerCase(),
                                `${startCase(degree.degreeType)}(S)`,
                            )}
                        >
                            {degree.degree.map((degreeName, index) => (
                                <div data-test-id={`${degree.degreeType}${index}`}>
                                    {degreeName}
                                </div>
                            ))}
                        </MentorCardSectionItem>
                    ))}
                </MentorCardSection>
            ) : null}

            {props.degreeLevel !== "Alumni" ? (
                <MentorCardSection
                    title={t("academicLife", "Academic life")}
                    data-test-id="academicSection"
                >
                    <MentorCardSectionItem label={t("favoriteCourses", "Favourite courses")}>
                        {favouriteModules.map((l) => l.name).join(itemSeparator)}
                    </MentorCardSectionItem>

                    <MentorCardSectionItem
                        label={t("previousStudies", "Previous Qualification")}
                        last
                    >
                        {props.pastQualification}
                    </MentorCardSectionItem>
                </MentorCardSection>
            ) : null}

            {props.degreeLevel !== "Alumni" ? (
                <MentorCardSection
                    title={t("socialLife", "Social life")}
                    data-test-id="socialSection"
                >
                    <MentorCardSectionItem label={t("hobbiesInterests", "Hobbies & interests")}>
                        {interests.map((l) => l.name).join(itemSeparator)}
                    </MentorCardSectionItem>

                    <MentorCardSectionItem label={t("societies", "Clubs & Societies")} last>
                        {societies.length > 0
                            ? societies.map((l) => l.name).join(itemSeparator)
                            : t("notPartOfSocieties", "I'm not part of any societies")}
                    </MentorCardSectionItem>
                </MentorCardSection>
            ) : null}

            {hasCustomAttributes ? (
                <HasUniversityFeature
                    data-test-id="customAttributesSection"
                    universitySlug={props.universitySlug}
                    feature="customAmbassadorAttributes"
                >
                    <OtherSection customAttributes={customAttributes} />
                </HasUniversityFeature>
            ) : null}

            <MentorCardSection title={t("aboutMe", "About me")}>
                <MentorCardSectionItem last>{props.bio}</MentorCardSectionItem>
            </MentorCardSection>

            <Suspense fallback={null}>
                <MentorCardBlogPosts
                    blogPosts={blogPosts}
                    onSelectPost={props.onSelectPost}
                    baseUrl={props.baseUrl}
                />
            </Suspense>
        </div>
    );
};

MentorCard.propTypes = {
    universitySlug: PropTypes.string.isRequired,
    profilePhoto: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    degreeName: PropTypes.string,
    degrees: PropTypes.array,
    degreeLevel: PropTypes.string.isRequired,
    placeLabel: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
    languages: PropTypes.array.isRequired,
    year: PropTypes.string.isRequired,
    favouriteModules: PropTypes.array.isRequired,
    pastQualification: PropTypes.string.isRequired,
    interests: PropTypes.array.isRequired,
    societies: PropTypes.array.isRequired,
    bio: PropTypes.string.isRequired,
    blogPosts: PropTypes.array.isRequired,
    baseUrl: PropTypes.string.isRequired,
    // eslint come on... this is being used!
    // eslint-disable-next-line react/no-unused-prop-types
    onSelectPost: PropTypes.func.isRequired,
    customAttributes: PropTypes.array,
    showChatNow: PropTypes.bool,
    onChatNow: PropTypes.func,
    lastSeen: PropTypes.string,
    className: PropTypes.string,
};

MentorCard.defaultProps = {
    lastSeen: null,
    degrees: [],
    className: "",
    showChatNow: false,
    onChatNow: () => true,
    customAttributes: [],
};

export default MentorCard;
