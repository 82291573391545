import React from "react";
import {Box, VisuallyHidden} from "@unibuddy/patron";
import {useTranslation} from "@unibuddy/intl";
import {PoweredByUnibuddy} from "shared/General/components/PoweredByUnibuddy/PoweredByUnibuddy";
import {IntlNamespaces} from "../../../../translations/types";
import {
    INTL_ACCESSIBILITY_POLICY,
    INTL_OPENS_IN_A_NEW_WINDOW,
} from "../../../../translations/common";
import {ThemedLink} from "../../../../components/ThemedLink/ThemedLink";
import {useUniversity} from "../../../modules/UniversityProvider/UniversityProvider";

export function Footer() {
    const {t} = useTranslation(IntlNamespaces.COMMON);
    const {features} = useUniversity();
    const isPoweredByUnibuddyClickDisabled = features.get("widgetPoweredByUnibuddyNotClickable");

    return (
        <Box role={"contentinfo"} paddingY="small" paddingX={["xsmall", "small"]}>
            <Box
                display="flex"
                flexDirection={["column", "row"]}
                alignItems="center"
                justifyContent="space-between"
                gap="small"
            >
                <ThemedLink
                    to="https://www.unibuddy.co/accessibility-policy"
                    external
                    aria-label={t(
                        INTL_ACCESSIBILITY_POLICY.key,
                        INTL_ACCESSIBILITY_POLICY.defaultValue,
                    )}
                >
                    {t(INTL_ACCESSIBILITY_POLICY.key, INTL_ACCESSIBILITY_POLICY.defaultValue)}
                    <VisuallyHidden>
                        {`, ${t(
                            INTL_OPENS_IN_A_NEW_WINDOW.key,
                            INTL_OPENS_IN_A_NEW_WINDOW.defaultValue,
                        )}`}
                    </VisuallyHidden>
                </ThemedLink>
                <PoweredByUnibuddy redirectOnClick={!isPoweredByUnibuddyClickDisabled} />
            </Box>
        </Box>
    );
}
