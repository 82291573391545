import React from "react";
import {useTranslation} from "@unibuddy/intl";
import {IconUbStaff} from "@unibuddy/icons";

import {IconTab} from "../IconTab/IconTab";
import {IntlNamespaces} from "../../../../../translations/types";
import {INTL_STAFF} from "../../../../../translations/common";

export default function StaffHeaderTab(props) {
    const {t} = useTranslation(IntlNamespaces.COMMON);

    return (
        <IconTab
            {...props}
            id="staff"
            name={t(INTL_STAFF.key, INTL_STAFF.defaultValue)}
            onClick={props.onClick}
            to={props.to}
            icon={() => <IconUbStaff width={32} height={32} />}
        />
    );
}
