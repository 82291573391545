import gql from "graphql-tag";

const universityFragment = gql`
    fragment UniversityFull on UniversityField {
        id
        name
        nickname
        slug
        logo
        squareLogo
        isEnterprise
        whiteLogo
        widgetUrl
        widgetUrls {
            locale
            name
            url
        }
        colour
        launchDate
        privacyPolicyUrl
        customMarketingConsentText
        customPreSignupText
        customMarketingConsentTextInLocales {
            locale
            name
            text
        }
        customPreSignupTextInLocales {
            locale
            name
            text
        }
        customChatBubbleTextInLocales {
            locale
            name
            text
        }
        country {
            id
            name
            code
        }
        entries {
            active
            entryMonths
        }
        degreeTypeOrder {
            type
            order
        }
        mentorFieldOrder {
            field
            order
        }
        config {
            features {
                feature {
                    name
                }
                enabled
                isReadOnly
            }
        }
    }
`;

export default universityFragment;
