import React, {useMemo} from "react";
import {useTranslation} from "@unibuddy/intl";
import {CardBodySection, CardBodySectionWrapper} from "@unibuddy/buddycard-ui";
import {MentorDegreeField, UniversityField} from "../../../../../types/generated";

type DegreesProps = {
    degrees: MentorDegreeField[];
    university: UniversityField;
};

type OrderedDegreeType = {
    degreeType: string;
    degree: string[];
};

export const Degrees = ({degrees = [], university}: DegreesProps) => {
    const {t} = useTranslation("mentorCard");

    const orderedDegrees: OrderedDegreeType[] = useMemo(() => {
        const degreeTypeOrder = university.degreeTypeOrder ?? [];
        const degreeMap = {};

        degrees.forEach((obj) => {
            if (obj.degree && obj.degreeType) {
                if (degreeMap[obj.degreeType]) degreeMap[obj.degreeType].push(obj.degree.name);
                else degreeMap[obj.degreeType] = [obj.degree.name];
            }
        });

        const orderedDeg: OrderedDegreeType[] = [];
        degreeTypeOrder.forEach((degree) => {
            if (degreeMap[degree.type])
                orderedDeg.push({
                    degreeType: degree.type,
                    degree: degreeMap[degree.type],
                });
        });

        return orderedDeg;
    }, [degrees, university]);

    return (
        <CardBodySectionWrapper>
            {orderedDegrees.map((degree) => {
                return (
                    <CardBodySection
                        key={degree.degreeType}
                        heading={t(degree.degreeType.toLowerCase(), `${degree.degreeType}(s)`)}
                        content={degree.degree.join(", ")}
                    />
                );
            })}
        </CardBodySectionWrapper>
    );
};
