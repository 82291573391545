import {v4 as uuidv4} from "uuid";

/*
 * Because we don't track personal information in error reports (such as ip address),
 * we create a unique ID per user otherwise it's impossible to track how many people
 * are affected by a problem.
 * To attempt to keep the number accurate we store the generated ID in localStorage
 * and then reuse it each time, however if the client blocks access to localStorage
 * we should just generate a new ID for each session.
 */
export const getOrSetUBUUID = (): string => {
    const UB_UUID = "UB_UUID";

    const existingId = localStorage?.getItem(UB_UUID);
    if (existingId) return existingId;

    const newId = uuidv4();
    localStorage.setItem(UB_UUID, newId);

    return newId;
};

/**
 * Extracts same bucket ID same UUID string.
 * This is to AB test the feature, along with Launch Darkly, targeting users
 * based on the bucket ID.
 *
 * @param {string} ubuuid - The UUID string from which to extract the bucket ID.
 * @returns {number} The bucket ID derived from the UUID.
 */
export const getBucketIdFromUBUUID = (ubuuid: string): number => {
    /*
    Note: Extracting a portion of the UUID is a way to simplify the conversion process while still maintaining a high degree of variability.
    UUIDs are 128-bit values, and converting the entire UUID to a number directly would result in an extremely large number, which is unnecessary
    for mapping to a small range like 1-20. By extracting a portion, we:
    1. Simplify Conversion: Working with a smaller substring (e.g., 8 characters) makes the conversion to an integer more manageable.
    2. Maintain Variability: Even a small portion of the UUID provides enough variability to ensure a good distribution of results.
    3. Efficiency: It reduces computational overhead compared to processing the entire UUID.
    */
    // Extract a portion of the UUID string (e.g., first 8 characters)
    const portion = ubuuid.slice(0, 8);

    // Convert the extracted portion to an integer
    const num = parseInt(portion, 16);

    // Map the integer to a number between 1 and 20 using modulo operation
    const result = (num % 20) + 1;

    return result;
};
