import React from "react";
import {Button} from "@unibuddy/patron";
import {IconArrowLeft} from "@unibuddy/icons";
import {useTranslation} from "@unibuddy/intl";
import {useHistory} from "react-router-dom";

import {IntlNamespaces} from "../../../../translations/types";
import {INTL_BACK} from "../../../../translations/common";

export const BackButton = () => {
    const {t} = useTranslation(IntlNamespaces.COMMON);
    const history = useHistory();

    return (
        <Button onClick={() => history.goBack()} clear size="sm">
            <IconArrowLeft color="currentColor" />
            {t(INTL_BACK.key, INTL_BACK.defaultValue)}
        </Button>
    );
};
