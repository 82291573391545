import React from "react";
import {Redirect, useLocation, useParams} from "react-router-dom";
import {rootURL} from "shared/Utils/WhereAmI";
import useAuth from "shared/Auth/hooks/useAuth";
import Auth from "shared/Auth/auth";
import {useUniversity} from "../../UniversityProvider/UniversityProvider";

type RegisterPage2Wrapper = {
    children: React.ReactNode;
};

export function RegisterPage2Wrapper({children}) {
    const {slug} = useUniversity();
    const {chatGroupId, opponentId} = useParams<{
        chatGroupId: string | undefined;
        opponentId: string | undefined;
    }>();
    const location = useLocation();
    const {authState} = useAuth();
    const rootUrl = rootURL(location.pathname);
    const currentApplicantUniversityLink = Auth.getCurrentApplicantUniversity(authState?.me, slug);
    const isInbox = location.pathname.split("/").includes("inbox");

    let toRelink = false;
    if (location?.state?.from === "delinked") {
        toRelink = true;
    }

    // Aim here is to ensure that prospects who aren't yet linked with a university
    // or have been delinked from a university get directed to second stage signup. This
    // essentially means that the prospect will only be able to browse the publicly available
    // pages in widget, similar to what a non-authenticated user would see before signup.
    // In all cases below, we require some auth state to exist.
    // 1. If we aren't linked with the university yet, redirect to signup stage 2.
    // 2. If we were previously linked with the university but have since been delinked, we
    // 3. Redirect to signup stage 2 if:
    // - we are on a protected path that doesn't contain "inbox" in the url
    // - location state includes from: "delinked" - in this case toRelink is true
    //      -- this happens when we are directed here from StartChat/Chat delinked UI
    // - we are on a protected path that includes "inbox" but has no chatGroupId or opponentId
    //      -- this is to protect the inbox screen, but allow us to see different UI in chat screens
    if (
        Auth.loggedIn(authState) &&
        (!currentApplicantUniversityLink ||
            (currentApplicantUniversityLink.delinked &&
                (!isInbox || toRelink || (isInbox && !chatGroupId && !opponentId))))
    ) {
        return (
            <Redirect
                to={{
                    pathname: `${rootUrl}/auth/register2`,
                    state: {nextPathname: location.pathname},
                }}
            />
        );
    }
    return <>{children}</>;
}
