import React from "react";
import {useTranslation} from "@unibuddy/intl";
import {IconUbStudent} from "@unibuddy/icons";

import {IconTab} from "../IconTab/IconTab";
import {IntlNamespaces} from "../../../../../translations/types";
import {INTL_STUDENTS} from "../../../../../translations/common";

export default function StudentsHeaderTab(props) {
    const {t} = useTranslation(IntlNamespaces.COMMON);

    return (
        <IconTab
            {...props}
            id="students"
            name={t(INTL_STUDENTS.key, INTL_STUDENTS.defaultValue)}
            onClick={props.onClick}
            to={props.to}
            icon={() => <IconUbStudent width={32} height={32} />}
        />
    );
}
