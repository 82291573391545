import React, {Suspense} from "react";
import get from "lodash/get";
import {Route, Switch, useParams} from "react-router-dom";
import {useLazyQuery} from "react-apollo";
import {useTranslation, Constants} from "@unibuddy/intl";

import {AppLocalesQueryWithUniPreference} from "shared/Translations/AppLocalesQuery";
import {AVAILABLE_LOCALES} from "shared/Utils/momentLocales";
import {getParameterByName} from "shared/Utils/UrlUtils";
import useErrorReporting from "shared/General/components/ErrorReporting/useErrorReporting";

import WidgetsRouter from "./widgets/navigation/WidgetsRoutes";
import Logout from "./widgets/modules/Logout/Logout";
import {UniversityProvider} from "./widgets/modules/UniversityProvider/UniversityProvider";
import {PageProvider} from "./widgets/modules/PageProvider/PageProvider";
import {RecommendationsProvider} from "./widgets/modules/Recommendations/RecommendationsProvider/RecommendationsProvider";
import {UnibuddyImpressionsLogo} from "./components/UnibuddyImpressionsLogo/UnibuddyImpressionsLogo";
import {SSOTrigger} from "./widgets/modules/SSO/SSOTrigger";
import {SSOHandleAuth} from "./widgets/modules/SSO/SSOHandleAuth";
import {SSOSuccess} from "./widgets/modules/SSO/SSOSuccess";
import {SSOListener} from "./widgets/modules/SSO/SSOListener";
import {ThemeProviderWrapper} from "./components/ThemeProviderWrapper/ThemeProviderWrapper";
import {LaunchDarklySDKWrapper} from "./widgets/utils/LaunchDarklySDKWrapper";

const PasswordResetRoutes = React.lazy(() => import("shared/PasswordReset/PasswordResetRoutes"));

/**
 * These are the routes currently used as embedded in uni site which contains universitySlug
 */
const widgetBasePaths = [
    "/embed/:universitySlug", // embedded widget
    "/pwa/:universitySlug", // non embedded widget
    "/:universitySlug/colour", // buddy card
    "/:universitySlug/blog", // blog feed
    "/:universitySlug/widget-traffic-driver-cta", // widget traffic driver cta
];

/**
 * A wrapper component which uses universitySlug from route params and sets the correct locale
 */
const LocaleWrapper = ({children}) => {
    const {i18n} = useTranslation();
    const {reportError} = useErrorReporting();
    const {universitySlug} = useParams();
    const [query, {data}] = useLazyQuery(AppLocalesQueryWithUniPreference);

    React.useEffect(() => {
        try {
            query({
                variables: {
                    appName: Constants.APP_NAMES.WIDGET,
                    universitySlug,
                },
            });
        } catch (err) {
            reportError(err);
        }
    }, [query, reportError, universitySlug]);

    React.useEffect(() => {
        const inContextParam = getParameterByName(window.location.href, "incontext");
        if (data && !inContextParam) {
            try {
                const appLocales = get(data, "appLocales.locales", []) || [];
                const preferredLocale =
                    get(data, "university.localePreference.locale", Constants.DEFAULT_LOCALE) ||
                    Constants.DEFAULT_LOCALE;

                const ubLang = getParameterByName(window.location.href, "ub_lang");
                const locale =
                    ubLang && appLocales.find((x) => get(x, "locale") === ubLang)
                        ? ubLang
                        : preferredLocale;

                if (i18n.language !== locale) {
                    i18n.changeLanguage(locale);
                }
                let momentLocale = Constants.DEFAULT_LOCALE.toLowerCase();
                // check if moment lib supports selected locale
                if (AVAILABLE_LOCALES.includes(locale.toLowerCase()))
                    momentLocale = locale.toLowerCase();
                else if (AVAILABLE_LOCALES.includes(locale.substr(0, 2)))
                    momentLocale = locale.substr(0, 2);
                import(`moment/locale/${momentLocale}`);
            } catch (err) {
                reportError(err);
            }
        }
    }, [i18n, data, reportError]);

    return children;
};

const Routes = () => (
    <Switch>
        <Suspense fallback={null}>
            <Route
                path={widgetBasePaths}
                render={(routeProps) => {
                    return (
                        <UniversityProvider>
                            <ThemeProviderWrapper>
                                <LaunchDarklySDKWrapper>
                                    <LocaleWrapper>
                                        <PageProvider>
                                            <UnibuddyImpressionsLogo routeProps={routeProps} />
                                            <RecommendationsProvider>
                                                {/* Listens for the bearerToken from SSO login */}
                                                <SSOListener />
                                                <WidgetsRouter {...routeProps} />
                                            </RecommendationsProvider>
                                        </PageProvider>
                                    </LocaleWrapper>
                                </LaunchDarklySDKWrapper>
                            </ThemeProviderWrapper>
                        </UniversityProvider>
                    );
                }}
            />

            {/*
             * The Auth routes below is not wrapped by a University Provider and
             * so does not have access to a uni slug to the redirect back to
             */}
            {/* Triggers the SSO popup */}
            <Route path="/auth/sso-trigger" render={() => <SSOTrigger />} />

            {/* Handles the SSO response within the popup */}
            <Route path="/auth/sso" render={() => <SSOHandleAuth />} />

            {/* Handles the SSO success within the popup */}
            <Route path="/auth/sso-success" render={() => <SSOSuccess />} />

            <Route path="/auth/logout" component={Logout} />

            <Route path="/auth/reset" render={(props) => <PasswordResetRoutes {...props} />} />
        </Suspense>
    </Switch>
);

export default Routes;
