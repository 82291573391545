import React from "react";
import {useTranslation} from "@unibuddy/intl";
import {Box, Heading, Shimmer, SkipNavContent} from "@unibuddy/patron";
import {ActivityStat} from "../../components/ActivityStat/ActivityStat";
import {INTL_CHOOSE_STUDENTS} from "../../../../../translations/common";
import {IntlNamespaces} from "../../../../../translations/types";

type StudentListPageHeaderProps = {
    finishedLoading: boolean;
    buddyGroupType: string;
    totalMessages: number;
    uniLaunchDate: string;
};

export function StudentListPageHeader({
    finishedLoading,
    buddyGroupType,
    totalMessages,
    uniLaunchDate,
}: StudentListPageHeaderProps) {
    const {t} = useTranslation(IntlNamespaces.COMMON);
    const displayActivityStat = totalMessages > 100;

    return (
        <Box display="flex" flexDirection="column">
            <Box marginBottom={displayActivityStat ? "small-medium" : "none"}>
                <SkipNavContent id="content">
                    <Heading level={2} size="small">
                        {t(INTL_CHOOSE_STUDENTS.key, INTL_CHOOSE_STUDENTS.defaultValue)}
                    </Heading>
                </SkipNavContent>
            </Box>
            {displayActivityStat && (
                <Box id="thebox">
                    {finishedLoading ? (
                        <ActivityStat
                            buddyGroupType={buddyGroupType}
                            totalMessages={totalMessages}
                            uniLaunchDate={uniLaunchDate}
                        />
                    ) : (
                        <Shimmer style={{width: "100%", height: 22}} height={22} width="550px">
                            <rect x="0" y="0" rx="12" ry="12" width="100%" height="22" />
                        </Shimmer>
                    )}
                </Box>
            )}
        </Box>
    );
}
