import React from "react";
import {Button} from "@unibuddy/patron";
import {sharedEvents} from "shared/AnalyticsNew/constants/productSpecification";
import useAnalytics from "shared/AnalyticsNew/hooks/useAnalytics";
import {IconXMark} from "@unibuddy/icons";

import {usePostMessage} from "./usePostMessage";
import useUniversityColor from "../UniversityProvider/hooks/useUniversityColor";
import {WidgetTrafficDriverIcon} from "./WidgetTrafficDriverIcon";
import {useUniversity} from "../UniversityProvider/UniversityProvider";

export function WidgetTrafficDriverCta() {
    const [open, setOpen] = React.useState(false);
    const {postMessage} = usePostMessage();
    const {trackEvent} = useAnalytics();
    const {slug: universitySlug} = useUniversity();
    const universityColor = useUniversityColor();

    const handleClick = (currentOpen) => {
        postMessage(`widgetTrafficDriverVisible=${!open as unknown as string}`);
        setOpen(!open);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        trackEvent(sharedEvents.widgetTrafficDriverCtaButtonClicked.name, {
            universitySlug,
            action: !currentOpen ? "Open" : "Close",
            cta: "Widget Traffic Driver",
        });
    };

    return (
        <Button
            onClick={() => handleClick(open)}
            style={{
                width: "56px",
                height: "56px",
                backgroundColor: universityColor,
                color: "white",
            }}
            round
            iconOnly
            aria-label="Chat to students and staff"
        >
            {!open ? <WidgetTrafficDriverIcon /> : <IconXMark width="32px" height="32px" />}
        </Button>
    );
}
