import {DocumentNode} from "graphql";
import gql from "graphql-tag";
import {useMutation, MutationHookOptions} from "react-apollo";
import {Scalars, Exact} from "../../../types";

export const WIDGET_HEIGHT_COLLAPSED = "42px";
export const WIDGET_HEIGHT_COLLAPSED_MOBILE = "48px";
export const SMALL_SCREEN_WIDTH = "460px";
export const MEDIUM_SCREEN_WIDTH = "600px";
export const MAX_SLIDES_TO_SHOW = 3;
export const NEXT_BEST_ACTIONS_FLAG_DEFAULT = false;

export const generateRecommendations: DocumentNode = gql`
    mutation generateRecommendations(
        $universityId: ObjectId!
        $batchSize: Int!
        $countryId: ObjectId
        $domesticRegionId: ObjectId
        $degreeLevelId: ObjectId
        $areaOfStudyIds: [ObjectId!]
        $expectedEntry: DateTime
    ) {
        generateRecommendations(
            generateRecommendationsInput: {
                universityId: $universityId
                batchSize: $batchSize
                countryId: $countryId
                domesticRegionId: $domesticRegionId
                degreeLevelId: $degreeLevelId
                areaOfStudyIds: $areaOfStudyIds
                expectedEntry: $expectedEntry
            }
        ) {
            id
            contentId
            batchId
            title
            description
            url
            type
        }
    }
`;

export const enum RecommendationResourceTypeEnum {
    BLOG = "BLOG",
    COMMUNITY = "COMMUNITY",
    EVENT = "EVENT",
    AMBASSADOR = "AMBASSADOR",
    ARTICLE = "ARTICLE",
    VIDEO = "VIDEO",
    UNIVERSITY_WEBPAGE = "UNIVERSITY_WEBPAGE",
    OTHER = "OTHER",
}

export type Recommendation = {
    id: string;
    contentId: string;
    title: string;
    url: string;
    type: RecommendationResourceTypeEnum;
    batchId: string;
    description: string;
};

export const nbaResponsiveConfig = [
    {
        breakpoint: parseInt(MEDIUM_SCREEN_WIDTH, 10),
        settings: {
            slidesToShow: 2,
            centerMode: true,
            centerPadding: "20px",
        },
    },
    {
        breakpoint: parseInt(SMALL_SCREEN_WIDTH, 10),
        settings: {
            slidesToShow: 1,
            centerMode: true, //true so we can see part of the next card, requires CSS hack (add in the StyledCarousel)
            centerPadding: "20px", //how much of the next card we can see
        },
    },
];

export type GenerateRecommendationsVariables = Exact<{
    universityId: Scalars["String"];
    batchSize: Scalars["Int"];
    countryId?: Scalars["String"];
    domesticRegionId?: Scalars["String"];
    degreeLevelId?: Scalars["String"];
    areaOfStudyIds?: Scalars["String"][];
    expectedEntry?: Scalars["DateTime"];
}>;

export type GenerateRecommendationsMutation = {
    __typename?: "Mutation";
    generateRecommendations: Array<{
        __typename?: "Recommendation";
        id: string;
        contentId: string;
        title: string;
        url: string;
        type: RecommendationResourceTypeEnum;
        batchId: string;
        description: string;
    }>;
};

export const saveVisitedRecommendation: DocumentNode = gql`
    mutation saveVisitedRecommendation($contentId: ObjectId!, $batchId: ObjectId!) {
        saveVisitedRecommendation(
            saveVisitedRecommendationInput: {contentId: $contentId, batchId: $batchId}
        ) {
            id
            contentId
            batchId
            title
            description
            url
            type
        }
    }
`;

type SaveVisitedRecommendationVariables = Exact<{
    contentId: Scalars["String"];
    batchId: Scalars["String"];
}>;

type SaveVisitedRecommendationMutation = {
    __typename?: "Mutation";
    saveVisitedRecommendation: {
        __typename?: "Recommendation";
        id: string;
        contentId: string;
        title: string;
        url: string;
        type: RecommendationResourceTypeEnum;
        batchId: string;
        description: string;
    };
};

export const useSaveVisitedRecommendationMutation = (
    options?: MutationHookOptions<
        SaveVisitedRecommendationMutation,
        SaveVisitedRecommendationVariables
    >,
) =>
    useMutation<SaveVisitedRecommendationMutation, SaveVisitedRecommendationVariables>(
        saveVisitedRecommendation,
        options,
    );
