import React from "react";
import {useTranslation} from "@unibuddy/intl";
import {Box} from "@unibuddy/patron";

import {PoweredByLogo} from "../../../../components/PoweredByLogo/PoweredByLogo";
import {IntlNamespaces} from "../../../../translations/types";
import {
    INTL_POWERED_BY_UNIBUDDY,
    INTL_OPENS_IN_A_NEW_WINDOW,
} from "../../../../translations/common";

export const PoweredByUnibuddy = ({redirectOnClick = true}: {redirectOnClick?: boolean}) => {
    const {t} = useTranslation(IntlNamespaces.COMMON);

    if (redirectOnClick) {
        const poweredByLabel = t(
            INTL_POWERED_BY_UNIBUDDY.key,
            INTL_POWERED_BY_UNIBUDDY.defaultValue,
        );
        const linkLabel = t(
            `${IntlNamespaces.COMMON}:${INTL_OPENS_IN_A_NEW_WINDOW.key}`,
            INTL_OPENS_IN_A_NEW_WINDOW.defaultValue,
        );
        const label = `${poweredByLabel}, ${linkLabel}`;

        return (
            <Box display="inline-block">
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.unibuddy.com"
                    aria-label={label}
                >
                    <PoweredByLogo
                        title={t(
                            INTL_POWERED_BY_UNIBUDDY.key,
                            INTL_POWERED_BY_UNIBUDDY.defaultValue,
                        )}
                    />
                </a>
            </Box>
        );
    }

    return (
        <Box display="inline-block">
            <PoweredByLogo
                title={t(
                    `${IntlNamespaces.COMMON}:${INTL_POWERED_BY_UNIBUDDY.key}`,
                    INTL_POWERED_BY_UNIBUDDY.defaultValue,
                )}
            />
        </Box>
    );
};
