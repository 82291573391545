import {Box} from "@unibuddy/patron";
import * as React from "react";
import {RouteComponentProps} from "react-router-dom";
import {StaticContext} from "react-router";
import {getenv} from "../../util/getEnv";
import {useUniversity} from "../../widgets/modules/UniversityProvider/UniversityProvider";
import {getLogoReferrer} from "../../util/referrer";

const LOGO = "unibuddy-logo.png";

export type UnibuddyImpressionsLogoProps = {
    routeProps: RouteComponentProps<
        {
            [x: string]: string;
        },
        StaticContext,
        unknown
    >;
};

export const UnibuddyImpressionsLogo = ({routeProps}: UnibuddyImpressionsLogoProps) => {
    const university = useUniversity();
    const LOGO_URL = getenv("LOGO_URL") as string;
    const path = routeProps?.history?.location.pathname;
    const referrer = getLogoReferrer(path);

    const url = `${LOGO_URL}/logos/${referrer}/${university.id}/${LOGO}`;

    return (
        <Box style={{display: "none", visibility: "hidden"}}>
            <img src={url} id="__decorative-logo" alt="Decorative Logo" />
        </Box>
    );
};
