import * as URLParamHelpers from "shared/Utils/UrlUtils";
import "@babel/polyfill";
import {DesignSystemProvider} from "@unibuddy/patron";
import mixpanelAdaptor from '@unibuddy/tracking/dist/adaptors/mixpanel';
import "whatwg-fetch";
import get from "lodash/get";
import {hot} from "react-hot-loader/root";
import {Provider} from "react-redux";
import {ApolloLink} from "apollo-link";
import React, {Component} from "react";
import {persistStore} from "redux-persist";
import {ApolloClient} from "apollo-client";
import {ApolloProvider} from "react-apollo";
import {Router} from "react-router-dom";
import {InMemoryCache, IntrospectionFragmentMatcher} from "apollo-cache-inmemory";
import createHistory from "history/createBrowserHistory";
import Intl, {Constants, i18n, I18nextEngine} from "@unibuddy/intl";

import {checkUserCookieConsent, getUniversitySlug} from "shared/Utils/CookieConsentUtils";
import ErrorBoundary from "shared/General/components/ErrorBoundary/ErrorBoundary";
import {ErrorReportingProvider} from "shared/General/components/ErrorReporting/ErrorReportingProvider";
import {
    errorReportingAdaptor,
    initErrorReportingService,
} from "shared/General/components/ErrorReporting/errorReportingAdaptor";
import Messenger from "shared/Chat/messenger";
import AuthProvider from "shared/Auth/AuthProvider/AuthProvider";
import SocketProvider from "shared/Experimental/Sockets/SocketProvider/SocketProvider";
import defaultLink from "shared/Core/defaultLink";
import UniversalAnalyticsProvider from "shared/AnalyticsNew/AnalyticsProvider/UniversalAnalyticsProvider";
import SourceTrackingProvider from "shared/SourceTracking/SourceTrackingProvider/SourceTrackingProvider";
import BuddiesFiltersProvider from "shared/Buddies/components/BuddiesFiltersProvider/BuddiesFiltersProvider";
import {clearAuth, setMe, setToken} from "shared/Auth/actions/authActions";
import {
    AUTH_CLEARED,
    AUTH_TRANSFER,
    AUTH_TRANSFER_READY,
    COOKIE_CATEGORIES,
    IS_EMBEDDED,
    MIXPANEL,
} from "shared/Utils/Constants";
import AppLocalesQuery from "shared/Translations/AppLocalesQuery";
import {getConsents} from "shared/CookieManager/CookieManager";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {getApolloClientReferrer} from "./util/referrer";
import configureStore from "./configureStore";
import Routes from "./Routes";
import "./main.pcss";
import "./app.css";
import {getenv} from "./util/getEnv";
import {isPwa} from "./cookieSetting";
import {enableHotjar} from "./hotjar";

// Initialise Sentry
initErrorReportingService();

const history = createHistory();

const store = configureStore();

/* Get a handle on the dispatch function
 * @return {Dispatch}
 * */
export const getReduxDispatch = () => store.dispatch;
/* Get a handle on redux state */
export const getReduxState = () => store.getState;

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
        __schema: {
            types: [],
        },
    },
});

const link = ApolloLink.from([defaultLink(store)]);

const cache = new InMemoryCache({
    fragmentMatcher,
});

const clientName = getApolloClientReferrer(window.location.pathname);
const client = new ApolloClient({
    link,
    cache,
    name: clientName,
    version: window.env?.VERSION ?? "unknown",
});

// Initialise i18next
const options = {
    appName: Constants.APP_NAMES.WIDGET,
    lng: URLParamHelpers.getParameterByName(window.location.href, "ub_lang"),
    translationsUrl: getenv("TRANSLATIONS_URL"),
};
const i18nextEngine = new I18nextEngine(options);

function initializeMixpanelWithAdaptors(adaptors) {
    const MP_OPT_OUT_BY_DEFAULT_UNI_SLUGS = getenv("MP_OPT_OUT_BY_DEFAULT_UNI_SLUGS", []);

    const universitySlug = getUniversitySlug(window.location.href);
    let optOutByDefault = false;
    if (MP_OPT_OUT_BY_DEFAULT_UNI_SLUGS) {
        optOutByDefault = MP_OPT_OUT_BY_DEFAULT_UNI_SLUGS.some((s) => {
            const slug = s.trim().toLowerCase();
            const allDisabled = slug === "all";
            const uniDisabled = slug === universitySlug.toLowerCase();

            return allDisabled || uniDisabled;
        });
    }

    const MIXPANEL_ID = getenv("MIXPANEL_ID");
    const MIXPANEL_PROXY_HOST = getenv("MIXPANEL_PROXY_HOST");

    mixpanelAdaptor.init(MIXPANEL_ID, {
        api_host: MIXPANEL_PROXY_HOST,
        opt_out_persistence_by_default: optOutByDefault,
        opt_out_tracking_by_default: optOutByDefault,
        ip: !optOutByDefault,
    });

    adaptors.push(mixpanelAdaptor);
}

export const initMixpanel = (preferences, adaptors) => {
    let mixpanelInit = false;
    preferences.forEach((preference) => {
        if (preference) {
            const key = preference.trim().toLowerCase();
            if (
                !mixpanelInit &&
                COOKIE_CATEGORIES.has(key) &&
                COOKIE_CATEGORIES.get(key).includes(MIXPANEL)
            ) {
                initializeMixpanelWithAdaptors(adaptors);
                mixpanelInit = true;
            }
        }
    });
};

export const setAnalyticsAdaptors = (adaptors) => {
    if (isPwa()) {
        const preferences = getConsents();
        initMixpanel(preferences, adaptors);
        enableHotjar(preferences);
        return;
    }

    const checkCookieConsent = checkUserCookieConsent(window.location.href);

    //If the University has not yet implemented cookie consent manager, then we do not check for
    //user cookie consent
    if (!checkCookieConsent) {
        initializeMixpanelWithAdaptors(adaptors);
        enableHotjar();
    } else {
        const cookieConsent = URLParamHelpers.getParameterByName(
            window.location.href,
            "ub_cookie_consent",
        );
        if (cookieConsent) {
            const preferences = cookieConsent.split(" ");
            initMixpanel(preferences, adaptors);
            enableHotjar(preferences);
        }
    }
};

// Setup the history so that if this is a hosted jigglypuff app, we notify the parent iframe when
// the page changes. This allows us to update the scroll position of the page so users do not end
// up looking at a blank screen after navigating when they have scrolled down the page. See the
// unibuddy-iframe.js script in iframe-scripts
history.listen(() => {
    if ("parentIFrame" in window) {
        window.parentIFrame.sendMessage("pageChange");
    }
});

export const sourceTrackingParams = {
    ub_medium: URLParamHelpers.getParameterByName(window.location.href, "ub_medium"),
    ub_source: URLParamHelpers.getParameterByName(window.location.href, "ub_source"),
    ub_campaign: URLParamHelpers.getParameterByName(window.location.href, "ub_campaign"),
    ub_content: URLParamHelpers.getParameterByName(window.location.href, "ub_content"),
    invite_id: URLParamHelpers.getParameterByName(window.location.href, "invite_id"),
};

export const analyticsAdaptors = [];

class App extends Component {
    state = {rehydrated: false, userObj: undefined};

    async componentDidMount() {
        const ubLang = URLParamHelpers.getParameterByName(window.location.href, "ub_lang");
        const inContextParam = URLParamHelpers.getParameterByName(
            window.location.href,
            "incontext",
        );
        let changeToDefault = false;
        // Check if the given lang is enabled for app
        if (ubLang && client && !inContextParam) {
            try {
                const {
                    data: {appLocales},
                } = await client.query({
                    query: AppLocalesQuery,
                    variables: {
                        appName: Constants.APP_NAMES.WIDGET,
                    },
                });
                if (!appLocales.locales.find((x) => x.locale === ubLang)) changeToDefault = true;
            } catch (e) {
                changeToDefault = true;
            }
        }
        if (changeToDefault) {
            await i18n.changeLanguage(Constants.DEFAULT_LOCALE);
        }
    }

    componentWillMount() {
        persistStore(store, {whitelist: ["authState"]}, async () => {
            const storeContent = store.getState();
            const token = get(storeContent, "authState.token", false);
            const tokenScheme = storeContent?.authState?.tokenScheme ?? "JWT";
            const messengerState = get(store.getState(), "messengerState");
            const userData = get(storeContent, "authState.me", undefined);
            let userTempObj;
            if (userData) {
                const {anyUser, universityUser} = userData;
                userTempObj = {...anyUser, ...universityUser};
            }
            this.setState({rehydrated: true, userObj: userTempObj});

            if (token) {
                await Messenger.connect(token, messengerState, store.dispatch, tokenScheme);
            }

            window.addEventListener("message", (message) => {
                if (message.data.name === AUTH_TRANSFER) {
                    if (!message.data.value.token) {
                        store.dispatch(clearAuth());
                        window.parent.postMessage({name: AUTH_CLEARED}, "*");
                    } else {
                        store.dispatch(setMe(message.data.value.me));
                        store.dispatch(setToken(message.data.value.token));
                    }
                }
            });

            if (IS_EMBEDDED) {
                window.parent.postMessage({name: AUTH_TRANSFER_READY}, "*");
            }
        });
    }

    render() {
        if (!this.state.rehydrated) {
            return <div id="unibuddy-loading">Loading...</div>;
        }
        return (
            <ErrorReportingProvider adaptor={errorReportingAdaptor}>
                <ErrorBoundary>
                    <UniversalAnalyticsProvider
                        adaptors={analyticsAdaptors}
                        user={this.state.userObj}
                    >
                        <SourceTrackingProvider params={sourceTrackingParams}>
                            <ApolloProvider client={client}>
                                <Provider store={store}>
                                    <Router history={history}>
                                        <BuddiesFiltersProvider>
                                            <AuthProvider>
                                                <SocketProvider>
                                                    <DesignSystemProvider>
                                                        <Intl
                                                            engine={i18nextEngine}
                                                            crowdinProjectName={
                                                                Constants.CROWDIN_PROJECT_NAMES
                                                                    .WIDGET
                                                            }
                                                        >
                                                            <Routes />
                                                        </Intl>
                                                    </DesignSystemProvider>
                                                </SocketProvider>
                                            </AuthProvider>
                                        </BuddiesFiltersProvider>
                                    </Router>
                                </Provider>
                            </ApolloProvider>
                        </SourceTrackingProvider>
                    </UniversalAnalyticsProvider>
                </ErrorBoundary>
            </ErrorReportingProvider>
        );
    }
}

export default hot(App);
