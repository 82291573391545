import React from "react";
import {Box, Text} from "@unibuddy/patron";
import {
    IconCalendar,
    IconChatBubbleLeftRight,
    IconShare,
    IconUbArticle,
    IconUbStudent,
    IconVideoCamera,
} from "@unibuddy/icons";

import {RecommendationResourceTypeEnum} from "../NextBestActions";
import useUniversityColor from "../../UniversityProvider/hooks/useUniversityColor";
import {titleCase} from "../../../../util/titleCase";

function determineContentTypeIconText(contentType: RecommendationResourceTypeEnum, color: string) {
    let chipIcon = null;

    switch (contentType) {
        case RecommendationResourceTypeEnum.BLOG:
            chipIcon = <IconUbArticle height={17} width={17} color={color} />;
            break;

        case RecommendationResourceTypeEnum.COMMUNITY:
            chipIcon = <IconChatBubbleLeftRight height={17} width={17} color={color} />;
            break;

        case RecommendationResourceTypeEnum.EVENT:
            chipIcon = <IconCalendar height={17} width={17} color={color} />;
            break;

        case RecommendationResourceTypeEnum.AMBASSADOR:
            chipIcon = <IconUbStudent height={17} width={17} color={color} />;
            break;

        case RecommendationResourceTypeEnum.ARTICLE:
            chipIcon = <IconUbArticle height={17} width={17} color={color} />;
            break;

        case RecommendationResourceTypeEnum.VIDEO:
            chipIcon = <IconVideoCamera height={17} width={17} color={color} />;
            break;

        case RecommendationResourceTypeEnum.UNIVERSITY_WEBPAGE:
            chipIcon = <IconUbArticle height={17} width={17} color={color} />;
            break;

        case RecommendationResourceTypeEnum.OTHER:
            chipIcon = <IconShare height={17} width={17} color={color} />;
            break;

        default:
            chipIcon = <IconUbArticle height={17} width={17} color={color} />;
            break;
    }
    return {
        chipIcon,
        chipText: titleCase(contentType),
    };
}

export default function ContentTypeChip({
    contentType,
    borderless = false,
}: {
    contentType: RecommendationResourceTypeEnum;
    borderless?: boolean;
}) {
    const color = useUniversityColor();
    const {chipIcon, chipText} = determineContentTypeIconText(contentType, color);
    return (
        <Box
            h="24px"
            borderRadius="full"
            borderWidth={1}
            borderColor={borderless ? "white" : color}
            paddingY="xxsmall"
            paddingX="xsmall"
            display="inline-flex"
            flexDirection="row"
            alignItems="center"
            flexShrink={0}
            vertical-align="center"
            gap="xxsmall"
            bgColor="white"
            data-test-id="chip-container"
        >
            <Box h="17px" w="17px" data-test-id="icon-container">
                {chipIcon}
            </Box>
            <Text size="xxsmall" lineHeight="16px" nowrap weight="700" color={color}>
                {chipText}
            </Text>
        </Box>
    );
}
