import React from "react";
import {PatronThemeProvider, View} from "@unibuddy/patron";
import {TileMedia, MediaType, contentUITheme} from "@unibuddy/content-ui";

import {ContentCardBody} from "./ContentCardBody";
import {ContentCardHeader} from "./ContentCardHeader";
import {getMediaAndMediaType} from "./utils";
import {BlogPostField} from "../../../../../types/generated";

const customContentUITheme = {
    ...contentUITheme,
    TileBody: {
        default: {
            flex: null,
        },
    },
};

// This replicates Tile from content-ui without fixed width and height
const Tile = ({children}) => {
    return (
        <View
            position="relative"
            borderRadius="xsmall"
            overflow="hidden"
            flexDirection="column"
            bgColor="white"
            flexShrink={0}
            borderWidth={1}
            borderColor="grey150"
            w="100%"
            h="412px"
            // Added minH because Safari/iPhones break when using 100%
            // instead of a hardcoded value. We chose 340px because across
            // our current breakpoints (29/10/2024), this is the smallest
            // any card can ever be.
            minH="340px"
            maxH="412px"
        >
            {children}
        </View>
    );
};

export function ContentCard({
    post,
    contentUrl,
    position,
}: {
    post: BlogPostField;
    contentUrl: string;
    position: number;
}) {
    const {media, mediaType} = getMediaAndMediaType(post);

    if (!media || !mediaType) return null;

    return (
        <Tile>
            <TileMedia media={media} mediaType={mediaType} autoPlay={false}>
                <ContentCardHeader post={post} />
                {mediaType !== MediaType.IMAGE && (
                    <ContentCardBody
                        post={post}
                        contentUrl={contentUrl}
                        mediaType={mediaType}
                        position={position}
                    />
                )}
            </TileMedia>
            {mediaType === MediaType.IMAGE && (
                <PatronThemeProvider theme={customContentUITheme}>
                    <ContentCardBody
                        post={post}
                        contentUrl={contentUrl}
                        mediaType={mediaType}
                        position={position}
                    />
                </PatronThemeProvider>
            )}
        </Tile>
    );
}
