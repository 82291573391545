import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {ProviderConfig, asyncWithLDProvider} from "launchdarkly-react-client-sdk";
import {getBucketIdFromUBUUID, getOrSetUBUUID} from "shared/Utils/uuidUtils";
import {getenv} from "../../util/getEnv";
import {useUser} from "../modules/User/useUser";
import {useUniversity} from "../modules/UniversityProvider/UniversityProvider";

const LAUNCHDARKLY_CLIENT_ID = getenv("UB_LAUNCHDARKLY_CLIENT_ID") as string;

export const LaunchDarklySDKWrapper = ({children}) => {
    const {universitySlug} = useParams<{universitySlug: string}>();
    const university = useUniversity();
    const user = useUser();
    const launchDarklyProviderRef = useRef<React.ComponentType | null>(null);
    const [isProviderReady, setIsProviderReady] = useState(false);
    const testingBucket = getBucketIdFromUBUUID(getOrSetUBUUID());

    // University
    const launchDarklyUniversity = {
        id: university?.id,
        slug: universitySlug,
        name: university?.name,
    };

    // User
    const launchDarklyUserKey = `widget-cohort-${testingBucket}`;
    const accountRole = user?.accountRole;
    const userId = user?.id;

    const launchDarklyUserOptions = {
        key: launchDarklyUserKey,
        university: launchDarklyUniversity,

        // Backwards compatibility
        universityId: launchDarklyUniversity?.id,
        universitySlug: launchDarklyUniversity?.slug,
        universityName: launchDarklyUniversity?.name,
    };

    const launchDarklyUser = userId
        ? {anonymous: false, accountRole, email: user.email, ...launchDarklyUserOptions}
        : {anonymous: true, ...launchDarklyUserOptions};

    useEffect(() => {
        const initializeLDProvider = async () => {
            if (isProviderReady || launchDarklyProviderRef.current) return;

            const launchDarklyConfig: ProviderConfig = {
                clientSideID: LAUNCHDARKLY_CLIENT_ID,
                context: {
                    kind: "multi",
                    user: {
                        kind: "user",
                        ...launchDarklyUser,
                    },
                    university:
                        university && university.slug
                            ? {
                                  kind: "university",
                                  key: university.slug,
                                  id: university.id,
                                  name: university.name,
                                  slug: university.slug,
                              }
                            : {kind: "university", key: "anonymousUniversity", anonymous: true},
                },
                reactOptions: {
                    useCamelCaseFlagKeys: false,
                },
                timeout: 5,
            };

            const LaunchDarklySDKProvider = await asyncWithLDProvider(launchDarklyConfig);
            launchDarklyProviderRef.current = LaunchDarklySDKProvider;
            setIsProviderReady(true);
        };

        initializeLDProvider();
    }, [isProviderReady, launchDarklyUser, university]);

    if (!isProviderReady || !launchDarklyProviderRef.current) {
        console.info("Loading LaunchDarkly...");
        return null;
    }

    const LaunchDarklyProvider = launchDarklyProviderRef.current;

    return <LaunchDarklyProvider>{children}</LaunchDarklyProvider>;
};
