import React, {useMemo} from "react";
import {PatronThemeProvider, usePatronTheme} from "@unibuddy/patron";
import {contentUITheme} from "@unibuddy/content-ui";
import {buddycardUITheme} from "@unibuddy/buddycard-ui";
import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";

import useUniversityColor from "../../widgets/modules/UniversityProvider/hooks/useUniversityColor";

export function ThemeProviderWrapper({children}) {
    const defaultTheme = usePatronTheme();
    const universityColor = useUniversityColor();
    const universityHoverColor = `${universityColor}D9`; // E6 is 90%, D9 is 85%, CC is 80%, BF is 75%
    const universityClearHoverColor = `${universityColor}33`; // 1A is 10%, 26 is 15%, 33 is 20%, 40 is 25%

    const universityTheme = {
        colors: {
            universityColor,
            universityHoverColor,
            universityClearHoverColor,
        },
        Button: {
            colors: {
                university: {
                    color: "white",
                    bgColor: universityColor,
                    borderColor: universityColor,
                    hoverBgColor: universityHoverColor,
                    outlineColor: universityHoverColor,
                    clearColor: universityColor,
                    clearHoverBgColor: universityClearHoverColor,
                },
            },
        },
        CardLink: {
            color: universityColor,
            hoverColor: universityHoverColor,
            activeColor: universityColor,
        },
    };

    const staticTheme = useMemo(
        () => merge(cloneDeep(defaultTheme), contentUITheme, buddycardUITheme),
        // Leaving deps array empty because none of these themes should change
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const finalTheme = useMemo(() => {
        return merge(cloneDeep(staticTheme), universityTheme);
        // Omitted staticTheme from deps as it should not change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [universityTheme]);

    return <PatronThemeProvider theme={finalTheme}>{children}</PatronThemeProvider>;
}
