// eslint-disable-next-line import/prefer-default-export
export const PRESIGNUP_MESSAGE_KEY = (opponentId: string): string =>
    `preSignUpMessage-${opponentId}`;

// Sign up
export const SIGN_UP_ERROR_MESSAGE =
    "Sorry, something went wrong. Try logging in if you already have an account";

export const UNIVERSITY_MULTI_CHOICE_CHAT = "0x7FFFFFFF";

// Launch Darkly Feature flags
export const NEXT_BEST_ACTIONS_FLAG = "ub-widget-next-best-actions";

type StringFeatureFlagProps = {
    key: string;
    value: string;
};

export type BooleanFeatureFlagProps = {
    key: string;
    value: boolean;
};

type NumberFeatureFlagProps = {
    key: string;
    value: number;
};

export const SHOW_INBOX_PROMPT: BooleanFeatureFlagProps = {
    key: "ub-widget-inbox-prompt",
    value: false,
};

export const SHOW_FOR_YOU_TAB: BooleanFeatureFlagProps = {
    key: "ub-widget-for-you-tab",
    value: false,
};

export const SHOW_RECOMMENDATION_PROMPT: BooleanFeatureFlagProps = {
    key: "ub-widget-recommendation-prompt",
    value: false,
};

export const SHOW_UNIVERSITY_INBOX_MESSAGE: BooleanFeatureFlagProps = {
    key: "ub-widget-university-inbox-message",
    value: false,
};

export const UB_EDM_COHORTS_ENABLED: BooleanFeatureFlagProps = {
    key: "ub-university-dashboard-edm-cohorts",
    value: false,
};

export const DEANONYMISE_PROSPECT_ENABLED: BooleanFeatureFlagProps = {
    key: "ub-university-dashboard-deanonymise-prospect",
    value: false,
};

export const SHOW_ASSISTANT_INBOX_MESSAGE: BooleanFeatureFlagProps = {
    key: "ub-widget-assistant-inbox-message",
    value: false,
};

export const SHOW_INTRO_PAGE: BooleanFeatureFlagProps = {
    key: "ub-widget-intro-page",
    value: false,
};

export const INTRO_PAGE_CONTENT_TYPE: StringFeatureFlagProps = {
    key: "ub-widget-intro-page-content-type",
    value: "none",
};

export const TRAFFIC_DRIVERS_PRODUCT_OPENED_EVENT_ENABLED: BooleanFeatureFlagProps = {
    key: "ub-traffic-drivers-product-opened-event",
    value: false,
};

export const EMBEDDED_WIDGET_PRODUCT_OPENED_EVENT_ENABLED: BooleanFeatureFlagProps = {
    key: "ub-widget-embedded-product-opened-event",
    value: false,
};

export const SSO_ENABLED: BooleanFeatureFlagProps = {
    key: "ub-widget-sso-enabled",
    value: false,
};

export const BUDDYCARD_UI_VERSION: StringFeatureFlagProps = {
    key: "ub-widget-buddycard-ui-version",
    value: "",
};

export const SHOW_HOME_PAGE: BooleanFeatureFlagProps = {
    key: "ub-widget-home-page",
    value: false,
};

export const NUMBER_OF_STUDENTS_TO_FETCH: NumberFeatureFlagProps = {
    key: "ub-widget-number-of-students-to-fetch",
    value: 16,
};

export const NUMBER_OF_STAFF_TO_FETCH: NumberFeatureFlagProps = {
    key: "ub-widget-number-of-staff-to-fetch",
    value: 12,
};

export const SHOW_STAFF_SECTION_IN_STUDENTS_TAB: BooleanFeatureFlagProps = {
    key: "ub-widget-staff-section-in-students-tab",
    value: false,
};

export const SHOW_CONTENT_SECTION_IN_STUDENTS_TAB: BooleanFeatureFlagProps = {
    key: "ub-widget-content-section-in-students-tab",
    value: false,
};

export const QUESTION_PROMPTS_ENABLED: BooleanFeatureFlagProps = {
    key: "ub-widget-question-prompts-enabled",
    value: false,
};

export const START_CHAT_V2_ENABLED: BooleanFeatureFlagProps = {
    key: "ub-widget-start-chat-v2-enabled",
    value: false,
};
