import {TokenScheme} from "../actions/authActions";

type AuthTokenScheme = (typeof TokenScheme)[keyof typeof TokenScheme];

type AuthMe = {
    anyUser: {
        firstName?: string;
        lastName?: string;
        chatToken?: string;
        a?: string[];
        email: string;
    };
};

type AuthState = {
    token: boolean;
    me: boolean | AuthMe;
    userTrackingId: null | string;
    initialMe: boolean | AuthMe;
    initialUniToken: boolean | string;
    tokenScheme: AuthTokenScheme;
};

export class AuthStateBuilder {
    private token = false;
    private me: boolean | AuthMe = false;
    private userTrackingId: null | string = null;
    private initialMe: boolean | AuthMe = false;
    private initialUniToken: boolean | string = false;
    private tokenScheme: AuthTokenScheme = TokenScheme.JWT;

    public withToken(token: boolean) {
        this.token = token;
        return this;
    }

    public withMe(me: AuthMe) {
        this.me = me;
        return this;
    }

    public withUserTrackingId(userTrackingId: string) {
        this.userTrackingId = userTrackingId;
        return this;
    }

    public withInitialMe(me: AuthMe) {
        this.initialMe = me;
        return this;
    }

    public withInitialUniToken(initialUniToken: string) {
        this.initialUniToken = initialUniToken;
        return this;
    }

    public withTokenScheme(tokenScheme: AuthTokenScheme) {
        this.tokenScheme = tokenScheme;
        return this;
    }

    public build(): AuthState {
        return {
            token: this.token,
            me: this.me,
            userTrackingId: this.userTrackingId,
            initialMe: this.initialMe,
            initialUniToken: this.initialUniToken,
            tokenScheme: this.tokenScheme,
        };
    }
}
