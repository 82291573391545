import React from "react";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {IconDocumentDuplicate} from "@unibuddy/icons";
import {Button} from "@unibuddy/patron";
import {useTranslation} from "@unibuddy/intl";

import {IntlNamespaces} from "../../../../translations/types";
import {INTL_COPIED_TO_CLIPBOARD, INTL_SHARE} from "../../../../translations/mentorCard";

type ShareButtonProps = {
    shareUrl: string;
    onCopy: () => void;
};

export const ShareButton = ({shareUrl, onCopy}: ShareButtonProps) => {
    const {t} = useTranslation(IntlNamespaces.MENTOR_CARD);

    const handleCopy = () => {
        // eslint-disable-next-line no-alert
        onCopy();
        alert(t(INTL_COPIED_TO_CLIPBOARD.key, INTL_COPIED_TO_CLIPBOARD.defaultValue));
    };

    return (
        <CopyToClipboard id="copy-to-clipboard" text={shareUrl} onCopy={handleCopy}>
            <Button color="university" clear size="sm">
                <IconDocumentDuplicate color="currentColor" />
                {t(INTL_SHARE.key, INTL_SHARE.defaultValue)}
            </Button>
        </CopyToClipboard>
    );
};
