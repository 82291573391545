import get from "lodash/get";
import {getenv} from "../../../util/getEnv";

export default function getTraits(me) {
    const DEFAULT_PROFILE_PHOTO = getenv("DEFAULT_PROFILE_PHOTO");
    const MEDIA_URL = getenv("MEDIA_URL");

    const accountRole = get(me, "anyUser.accountRole", "");
    const profilePhoto = get(me, "mentor.profilePhoto", null);
    const traits = {
        id: get(me, "anyUser.id", `anon${Date.now()}`),
        $email: get(me, "anyUser.email", "anonymous@unibuddy.co"),
        $first_name: get(me, "anyUser.firstName", "Anonymous"),
        $last_name: get(me, "anyUser.lastName", "Visitor"),
        $created: get(me, "anyUser.created", Date.now()),
        accountRole,
        profilePhoto: profilePhoto ? `${MEDIA_URL}${profilePhoto}` : DEFAULT_PROFILE_PHOTO,
        $phone: get(me, "mentor.phoneNumber", ""),
        active: get(me, "anyUser.active", ""),
        countryId: get(me, "mentor.country.id", ""),
        degreeLevel: get(me, "mentor.degreeLevel", ""),
    };
    if (accountRole === "applicant") {
        const unisInterested = get(me, "applicant.universitiesInterested", [""]);
        unisInterested.forEach((uni, idx) => {
            traits[`uniInterested${idx}`] = uni.$oid;
        });
        const degreesInterested = get(me, "applicant.degreesInterested", [""]);
        degreesInterested.forEach((uni, idx) => {
            traits[`degreeInterested${idx}`] = uni.$oid;
        });
    } else if (accountRole === "mentor") {
        traits.year = get(me, "mentor.year", "");
        traits.degree = get(me, "mentor.degree.id", "");
        traits.university = get(me, "mentor.university.id", "");
    }
    return traits;
}
