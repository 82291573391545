import React from "react";
import {
    Card,
    CardActivityStatus,
    CardAvatar,
    CardBody,
    CardBodySectionWrapper,
    CardButtonLink,
    CardHeader,
    CardHeaderInfo,
    CardHeaderInfoWrapper,
    CardLink,
} from "@unibuddy/buddycard-ui";
import {useTranslation} from "@unibuddy/intl";
import {TFunction} from "i18next";

import {getShortName, getTimeSince, UNITS} from "shared/Utils/DateUtils";
import {isExternalBuddycard} from "shared/Utils/WhereAmI";

import MentorComponent from "../../Mentor/components/MentorPreviewCard/MentorComponent";
import {CardAvatarClickHandler} from "../components/CardAvatarClickHandler";
import {getInfoArray} from "../../Mentor/utils/getInfoArray";
import {useUniversity} from "../../UniversityProvider/UniversityProvider";
import {MentorField, MentorFilterOptionField, UniversityField} from "../../../../types/generated";
import {getenv} from "../../../../util/getEnv";
import {IntlNamespaces} from "../../../../translations/types";

export type StudentBuddycardProps = {
    mentor: MentorField;
    handleAvatarClicked?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    rootUrl: string;
    handleButtonLink: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    chatLink: string;
    buddyPosition: number;
    universityDegreeLevels: MentorFilterOptionField[];
    handleSeeMore?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    orderedMentorField: string[];
    university: UniversityField;
    isInternalBuddycard?: boolean;
};

function isValidDate(dateString: string) {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
}

export function lastSeenDate(date: string, t: TFunction<IntlNamespaces.MENTOR_CARD>) {
    if (!isValidDate(date)) return null;

    const {value, units} = getTimeSince(date, new Date());
    if (units === UNITS.DAYS) {
        return null;
    }
    if (value > 1) {
        const translatedActivity: string = t(
            `${units.toLowerCase()}Active`,
            `Active ${value}${getShortName(units)} ago`,
            {
                time: value,
            },
        );
        return translatedActivity;
    }
    const translatedSingularActivity: string = t(
        `${units.toLowerCase()}ActiveSingular`,
        `Active ${value}${getShortName(units)} ago`,
        {
            time: value,
        },
    );
    return translatedSingularActivity;
}

export function StudentBuddycard({
    mentor,
    handleAvatarClicked,
    rootUrl,
    handleButtonLink,
    chatLink,
    buddyPosition,
    universityDegreeLevels = [],
    handleSeeMore,
    orderedMentorField = [],
    university,
    isInternalBuddycard,
}: StudentBuddycardProps) {
    const {t} = useTranslation(IntlNamespaces.MENTOR_CARD);
    const {features, isEnterprise} = useUniversity();
    const isDegreeTypesEnabled = features.get("enableDegreeTypes");
    const isShowCountryFlagEnabled = features.get("showCountryFlag");
    const ASSETS_URL: string = getenv("ASSETS_URL", "") as string;
    const params = new URLSearchParams(window.location.search);
    const profileLink = `${rootUrl}/buddies/students/${mentor.id}?${params.toString()}`;

    const infoArray = getInfoArray(
        mentor,
        isEnterprise,
        mentor?.university?.name ?? "",
        universityDegreeLevels,
        isDegreeTypesEnabled,
    );

    const recentActivityStatus = lastSeenDate(mentor.lastSeen, t);

    return (
        <Card
            activityStatus={
                recentActivityStatus && <CardActivityStatus activityStatus={recentActivityStatus} />
            }
        >
            <CardHeader>
                <CardHeaderInfoWrapper>
                    <CardAvatarClickHandler
                        link={handleAvatarClicked ? profileLink : undefined}
                        handleClick={handleAvatarClicked}
                        ariaLabel={`Mentor ${mentor.firstName ?? ""}`}
                        openInNewTab={isInternalBuddycard}
                    >
                        <CardAvatar
                            url={`${ASSETS_URL}/${mentor.profilePhoto}`}
                            alt={`Mentor ${mentor.firstName ?? ""}`}
                            primaryFlag={
                                isShowCountryFlagEnabled ? mentor?.mentorFields?.primaryFlag : null
                            }
                            secondaryFlag={
                                isShowCountryFlagEnabled
                                    ? mentor?.mentorFields?.secondaryFlag
                                    : null
                            }
                        />
                    </CardAvatarClickHandler>
                    <CardHeaderInfo buddyName={mentor.firstName} info={infoArray} />
                </CardHeaderInfoWrapper>
                <CardButtonLink to={chatLink} color="university" handleClick={handleButtonLink}>
                    {t("chatWith", `Chat with ${mentor.firstName}`, {name: mentor.firstName})}
                </CardButtonLink>
            </CardHeader>
            <CardBody>
                <CardBodySectionWrapper space={"medium"}>
                    {orderedMentorField.map((field) => {
                        return (
                            <React.Fragment key={field}>
                                {MentorComponent(field, {
                                    university,
                                    mentor,
                                    t,
                                })}
                            </React.Fragment>
                        );
                    })}
                </CardBodySectionWrapper>
                {!isExternalBuddycard(rootUrl) && (
                    <CardLink
                        handleClick={handleSeeMore}
                        to={`${rootUrl}/buddies/students/${mentor.id}?buddyPosition=${buddyPosition}`}
                        external={isInternalBuddycard}
                    >
                        {t("readMore", `Read more about ${mentor.firstName ?? ""}...`, {
                            name: mentor.firstName ?? "",
                        })}
                    </CardLink>
                )}
            </CardBody>
        </Card>
    );
}
