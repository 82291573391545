import get from "lodash/get";
import find from "lodash/find";

function getFeatureConfig(name, university) {
    const features = get(university, "config.features", []);
    return find(features, {feature: {name}});
}

export function isFeatureEnabled(name, university) {
    const featureConfig = getFeatureConfig(name, university);
    return featureConfig ? featureConfig.enabled : false;
}

export function isFeatureReadOnly(name, university) {
    const featureConfig = getFeatureConfig(name, university);
    return featureConfig ? featureConfig.isReadOnly : false;
}

export function isFeatureEnabledByUnibuddyOrByUnibuddyAndUniversity(features, name) {
    const feature = find(features, {feature: {name}});
    const enabledByUnibuddy = get(feature, "enabledByUnibuddy", false);
    const enabledByUniversity = get(feature, "enabledByUniversity", false);
    const universityCanToggleFeature = get(feature, "universityCanToggleFeature", false);

    if (!enabledByUnibuddy) return false;
    if (enabledByUnibuddy && universityCanToggleFeature && enabledByUniversity) return true;
    if (enabledByUnibuddy && !universityCanToggleFeature) return true;
    if (enabledByUnibuddy && universityCanToggleFeature && !enabledByUniversity) return false;
}
