export function isJiggly(path) {
    return path?.includes("/embed/");
}

export function isDiglett(path) {
    return path?.includes("/external/") || path?.includes("/internal/");
}

export function isOnix(path) {
    return !isJiggly(path) && !isDiglett(path);
}

export function isCharmander(path) {
    return path?.includes("/pwa/");
}

export function isInternalBuddycard(path) {
    return path?.includes("/internal/");
}

export function isExternalBuddycard(path) {
    return path?.includes("/external/");
}

/**
 * @param {string} path
 * @return {string}
 */
export function rootURL(path) {
    if (isDiglett(path) || isJiggly(path)) {
        return path?.split("/").slice(0, 5).join("/");
    } else if (isCharmander(path)) {
        return path?.split("/").slice(0, 3).join("/");
    }
    return "";
}

export function getHorseaRootUrl(path) {
    return path?.split("/").slice(0, 3).join("/");
}

export function findProductByPath(path) {
    if (path?.includes("/external/")) {
        return "External Buddy Cards";
    } else if (path?.includes("/pwa/")) {
        return "PWA University Widget";
    }
    return "Embedded University Widget";
}
