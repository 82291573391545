import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router";
import styled from "styled-components";
import {Heading, Text, Box, Stack, ButtonLink} from "@unibuddy/patron";
import {IconArrowRight} from "@unibuddy/icons";
import {ApolloError} from "apollo-client";
import {useTranslation} from "@unibuddy/intl";

import useAnalytics from "shared/AnalyticsNew/hooks/useAnalytics";
import {sharedEvents} from "shared/AnalyticsNew/constants/productSpecification";

import {useUniversity} from "../../../UniversityProvider/UniversityProvider";
import {AllPostsField, BlogPostField} from "../../../../../types/generated";
import {WIDGET_PAGE} from "../../../PageProvider/PageTitles";
import {ContentCard} from "./ContentCard";
import {
    INTL_EXPLORE_STUDENT_CONTENT,
    INTL_VIEW_MORE_CONTENT,
} from "../../../../../translations/common";
import {IntlNamespaces} from "../../../../../translations/types";

const BREAKPOINTS = [420, 632];

const StyleIconArrowRight = styled(IconArrowRight)`
    margin-left: 5px;
    position: relative;
`;

const StyledContentContainer = styled(Box)`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;

    @media (min-width: 420px) and (max-width: 631px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 632px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

export type ContentSectionProps = {
    data?: {allPostsInfo: AllPostsField};
    loading: boolean;
    error?: ApolloError;
};

export function ContentSection({data, loading = false, error}: ContentSectionProps) {
    const {slug} = useUniversity();
    const {trackEvent} = useAnalytics();
    const history = useHistory();
    const location = useLocation();
    const {t} = useTranslation(IntlNamespaces.COMMON);
    const contentUrl = `${location.pathname.split("/buddies/", 1)[0]}/blog`;
    const contentData = data?.allPostsInfo?.blogPosts || [];
    const [numberOfCardsShown, setNumberOfCardsShown] = useState(1);

    const updateItemsToShow = () => {
        const width = window.innerWidth;
        if (width < BREAKPOINTS[0]) {
            setNumberOfCardsShown(1);
        } else if (width >= BREAKPOINTS[0] && width < BREAKPOINTS[1]) {
            setNumberOfCardsShown(2);
        } else {
            setNumberOfCardsShown(3);
        }
    };

    useEffect(() => {
        updateItemsToShow();

        window.addEventListener("resize", updateItemsToShow);

        return () => {
            window.removeEventListener("resize", updateItemsToShow);
        };
        // Empty deps as we only want to initialise the listener once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onViewMoreClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        trackEvent(sharedEvents.viewMoreButtonClicked.name, {
            universitySlug: slug,
            sectionType: "content",
            tab: WIDGET_PAGE.WIDGET_STUDENTS,
        });
        history.push(contentUrl);
    };

    if (loading) return <Text>Loading...</Text>;

    if (error) return null;

    if (!contentData?.length) return null;

    return (
        <Stack space="small-medium">
            <Heading level="2" size="xsmall">
                {t(INTL_EXPLORE_STUDENT_CONTENT.key, INTL_EXPLORE_STUDENT_CONTENT.defaultValue)}
            </Heading>
            <Stack space="xsmall">
                <Box display="flex" flexDirection="row">
                    <StyledContentContainer width="100%" marginBottom="xsmall">
                        {contentData
                            .slice(0, numberOfCardsShown)
                            .map((post: BlogPostField, index: number) => {
                                return (
                                    <ContentCard
                                        key={`content-card-${post.id}`}
                                        post={post}
                                        contentUrl={contentUrl}
                                        position={index}
                                    />
                                );
                            })}
                    </StyledContentContainer>
                </Box>
                <ButtonLink
                    round
                    block
                    clear
                    to={contentUrl}
                    onClick={onViewMoreClick}
                    color="university"
                >
                    {t(INTL_VIEW_MORE_CONTENT.key, INTL_VIEW_MORE_CONTENT.defaultValue)}
                    <StyleIconArrowRight color="university" />
                </ButtonLink>
            </Stack>
        </Stack>
    );
}
