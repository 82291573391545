import * as React from "react";
import {useMutation} from "react-apollo";
import noop from "lodash/noop";
import {ApolloError} from "apollo-client";

import useErrorReporting from "shared/General/components/ErrorReporting/useErrorReporting";
import {useBooleanFeatureFlag} from "ub-feature-flagging-react";
import {UB_EDM_COHORTS_ENABLED} from "shared/constants";

import {useUniversity} from "../../UniversityProvider/UniversityProvider";
import {
    generateRecommendations,
    GenerateRecommendationsMutation,
    GenerateRecommendationsVariables,
} from "../../NextBestActions/NextBestActions";
import {useUser} from "../../User/useUser";
import {useUniversityApplicantLink} from "../../User/useUniversityApplicantLink";

export const RECOMMENDATION_PAGE_BATCH_SIZE = 6;
export const RECOMMENDATION_BANNER_BATCH_SIZE = 3;

type RecommendationContext = {
    getRecommendationsCallback: (batchSize: number) => Promise<void>;
    data?: GenerateRecommendationsMutation;
    loading: boolean;
    error: ApolloError;
    hasRecommendations: boolean;
};

export const RecommendationsContext = React.createContext<RecommendationContext>({
    getRecommendationsCallback: noop as (batchSize: number) => Promise<void>,
    data: null,
    loading: false,
    error: null,
    hasRecommendations: false,
});

export const RecommendationsProvider: React.FC = ({children}) => {
    const {id} = useUniversity();
    const user = useUser();
    const applicantUniversityLink = useUniversityApplicantLink(id);
    const isCohortEnabled = useBooleanFeatureFlag(
        UB_EDM_COHORTS_ENABLED.key,
        UB_EDM_COHORTS_ENABLED.value,
    );
    const {reportError} = useErrorReporting();
    const [hasRecommendations, setHasRecommendations] = React.useState<boolean>(false);
    const [getGeneratedRecommendations, {data, loading, error}] = useMutation<
        GenerateRecommendationsMutation,
        GenerateRecommendationsVariables
    >(generateRecommendations);

    async function GenerateRecommendations(batchSize: number) {
        const variables: GenerateRecommendationsVariables = {
            universityId: id,
            batchSize,
        };
        if (isCohortEnabled) {
            const cohortFields: [string, string | string[] | undefined][] = [
                ["countryId", user?.country?.id],
                ["domesticRegionId", user?.domesticRegion?.id],
                ["degreeLevelId", applicantUniversityLink?.degreeLevel?.id],
                [
                    "areaOfStudyIds",
                    applicantUniversityLink?.degreesInterested.map((degree) => degree?.id),
                ],
                [
                    "expectedEntry",
                    applicantUniversityLink?.dateOfEntry
                        ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                          new Date(applicantUniversityLink?.dateOfEntry).toISOString()
                        : undefined,
                ],
            ];
            cohortFields
                .filter(
                    ([, value]) =>
                        (!Array.isArray(value) && value !== undefined) ||
                        (Array.isArray(value) && value.length > 0),
                )
                .forEach(([key, value]) => {
                    variables[key] = value;
                });
        }

        try {
            const recommendations = await getGeneratedRecommendations({
                variables,
            });
            if (!hasRecommendations && recommendations.data?.generateRecommendations?.length > 0) {
                setHasRecommendations(true);
            }
        } catch (e) {
            reportError(e);
        }
    }

    const getRecommendationsCallback = React.useCallback(GenerateRecommendations, [
        id,
        user,
        applicantUniversityLink,
        isCohortEnabled,
    ]);

    return (
        <RecommendationsContext.Provider
            value={{getRecommendationsCallback, data, loading, error, hasRecommendations}}
        >
            {children}
        </RecommendationsContext.Provider>
    );
};

export const useRecommendations = () =>
    React.useContext<RecommendationContext>(RecommendationsContext);
