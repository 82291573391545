import React from "react";
import {Button} from "@unibuddy/patron";
import {ApolloError} from "apollo-client";

import EmptyState from "../EmptyState/EmptyState";
import forbiddenImage from "./403.png";
import notfoundImage from "./404.png";

type ErrorDisplayProps = {
    error?: ApolloError;
    actionButton?: {onClick: () => void; text: string};
    children: React.ReactElement;
};

export const ErrorDisplay = ({error, actionButton, children}: ErrorDisplayProps) => {
    if (error) {
        let image = null;
        if (error?.message.includes("403 Forbidden")) {
            image = <img src={forbiddenImage} alt="403 Forbidden" />;
        } else if (error?.message.includes("404 Not Found")) {
            image = <img src={notfoundImage} alt="404 Not Found" />;
        }
        return (
            <EmptyState
                image={image}
                actionButton={
                    actionButton && (
                        <Button onClick={actionButton.onClick}>{actionButton.text}</Button>
                    )
                }
            />
        );
    }
    return children;
};
