import React from "react";
import get from "lodash/get";
import {compose} from "redux";
import {Redirect, Route, Switch} from "react-router-dom";
import {useBooleanFeatureFlag} from "ub-feature-flagging-react";
import {useLocalStorage} from "@unibuddy/patron";

import {isDiglett, isJiggly} from "shared/Utils/WhereAmI";
import {isFeatureEnabled} from "shared/Utils/FeatureUtils";
import asyncComponent from "shared/General/components/AsyncComponent/AsyncComponent";
import requireWidgetAuth from "../../../shared/hoc/requireWidgetAuth";
import {WidgetPageMaintainer} from "../../../modules/PageProvider/WidgetPageContainer";
import {WIDGET_PAGE} from "../../../modules/PageProvider/PageTitles";
import BuddiesTab from "../../../modules/HeaderBar/tabs/BuddiesTab/BuddiesTab";
import withSocket from "./SocketData/withSocket";
import Blog from "../../../modules/HeaderBar/tabs/BlogTab/Blog";
import {RecommendationPage} from "../../../modules/Recommendations/containers/RecommendationPage";
import {NotInterestedPage} from "../../../modules/NotInterested/containers/NotInterestedPage";
import {SHOW_INTRO_PAGE, SHOW_HOME_PAGE} from "../../../../shared/constants";
import {useUniversity} from "../../../../widgets/modules/UniversityProvider/UniversityProvider";
import {IntroPage} from "../../../modules/Intro/containers/IntroPage";
import {RegisterPage2Wrapper} from "../../../modules/RegisterNew/components/RegisterPage2Wrapper";
import {Home} from "../../../../views/Home/containers/Home";

// Dynamically import components for code splitting
const AsyncInboxTab = asyncComponent(() =>
    import("../../../modules/HeaderBar/tabs/InboxTab/InboxTabRoutes"),
);
const AsyncAuthTab = asyncComponent(() =>
    import("../../../modules/HeaderBar/tabs/AuthTab/AuthTab"),
);
const AsyncSettingsTab = asyncComponent(() =>
    import("../../../modules/HeaderBar/tabs/SettingsTab/SettingsTab"),
);

const AsyncSettingsTabWithAuth = requireWidgetAuth(AsyncSettingsTab);

const BuddiesRoutesWrapper = (parentProps) => {
    const {slug} = useUniversity();
    const urlParams = new URLSearchParams(window.location.search);
    const ubSource = urlParams.get("ub_source");
    const [userHasSeenIntroPage, setUserHasSeenIntroPage] = useLocalStorage(
        `UB_INTRO_PAGE_SEEN_${slug}`,
        false,
    );
    const isEmbeddedWidget = parentProps.primaryApp === "university-jigglypuff";
    const isMatchingUbSource = ubSource === "Embedded University Widget" || !ubSource;
    const isSignedOut = !parentProps.authState.token;
    const isFirstVisit = !userHasSeenIntroPage;
    const isIntroPageEnabled = useBooleanFeatureFlag(SHOW_INTRO_PAGE.key, SHOW_INTRO_PAGE.value);

    const shouldShowIntroPage =
        isEmbeddedWidget &&
        isMatchingUbSource &&
        isSignedOut &&
        isFirstVisit &&
        isIntroPageEnabled &&
        !isDiglett(parentProps.history.location.pathname) &&
        isJiggly(parentProps.history.location.pathname);

    if (shouldShowIntroPage) {
        return (
            <Switch>
                <Route
                    path={`${parentProps.match.path}/intro`}
                    render={() => (
                        <WidgetPageMaintainer widgetPage={WIDGET_PAGE.WIDGET_INTRO}>
                            <IntroPage setUserHasSeenIntroPage={setUserHasSeenIntroPage} />
                        </WidgetPageMaintainer>
                    )}
                />
                <Route
                    exact
                    path={`${parentProps.match.path}/students/intro`}
                    render={({location}) => (
                        <Redirect
                            to={{
                                ...location,
                                pathname: `${parentProps.match.url.replace(/\/$/, "")}/intro`,
                            }}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${parentProps.match.path}/students`}
                    render={({location}) => (
                        <Redirect
                            to={{
                                ...location,
                                pathname: `${parentProps.match.url.replace(/\/$/, "")}/intro`,
                            }}
                        />
                    )}
                />
                <Route
                    path={`${parentProps.match.path}/:buddyGroupType`}
                    render={(props) => (
                        <WidgetPageMaintainer
                            widgetPage={
                                get(props, "match.params.buddyGroupType") === "staff"
                                    ? WIDGET_PAGE.WIDGET_STAFF
                                    : WIDGET_PAGE.WIDGET_STUDENTS
                            }
                        >
                            <BuddiesTab {...parentProps} {...props} />
                        </WidgetPageMaintainer>
                    )}
                />
                <Route
                    exact
                    path={parentProps.match.path}
                    render={({location}) => (
                        <Redirect
                            to={{
                                ...location,
                                pathname: `${parentProps.match.url.replace(/\/$/, "")}/intro`,
                            }}
                        />
                    )}
                />
            </Switch>
        );
    }

    return (
        <Switch>
            <Route
                path={`${parentProps.match.path}/:buddyGroupType`}
                render={(props) => (
                    <WidgetPageMaintainer
                        widgetPage={
                            get(props, "match.params.buddyGroupType") === "staff"
                                ? WIDGET_PAGE.WIDGET_STAFF
                                : WIDGET_PAGE.WIDGET_STUDENTS
                        }
                    >
                        <BuddiesTab {...parentProps} {...props} />
                    </WidgetPageMaintainer>
                )}
            />
            <Route
                exact
                path={parentProps.match.path}
                render={({location}) => (
                    <Redirect
                        to={{
                            ...location,
                            pathname: `${parentProps.match.url.replace(/\/$/, "")}/students`,
                        }}
                    />
                )}
            />
        </Switch>
    );
};

const NotInterestedWithAuth = requireWidgetAuth((props) => (
    <RegisterPage2Wrapper>
        <NotInterestedPage {...props} />
    </RegisterPage2Wrapper>
));

const RecommendationPageWithAuth = requireWidgetAuth((props) => (
    <RegisterPage2Wrapper>
        <RecommendationPage {...props} />
    </RegisterPage2Wrapper>
));

const ProspectWidgetRoutes = (parentProps) => {
    const isBlogsEnabled = isFeatureEnabled("userGeneratedContent", parentProps.university);
    const isHomePageEnabled = useBooleanFeatureFlag(SHOW_HOME_PAGE.key, SHOW_HOME_PAGE.value);

    return (
        <main>
            <Switch>
                <Route
                    path={`${parentProps.match.path}/buddies`}
                    render={(props) => <BuddiesRoutesWrapper {...parentProps} {...props} />}
                />
                {isBlogsEnabled ? (
                    <Route
                        path={`${parentProps.match.path}/blog`}
                        render={(props) => (
                            <WidgetPageMaintainer widgetPage={WIDGET_PAGE.WIDGET_CONTENT}>
                                <Blog {...parentProps} {...props} />
                            </WidgetPageMaintainer>
                        )}
                    />
                ) : (
                    <Redirect
                        path={`${parentProps.match.path}/blog`}
                        to={`${parentProps.match.path}/buddies`}
                    />
                )}
                {isHomePageEnabled ? (
                    <Route
                        path={`${parentProps.match.path}/home`}
                        render={() => (
                            <WidgetPageMaintainer widgetPage={WIDGET_PAGE.WIDGET_HOME}>
                                <Home />
                            </WidgetPageMaintainer>
                        )}
                    />
                ) : (
                    <Redirect
                        path={`${parentProps.match.path}/home`}
                        to={`${parentProps.match.path}/buddies`}
                    />
                )}

                <Route
                    path={`${parentProps.match.path}/inbox`}
                    render={(props) => (
                        <WidgetPageMaintainer widgetPage={WIDGET_PAGE.WIDGET_INBOX}>
                            <AsyncInboxTab {...parentProps} {...props} />
                        </WidgetPageMaintainer>
                    )}
                />
                <Route
                    path={`${parentProps.match.path}/auth`}
                    render={(props) => <AsyncAuthTab {...parentProps} {...props} />}
                />
                <Route
                    exact
                    path={`${parentProps.match.path}/settings`}
                    render={(props) => (
                        <WidgetPageMaintainer widgetPage={WIDGET_PAGE.WIDGET_SETTINGS}>
                            <AsyncSettingsTabWithAuth {...parentProps} {...props} />
                        </WidgetPageMaintainer>
                    )}
                />
                <Redirect
                    path={`${parentProps.match.path}/decision-stage`}
                    to={`${parentProps.match.path}/recommendations`}
                />
                <Route
                    exact
                    path={`${parentProps.match.path}/not-interested`}
                    render={() => (
                        <WidgetPageMaintainer widgetPage={WIDGET_PAGE.WIDGET_NOT_INTERESTED}>
                            <NotInterestedWithAuth />
                        </WidgetPageMaintainer>
                    )}
                />
                <Route
                    exact
                    path={`${parentProps.match.path}/recommendations`}
                    render={() => (
                        <WidgetPageMaintainer widgetPage={WIDGET_PAGE.WIDGET_RECOMMENDATIONS}>
                            <RecommendationPageWithAuth />
                        </WidgetPageMaintainer>
                    )}
                />
                <Route
                    exact
                    path={parentProps.match.path}
                    render={({location}) => (
                        <Redirect
                            to={{
                                ...location,
                                pathname: `${parentProps.match.url.replace(/\/$/, "")}/buddies`,
                            }}
                        />
                    )}
                />
            </Switch>
        </main>
    );
};

export default compose(withSocket)(ProspectWidgetRoutes);
