import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "@unibuddy/intl";
import {Button} from "@unibuddy/patron";

const ChatNowButton = ({id, className, large, onChatNow, name, ...props}) => {
    const {t} = useTranslation("mentorCard");

    return (
        <Button
            id={id}
            className={className}
            onClick={onChatNow}
            size={large ? "lg" : "md"}
            round
            ghost
            color="university"
            {...props}
        >
            {
                /* we render this so react text comment nodes don't mess up
               text assertions in bdd tests */
                t("chatWith", `Chat with ${name}`, {name})
            }
        </Button>
    );
};

ChatNowButton.defaultProps = {
    large: false,
    invert: false,
    id: "",
};

ChatNowButton.propTypes = {
    name: PropTypes.string.isRequired,
    onChatNow: PropTypes.func.isRequired,
    large: PropTypes.bool,
    id: PropTypes.string,
};

export default ChatNowButton;
