import React from "react";
import {useLocation, useHistory} from "react-router";

const FiltersContext = React.createContext();

export const useBuddiesFilters = () => {
    return React.useContext(FiltersContext);
};
// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const location = useLocation();
    return new URLSearchParams(location.search);
}

export function objectToParams(obj) {
    const params = new URLSearchParams();
    Object.keys(obj).forEach((key) => {
        if (obj[key]) {
            params.set(key, obj[key]);
        }
    });
    return params;
}
export function paramsToObject(entries) {
    const result = {};

    if (!entries || !entries[Symbol.iterator]) {
        return {};
    }
    for (const entry of entries) {
        // each 'entry' is a [key, value] tupple
        const [key, value] = entry;
        result[key] = value;
    }
    return result;
}

/**\
 * Function to init the filter from the url
 * used only once in the app
 *  (that's why the use effect has not deps)
 */
function useInitFilters(setFilters) {
    const query = useQuery();
    React.useEffect(() => {
        const entries = query.entries();
        setFilters(paramsToObject(entries));
    }, []); //eslint-disable-line
}

export function syncUrl(filters, history) {
    const castedFilters = objectToParams(filters).toString();
    history.replace({
        search: castedFilters,
    });
}

/**\
 * Function to sync the url with the filters
 * onMount (that's why the use effect has not deps)
 */
export function useSyncUrlOnce(filters) {
    const history = useHistory();
    React.useEffect(() => {
        syncUrl(filters, history);
    }, []); //eslint-disable-line
}

function useSyncUrl(filters) {
    const history = useHistory();
    React.useEffect(() => {
        syncUrl(filters, history);
    }, [filters, history]);
}

export default function BuddiesFiltersProvider({children}) {
    const [filters, setFilters] = React.useState({});
    useInitFilters(setFilters);
    useSyncUrl(filters);
    function clearFilters() {
        setFilters({});
    }

    return (
        <FiltersContext.Provider
            value={{
                filters,
                setFilters,
                clearFilters,
            }}
        >
            {children}
        </FiltersContext.Provider>
    );
}
