import React from "react";
import {useTranslation} from "@unibuddy/intl";
import {
    Card,
    CardHeader,
    CardHeaderInfoWrapper,
    CardAvatar,
    CardHeaderInfo,
    CardButtonLink,
    CardBody,
    CardBodySection,
    CardBodySectionWrapper,
    CardLink,
} from "@unibuddy/buddycard-ui";

import {isExternalBuddycard} from "shared/Utils/WhereAmI";

import {getenv} from "../../../../util/getEnv";
import {CardAvatarClickHandler} from "../components/CardAvatarClickHandler";
import {BuddyField} from "../../../../types/generated";
import {IntlNamespaces} from "../../../../translations/types";
import {
    INTL_CHAT_WITH,
    INTL_ROLE_DESCRIPTION,
    INTL_READ_MORE,
} from "../../../../translations/mentorCard";
import {isFunction} from "../../../../util/isFunction";

type ExtendedBuddyField = BuddyField & {
    staffBuddyName?: string;
};

export type StaffBuddycardProps = {
    buddy: ExtendedBuddyField;
    onChatNow: (buddyId: string) => void;
    onSeeMore: (buddyId: string) => void;
    isInternalBuddycard?: boolean;
    rootUrl?: string;
    onAvatarClicked?: () => void;
};

export const StaffBuddycard = ({
    buddy,
    onChatNow,
    onSeeMore,
    isInternalBuddycard,
    rootUrl,
    onAvatarClicked,
}: StaffBuddycardProps) => {
    const {t} = useTranslation("mentorCard");
    const params = new URLSearchParams(window.location.search);
    const chatLink = `${rootUrl}/inbox/chatwith/${buddy.id}${
        params.toString() ? `?${params.toString()}` : ""
    }`;
    const profileLink = `${rootUrl}/buddies/staff/${buddy.id}${
        params.toString() ? `?${params.toString()}` : ""
    }`;

    const ASSETS_URL: string = getenv("ASSETS_URL", "") as string;

    const buddyName: string = buddy?.staffBuddyName ?? buddy?.firstName ?? "";

    function handleOnAvatarClicked(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        e.preventDefault();
        onAvatarClicked();
    }

    function handleChatWith(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        e.preventDefault();
        onChatNow(buddy.id);
    }

    function handleSeeMore(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        e.preventDefault();
        onSeeMore(buddy.id);
    }

    const roleInfo = buddy?.staffFields?.role
        ? {
              text: buddy.staffFields.role,
          }
        : undefined;

    const departmentInfo = buddy?.staffFields?.department
        ? {
              text: buddy.staffFields.department,
              color: buddy?.staffFields?.role ? "grey600" : undefined,
          }
        : undefined;

    const infoArray = [];

    if (roleInfo) {
        infoArray.push(roleInfo);
    }
    if (departmentInfo) {
        infoArray.push(departmentInfo);
    }

    return (
        <Card>
            <CardHeader>
                <CardHeaderInfoWrapper>
                    <CardAvatarClickHandler
                        link={onAvatarClicked ? profileLink : undefined}
                        handleClick={handleOnAvatarClicked}
                        ariaLabel={`Mentor ${buddyName}`}
                        openInNewTab={isInternalBuddycard}
                    >
                        <CardAvatar
                            url={`${ASSETS_URL}/${buddy.profilePhoto}`}
                            alt={`Mentor ${buddyName}`}
                        />
                    </CardAvatarClickHandler>
                    <CardHeaderInfo buddyName={buddyName} info={infoArray} />
                </CardHeaderInfoWrapper>
                <CardButtonLink to={chatLink} color="university" handleClick={handleChatWith}>
                    {t(
                        `${IntlNamespaces.MENTOR_CARD}:${INTL_CHAT_WITH.key}`,
                        isFunction(INTL_CHAT_WITH.defaultValue)
                            ? INTL_CHAT_WITH.defaultValue(buddyName)
                            : INTL_CHAT_WITH.defaultValue,
                        {name: buddyName},
                    )}
                </CardButtonLink>
            </CardHeader>
            <CardBody>
                <CardBodySectionWrapper space={"medium"}>
                    <CardBodySection
                        heading={t(
                            `${IntlNamespaces.MENTOR_CARD}:${INTL_ROLE_DESCRIPTION.key}`,
                            `${INTL_ROLE_DESCRIPTION.defaultValue as string}`,
                        )}
                        content={`${buddy?.staffFields?.bio.slice(0, 70) ?? ""}...`}
                    />
                </CardBodySectionWrapper>
                {!isExternalBuddycard(rootUrl) && (
                    <CardLink
                        handleClick={handleSeeMore}
                        to={profileLink}
                        external={isInternalBuddycard}
                    >
                        {t(
                            `${IntlNamespaces.MENTOR_CARD}:${INTL_READ_MORE.key}`,
                            isFunction(INTL_READ_MORE.defaultValue)
                                ? INTL_READ_MORE.defaultValue(buddyName)
                                : INTL_READ_MORE.defaultValue,
                            {name: buddyName},
                        )}
                    </CardLink>
                )}
            </CardBody>
        </Card>
    );
};
