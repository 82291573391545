import React from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import {useTranslation} from "@unibuddy/intl";
import {Shimmer, Tiles} from "@unibuddy/patron";
import concat from "lodash/concat";
import get from "lodash/get";
import {IntlNamespaces} from "../../../../../translations/types";
import {FilterDropdown} from "../../../../../components/FilterDropdown/FilterDropdown";

const MAX_FILTER_COUNT_FOR_ONE_LINE_RENDER = 4;
const presetFilterLabelMap = {
    country: "countries",
    degree_type: "degreeType",
    degree: "areaOfStudy",
    degree_level: "degreeLevel",
    institution: "institution",
};

const FilterLoading = () => (
    <Shimmer style={{width: "100%", height: 42}} height={42} width="100%">
        <rect x="0" y="0" rx="4" ry="4" width="100%" height="42" />
    </Shimmer>
);

export const MentorFiltersLoading = () => (
    <MediaQuery minWidth="768px">
        {(isLargeScreen) => (
            <Tiles columns={[1, 2, isLargeScreen ? 4 : 1]} space="small">
                <FilterLoading />
                <FilterLoading />
                <FilterLoading />
                <FilterLoading />
            </Tiles>
        )}
    </MediaQuery>
);

const MentorFilters = (props) => {
    const {t} = useTranslation(["mentorCard", IntlNamespaces.LEVELS, "countries"]);
    const degreeTypeOrder = get(props.university, "degreeTypeOrder", []);
    const noOfFilters = get(props.filters, "length", 0);

    const calculateNoOfColumns = (isLargeScreen) => {
        let col = 1;
        if (isLargeScreen) {
            col = noOfFilters <= MAX_FILTER_COUNT_FOR_ONE_LINE_RENDER ? noOfFilters : 3;
        }
        return col;
    };

    const orderDegreeTypesAsPerUniPreference = (options) => {
        // Create a temporary map so that we can easily access
        // degree type while ordering in the next step
        const degreeTypeValueToOptionMap = {};
        options.forEach(({value, label}) => {
            degreeTypeValueToOptionMap[value] = {value, label};
        });
        const orderedOptions = [];
        degreeTypeOrder.forEach((degreeType) => {
            const degreeTypeKey = degreeType.type;
            if (degreeTypeKey in degreeTypeValueToOptionMap) {
                orderedOptions.push(degreeTypeValueToOptionMap[degreeTypeKey]);
            }
        });
        return orderedOptions;
    };

    function getOptions(f) {
        let options;
        if (f.id === "degree_level") {
            options = f.options.map(({id, label}) => {
                return {
                    value: id,
                    label: t(`levels:${label.toLowerCase()}`, label),
                };
            });
        } else if (f.id === "degree_type") {
            options = f.options.map(({id, label}) => {
                return {
                    value: id,
                    label: t(`${id.toLowerCase()}`, label),
                };
            });
        } else if (f.id === "country") {
            options = f.options.map(({id, label}) => ({
                value: id,
                label: t(`${label}`, label, {ns: "countries"}),
            }));
        } else {
            options = f.options.map(({id, label}) => ({value: id, label}));
        }
        return options;
    }

    return (
        <MediaQuery minWidth="768px">
            {(isLargeScreen) => (
                <Tiles columns={[1, 2, calculateNoOfColumns(isLargeScreen)]} space="small">
                    {props.filters.map((f) => {
                        const filterLabel = presetFilterLabelMap[f.id]
                            ? t(presetFilterLabelMap[f.id], f.label)
                            : f.label;
                        // translating options for degree level & degree type
                        let options = getOptions(f);

                        /* Hack for handling multiple degrees having same name - a typical enterprise use case.
                         * We want a single option in the dropdown which has multiple degrees attached to it.
                         * For example: [
                         *   {"label": "Comp Sci", "value": "5f4e2f936ee5c5576e6b0539"},
                         *   {"label": "Full-Time MBA", "value": ["606cc93a6ee5c5c6ee3ca6de","612667376ee5c570afa36d2b"]},
                         *   {"label": "Maths","value": "6039046c6ee5c58e81a7659b"},
                         * ]
                         */
                        if (f.id === "degree") {
                            const labelToValueMap = {};
                            options.forEach(({value, label}) => {
                                labelToValueMap[label] =
                                    label in labelToValueMap
                                        ? concat(labelToValueMap[label], value)
                                        : value;
                            });
                            options = [];
                            for (const [k, v] of Object.entries(labelToValueMap)) {
                                options.push({label: k, value: v});
                            }
                        }

                        // Arrange degree_types in the order preferred by university
                        if (f.id === "degree_type" && degreeTypeOrder.length > 0) {
                            options = orderDegreeTypesAsPerUniPreference(options);
                        }

                        return (
                            <FilterDropdown
                                key={f.id}
                                noOptionsMessage={t("noOptionsMessage", "No options")}
                                id={`mentor-filter-${f.id}`}
                                name={f.id}
                                placeholder={filterLabel}
                                options={options}
                                onUpdateFilter={(label, value) => {
                                    props.updateFilter(f, {
                                        label,
                                        value,
                                    });
                                }}
                                aria-label={filterLabel}
                                // Don't want the filter value to change, since we are actually managing filtering through
                                // the addFilter callback (adding filters) and filter pills (removing filters)
                                value={null}
                            />
                        );
                    })}
                </Tiles>
            )}
        </MediaQuery>
    );
};

MentorFilters.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    updateFilter: PropTypes.func.isRequired,
    filters: PropTypes.array,
    university: PropTypes.object.isRequired,
};

MentorFilters.defaultProps = {
    filters: [],
};

export default MentorFilters;
