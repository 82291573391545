import React, {useEffect} from "react";
import {Query, useQuery} from "react-apollo";
import get from "lodash/get";
import {Box, Heading, SkipNavContent} from "@unibuddy/patron";
import pickBy from "lodash/pickBy";
import {useTranslation} from "@unibuddy/intl";
import {useLocation} from "react-router-dom";
import {useStringFeatureFlag, useNumberFeatureFlag} from "ub-feature-flagging-react";
import styled from "styled-components";

import {PoweredByUnibuddy} from "shared/General/components/PoweredByUnibuddy/PoweredByUnibuddy";
import {BUDDYCARD_UI_VERSION, NUMBER_OF_STAFF_TO_FETCH} from "shared/constants";
import {rootURL} from "shared/Utils/WhereAmI";
import {useBuddiesFilters} from "shared/Buddies/components/BuddiesFiltersProvider/BuddiesFiltersProvider";
import {ViewMoreLoaderButton} from "shared/General/components/ViewMoreLoaderButton/ViewMoreLoaderButton";
import useAnalytics from "shared/AnalyticsNew/hooks/useAnalytics";
import buddiesFilterOptionsQuery from "shared/Buddies/queries/buddiesFilterOptionsQuery";
import {ErrorDisplay} from "shared/General/components/ErrorDisplay/ErrorDisplay";

import {useUniversity} from "../../../UniversityProvider/UniversityProvider";
import {PageTitle} from "../../../../../components/PageTitle/PageTitle";
import {StaffBuddycard} from "../../../BuddiesNew/containers/StaffBuddycard";
import buddiesQuery from "../../queries/buddiesQuery";
import s from "./BuddiesList.pcss";
import {PreviewCardsV2Grid} from "../../../Mentor/containers/StudentList/StudentList";
import BuddiesFilterSection from "../BuddiesFilterSection/BuddiesFilterSection";
import {IntlNamespaces} from "../../../../../translations/types";
import {
    INTL_CHOOSE_STAFF,
    INTL_NO_AMBASSADOR_MSG,
    INTL_STAFF,
} from "../../../../../translations/common";

const staffFilterFields = ["role", "department"];

const updateQuery = (prev, {fetchMoreResult}) => {
    if (!fetchMoreResult) return prev;

    return {
        ...prev,
        buddies: {
            ...prev.buddies,
            users: [...prev.buddies.users, ...fetchMoreResult.buddies.users],
        },
    };
};

const processFilters = (filters, buddyGroupType) => {
    if (buddyGroupType === "staff") {
        return {staffFields: JSON.stringify(pickBy(filters))};
    }
    return filters;
};

const getSearchParamsWithSourceTracking = (buddyPosition, buddyGroupType) => {
    const params = new URLSearchParams(window.location.search);
    params.set("buddyPosition", buddyPosition);
    params.set("buddyGroupType", buddyGroupType);
    return params.toString();
};

const onChatNow = ({compProps, buddyId, rootUrl, buddyPosition, buddyGroupType}) => {
    const params = getSearchParamsWithSourceTracking(buddyPosition, buddyGroupType);

    compProps.history.push(`${rootUrl}/inbox/chatwith/${buddyId}?${params}`);
};

const onSeeMore = ({compProps, buddyId, rootUrl, buddyPosition, buddyGroupType}) => {
    const params = getSearchParamsWithSourceTracking(buddyPosition, buddyGroupType);

    compProps.history.push(`${rootUrl}/buddies/${buddyGroupType}/${buddyId}?${params}`);
};

const onAvatarClicked = ({compProps, buddyId, rootUrl, buddyPosition, buddyGroupType}) => {
    const params = getSearchParamsWithSourceTracking(buddyPosition, buddyGroupType);

    compProps.history.push(`${rootUrl}/buddies/${buddyGroupType}/${buddyId}?${params}`);
};

const LogoWrapper = styled(Box)`
    display: none;

    @media (min-width: 632px) {
        display: block;
    }
`;

/* FYI: This Component is only used by the staff page */
export default function BuddiesList({match, history}) {
    const {t} = useTranslation(IntlNamespaces.COMMON);
    const buddyGroupType = get(match, "params.buddyGroupType", "staff");
    const universitySlug = get(match, "params.universitySlug");
    const university = useUniversity();
    const location = useLocation();
    const baseUrl = rootURL(location.pathname);
    const isPoweredByUnibuddyClickDisabled = university?.features?.get(
        "widgetPoweredByUnibuddyNotClickable",
    );

    const {data: buddiesFilterOptionsData} = useQuery(buddiesFilterOptionsQuery, {
        variables: {
            universitySlug,
            groupType: buddyGroupType,
            active: true,
        },
    });
    const {filters, setFilters, clearFilters} = useBuddiesFilters();
    const staffFiltersObject = staffFilterFields.reduce(
        (prev, curr) => (filters[curr] ? {...prev, [curr]: filters[curr]} : prev),
        {},
    );
    const customAttributesFilterOptions =
        get(buddiesFilterOptionsData, "buddiesFilterOptions.customAttributesFilters", []) || [];
    const customAttributesFiltersObject = customAttributesFilterOptions.reduce(
        (prev, curr) => (filters[curr.id] ? {...prev, [curr.id]: filters[curr.id]} : prev),
        {},
    );
    const customAttributesFilters = Object.keys(customAttributesFiltersObject).map((key) => ({
        field: key,
        value: filters[key],
    }));

    const STAFF_TO_FETCH_LIMIT = useNumberFeatureFlag(
        NUMBER_OF_STAFF_TO_FETCH.key,
        NUMBER_OF_STAFF_TO_FETCH.value,
    );

    const variables = {
        universitySlug,
        role: "staff",
        offset: 0,
        limit: STAFF_TO_FETCH_LIMIT,
        customAttributesFilters: customAttributesFilters.length
            ? customAttributesFilters
            : undefined,
        ...processFilters(staffFiltersObject, buddyGroupType),
    };

    const {
        events: {buddiesListViewed, buddiesListViewedMore},
    } = useAnalytics();

    const BUDDY_CARD_VERSION = useStringFeatureFlag(
        BUDDYCARD_UI_VERSION.key,
        BUDDYCARD_UI_VERSION.value,
    );

    /* Disabling eslint as the function reference changes and will cause an
     * infinite loop if it's added to the dependency array */
    /* eslint-disable */
    useEffect(() => () => clearFilters(), []);
    /* eslint-enable */

    useEffect(() => {
        if (BUDDY_CARD_VERSION) {
            buddiesListViewed({
                buddyType: buddyGroupType,
                buddyDesign: BUDDY_CARD_VERSION,
            });
        }
    }, [buddiesListViewed, buddyGroupType, BUDDY_CARD_VERSION]);

    return (
        <Query
            fetchPolicy="cache-and-network"
            query={buddiesQuery}
            notifyOnNetworkStatusChange
            variables={variables}
        >
            {({data, error, fetchMore, networkStatus}) => {
                let errorJsx = null;
                const buddyCount = get(data, "buddies.count", 0);
                const buddies = get(data, "buddies.users", []);
                const randomizeListSeed = get(data, "buddies.randomizeListSeed", null);
                if (networkStatus === 1) {
                    errorJsx = (
                        <div className={s.informationText} data-test-id="buddies-list-loading">
                            Loading...
                        </div>
                    );
                } else if (!buddyCount) {
                    errorJsx = (
                        <div>
                            <div className={s.informationText}>
                                {t(INTL_NO_AMBASSADOR_MSG.key, INTL_NO_AMBASSADOR_MSG.defaultValue)}
                            </div>
                        </div>
                    );
                }
                const hasMore = buddies.length < buddyCount;
                const compProps = {limit: STAFF_TO_FETCH_LIMIT, match, history};
                return (
                    <ErrorDisplay error={networkStatus !== 1 && error ? error : null}>
                        <div id="buddies-page">
                            <PageTitle title={t(buddyGroupType, INTL_STAFF.defaultValue)} />
                            <SkipNavContent id="content">
                                <Box
                                    display="flex"
                                    flexDirection={["row"]}
                                    justifyContent={["space-between"]}
                                    alignItems={["flex-start"]}
                                    marginBottom="medium-large"
                                >
                                    <Heading level={2} size="small">
                                        {t(INTL_CHOOSE_STAFF.key, INTL_CHOOSE_STAFF.defaultValue)}
                                    </Heading>

                                    <LogoWrapper
                                        display={["none", "block"]}
                                        justifyContent="center"
                                        flexShrink={1}
                                        minW="166px"
                                        height="30px"
                                    >
                                        <PoweredByUnibuddy
                                            redirectOnClick={!isPoweredByUnibuddyClickDisabled}
                                        />
                                    </LogoWrapper>
                                </Box>
                            </SkipNavContent>
                            <React.Suspense fallback={"loading..."}>
                                <BuddiesFilterSection
                                    buddyGroupType={buddyGroupType}
                                    filters={{
                                        ...staffFiltersObject,
                                        ...customAttributesFiltersObject,
                                    }}
                                    setFilters={(updatedfilters) => {
                                        return setFilters({...filters, ...updatedfilters});
                                    }}
                                    buddiesFilterOptionsData={buddiesFilterOptionsData}
                                />
                            </React.Suspense>
                            {!errorJsx ? (
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    flexDirection="column"
                                    alignItems="center"
                                >
                                    <PreviewCardsV2Grid>
                                        {buddies.map((buddy, buddyPosition) => {
                                            return (
                                                <StaffBuddycard
                                                    data-test-id="buddy-card-container"
                                                    key={buddy.id}
                                                    buddy={buddy}
                                                    rootUrl={baseUrl}
                                                    onChatNow={() =>
                                                        onChatNow({
                                                            compProps,
                                                            buddyGroupType,
                                                            buddyPosition,
                                                            buddyId: buddy.id,
                                                            rootUrl: rootURL(match.url),
                                                        })
                                                    }
                                                    onSeeMore={() =>
                                                        onSeeMore({
                                                            compProps,
                                                            buddyGroupType,
                                                            buddyPosition,
                                                            buddyId: buddy.id,
                                                            rootUrl: rootURL(match.url),
                                                        })
                                                    }
                                                    onAvatarClicked={() =>
                                                        onAvatarClicked({
                                                            compProps,
                                                            buddyGroupType,
                                                            buddyPosition,
                                                            buddyId: buddy.id,
                                                            rootUrl: rootURL(match.url),
                                                        })
                                                    }
                                                />
                                            );
                                        })}
                                    </PreviewCardsV2Grid>
                                    {hasMore ? (
                                        <Box marginTop="small">
                                            <ViewMoreLoaderButton
                                                fetchMore={() => {
                                                    fetchMore({
                                                        variables: {
                                                            offset: buddies.length,
                                                            randomizeListSeed,
                                                        },
                                                        updateQuery,
                                                    });
                                                    if (BUDDY_CARD_VERSION) {
                                                        buddiesListViewedMore({
                                                            buddyType: buddyGroupType,
                                                            pageNumber: Math.round(
                                                                buddies.length /
                                                                    STAFF_TO_FETCH_LIMIT,
                                                            ),
                                                            buddyDesign: BUDDY_CARD_VERSION,
                                                            buddyCardsCount: STAFF_TO_FETCH_LIMIT,
                                                        });
                                                    }
                                                }}
                                                showButton={hasMore}
                                                buddyType="STAFF"
                                            />
                                        </Box>
                                    ) : null}
                                </Box>
                            ) : (
                                errorJsx
                            )}
                        </div>
                    </ErrorDisplay>
                );
            }}
        </Query>
    );
}
