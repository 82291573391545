import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import ReactSlick from "react-slick";
import {Box, Text, ChevronDownIcon} from "@unibuddy/patron";
import {useBooleanFeatureFlag} from "ub-feature-flagging-react";
import styled, {keyframes} from "styled-components";
import {useTranslation} from "@unibuddy/intl";
import {NEXT_BEST_ACTIONS_FLAG} from "shared/constants";
import useAnalytics from "shared/AnalyticsNew/hooks/useAnalytics";
import {sharedEvents} from "shared/AnalyticsNew/constants/productSpecification";
import useErrorReporting from "shared/General/components/ErrorReporting/useErrorReporting";
import {useUniversity} from "../../UniversityProvider/UniversityProvider";
import {
    WIDGET_HEIGHT_COLLAPSED,
    WIDGET_HEIGHT_COLLAPSED_MOBILE,
    SMALL_SCREEN_WIDTH,
    MEDIUM_SCREEN_WIDTH,
    MAX_SLIDES_TO_SHOW,
    nbaResponsiveConfig,
    NEXT_BEST_ACTIONS_FLAG_DEFAULT,
} from "../NextBestActions";
import NextBestActionsCard from "../components/NextBestActionsCard";
import NextBestActionsLoading from "../components/NextBestActionsLoading";
import IconStar from "../../Icons/IconStar";
import {TickerHeader} from "../components/TickerHeader";
import useUniversityColor from "../../UniversityProvider/hooks/useUniversityColor";
import {usePage} from "../../PageProvider/PageContext";
import {WIDGET_PAGE} from "../../PageProvider/PageTitles";
import {
    useRecommendations,
    RECOMMENDATION_BANNER_BATCH_SIZE,
} from "../../Recommendations/RecommendationsProvider/RecommendationsProvider";
import IconChevronRight from "../../Icons/IconChevronRight";
import {IntlNamespaces} from "../../../../translations/types";
import {
    INTL_FOR_YOU,
    INTL_RECOMMENDATION_BANNER_TEXT,
    INTL_VIEW_MORE_TEXT,
} from "../../../../translations/recommendations";

const RecommendationCardCarousel = styled(ReactSlick)`
    overflow: hidden;
    padding-left: 12px;
    .slick-list {
        margin: 0 -10px;
        // css hack to show partial next card
        padding-left: 0 !important;
    }
    .slick-track {
        display: flex !important;
        padding-bottom: 12px;
    }
    .slick-slide {
        margin-right: 12px;
    }
    .slick-active > div {
        width: 100%;
    }
`;

const NextBestActionsContainer = styled(Box)`
    @media (max-width: ${SMALL_SCREEN_WIDTH}) {
        height: ${WIDGET_HEIGHT_COLLAPSED_MOBILE};
    }
`;

const slideDown = keyframes`
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const CardsContainer = styled(Box)`
    animation: ${slideDown} 0.3s ease-in-out;
    margin-right: 0;
    @media (min-width: ${MEDIUM_SCREEN_WIDTH}) {
        margin-right: 12px;
    }
`;

export const CardContainer = styled(Box)`
    width: 100%;
    padding: 16px;
    border-width: 1px;
    border-color: #d0d9d9;
    border-radius: 4px;
    @media (max-width: ${SMALL_SCREEN_WIDTH}) {
        height: 193px;
    }
`;

const ChevronIcon = styled(ChevronDownIcon)`
    font-size: 0.875rem;
    transform-origin: center;
    transform: ${({isOpen}) => (isOpen ? "rotate(-180deg)" : "rotate(0)")};
    transition: 0.2s ease transform;
`;

const ToggleSectionContainer = styled(Box)<{isOpen: boolean}>`
    border-radius: 4px;
    border-bottom-left-radius: ${(props) => (props.isOpen ? "0px" : "4px")};
    border-bottom-right-radius: ${(props) => (props.isOpen ? "0px" : "4px")};

    &:hover:not([disabled]) {
        cursor: ${(props) => (props.isOpen ? "default" : "pointer")};
        opacity: ${(props) => (props.isOpen ? "1" : "0.85")};
    }
`;

const ToggleSectionButtonContainer = styled(Box)<{isOpen: boolean}>`
    border-radius: 0px;
    border-top-left-radius: ${(props) => (props.isOpen ? "4px" : "0px")};
    border-top-right-radius: ${(props) => (props.isOpen ? "4px" : "0px")};
`;

const ToggleSectionContents = styled(Box)<{isOpen: boolean}>`
    border-radius: 4px;
    border-top-left-radius: ${(props) => (props.isOpen ? "0px" : "4px")};
    border-top-right-radius: ${(props) => (props.isOpen ? "0px" : "4px")};
`;

const StyledHeaderText = styled(Text)<{translation}>`
    display: none;
    @media (min-width: ${MEDIUM_SCREEN_WIDTH}) {
        display: block;
    }
`;

const StyledHeaderTextNarrow = styled(Text)`
    display: block;
    @media (min-width: ${MEDIUM_SCREEN_WIDTH}) {
        display: none;
    }
`;

const ViewMoreTextBlock = styled(Text)`
    &:hover {
        text-decoration: underline;
    }
`;

const StyleChevronRightIcon = styled(IconChevronRight)`
    margin-left: 5px;
    position: relative;
    top: 7px;
`;

type RecommendationStarIconProps = {
    color?: string;
};

const RecommendationStarIcon: React.FC<RecommendationStarIconProps> = (props) => (
    <Box w="19px" h="18px" paddingRight="xxsmall" marginRight="xxsmall">
        <IconStar color={props.color ?? "#FFFFFF"} />
    </Box>
);

export default function NextBestActions() {
    const {page} = usePage();
    const {getRecommendationsCallback, data, loading, error} = useRecommendations();
    const SHOW_NEXT_BEST_ACTIONS = useBooleanFeatureFlag(
        NEXT_BEST_ACTIONS_FLAG,
        NEXT_BEST_ACTIONS_FLAG_DEFAULT,
    );
    const currentPath: string = useLocation().pathname;
    const {t, ready} = useTranslation([IntlNamespaces.RECOMMENDATIONS]);

    const {reportError} = useErrorReporting();

    const {trackEvent} = useAnalytics();

    const [isOpen, setIsOpen] = useState(false);

    const {
        id: universityId,
        name: universityName,
        slug: universitySlug,
        color: universityColor,
    } = useUniversity();
    const color = useUniversityColor();

    const toggleRecommendationBanner = React.useCallback(() => {
        if (!isOpen) {
            trackEvent(sharedEvents.nextBestActionsToggleButtonClicked.name, {
                universityId,
                universityName,
                where: page,
            });
            if (page === WIDGET_PAGE.UNKNOWN) {
                reportError(`No mapping is found for the url: ${currentPath}`, {
                    tags: {universityId, universityName},
                });
            }
        }
        setIsOpen(!isOpen);
    }, [isOpen, page, currentPath, universityId, universityName, trackEvent, reportError]);

    const navigateToRecommendations = React.useCallback(() => {
        if (currentPath.toLowerCase().includes("pwa")) {
            return `/pwa/${universitySlug}/recommendations`;
        }
        return `/embed/${universitySlug}/colour/${universityColor}/recommendations`;
    }, [currentPath, universityColor, universitySlug]);

    useEffect(() => {
        if (!SHOW_NEXT_BEST_ACTIONS) {
            trackEvent(sharedEvents.nextBestActionsNotShown.name, {
                universityId,
                universityName,
            });
        } else {
            trackEvent(sharedEvents.nextBestActionsShown.name, {
                universityId,
                universityName,
            });
            getRecommendationsCallback(RECOMMENDATION_BANNER_BATCH_SIZE);
        }
    }, [
        getRecommendationsCallback,
        SHOW_NEXT_BEST_ACTIONS,
        trackEvent,
        universityId,
        universityName,
    ]);

    if (!SHOW_NEXT_BEST_ACTIONS) return null;

    if (loading || !ready) return <NextBestActionsLoading />;

    if (error) {
        reportError(error, {
            tags: {universitySlug},
        });
        return null;
    }

    if (!data || data.generateRecommendations.length === 0) {
        return null;
    }

    return (
        <NextBestActionsContainer
            position="relative"
            maxH={WIDGET_HEIGHT_COLLAPSED}
            w="100%"
            data-test-id="nba-container"
        >
            <ToggleSectionContainer
                borderWidth={1}
                borderRadius="xxsmall"
                borderColor={color}
                w="100%"
                borderBottomWidth={isOpen ? 0 : 1}
                isOpen={isOpen}
            >
                <ToggleSectionButtonContainer isOpen={isOpen} bgColor={isOpen ? "white" : color}>
                    <Box
                        role="button"
                        w="100%"
                        height={WIDGET_HEIGHT_COLLAPSED}
                        as="button"
                        aria-label={t(
                            INTL_RECOMMENDATION_BANNER_TEXT.key,
                            INTL_RECOMMENDATION_BANNER_TEXT.defaultValue,
                        )}
                        onClick={toggleRecommendationBanner}
                        borderRadius="xxsmall"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        data-test-id="recommended-for-you-btn"
                        aria-expanded={isOpen ? "true" : "false"}
                    >
                        <Box
                            display="flex"
                            w="80%"
                            flexDirection="row"
                            justifyContent="flex-start"
                            gap="medium"
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                marginLeft="small"
                            >
                                <RecommendationStarIcon color={isOpen ? color : "white"} />
                                <Box paddingLeft="xxsmall" display="flex">
                                    <StyledHeaderText
                                        role="heading"
                                        aria-level="2"
                                        transform="uppercase"
                                        size="small"
                                        color={isOpen ? color : "white"}
                                        weight="700"
                                        nowrap
                                    >
                                        {t(
                                            INTL_RECOMMENDATION_BANNER_TEXT.key,
                                            INTL_RECOMMENDATION_BANNER_TEXT.defaultValue,
                                        )}
                                    </StyledHeaderText>
                                    <StyledHeaderTextNarrow
                                        transform="uppercase"
                                        size="small"
                                        color={isOpen ? color : "white"}
                                        weight="700"
                                        nowrap
                                    >
                                        {t(INTL_FOR_YOU.key, INTL_FOR_YOU.defaultValue)}
                                    </StyledHeaderTextNarrow>
                                </Box>
                            </Box>
                            {isOpen ? null : (
                                <Box w="60%">
                                    <TickerHeader data={data} />
                                </Box>
                            )}
                        </Box>
                        <Box
                            w="20%"
                            marginRight="xsmall"
                            display="flex"
                            flexDirection="row-reverse"
                            alignItems="flex-end"
                            paddingRight="small"
                        >
                            <ChevronIcon isOpen={isOpen} color={isOpen ? color : "white"} />
                        </Box>
                    </Box>
                </ToggleSectionButtonContainer>
                <ToggleSectionContents
                    isOpen={isOpen}
                    w="100%"
                    display={isOpen ? "block" : "none"}
                    bgColor="white"
                    // z-index must be greater than 2 to avoid conflict with content page
                    zIndex={3}
                    borderWidth={1}
                    borderTopWidth={0}
                    borderColor={color}
                    position="absolute"
                    top={WIDGET_HEIGHT_COLLAPSED}
                    left={0}
                >
                    <CardsContainer marginLeft="small">
                        <RecommendationCardCarousel
                            dots={false}
                            rows={1}
                            speed={500}
                            slidesToScroll={1}
                            responsive={nbaResponsiveConfig}
                            arrows={false}
                            centerMode={false}
                            centerPadding="50px"
                            easing="ease"
                            slidesToShow={Math.min(
                                data?.generateRecommendations.length ?? 0,
                                MAX_SLIDES_TO_SHOW,
                            )}
                            autoplay={false}
                            swipeToSlide
                            infinite
                        >
                            {data?.generateRecommendations.map((content) => {
                                return (
                                    <CardContainer key={content.id}>
                                        <NextBestActionsCard
                                            contentId={content.contentId}
                                            title={content.title}
                                            description={content.description}
                                            contentType={content.type}
                                            url={content.url}
                                            batchId={content.batchId}
                                        />
                                    </CardContainer>
                                );
                            })}
                        </RecommendationCardCarousel>
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                            marginBottom="small"
                        >
                            <Link to={navigateToRecommendations} data-test-id="view-more-link">
                                <ViewMoreTextBlock weight="600" color={color}>
                                    {t(INTL_VIEW_MORE_TEXT.key, INTL_VIEW_MORE_TEXT.defaultValue)}
                                    <StyleChevronRightIcon color={color} />
                                </ViewMoreTextBlock>
                            </Link>
                        </Box>
                    </CardsContainer>
                </ToggleSectionContents>
            </ToggleSectionContainer>
        </NextBestActionsContainer>
    );
}
