import React, {useEffect, useState} from "react";
import {ApolloError} from "apollo-client";
import {useHistory, useLocation} from "react-router";
import styled from "styled-components";
import {Heading, Text, Box, Stack, ButtonLink} from "@unibuddy/patron";
import {IconArrowRight} from "@unibuddy/icons";
import {useTranslation} from "@unibuddy/intl";

import useAnalytics from "shared/AnalyticsNew/hooks/useAnalytics";
import {sharedEvents} from "shared/AnalyticsNew/constants/productSpecification";
import {rootURL} from "shared/Utils/WhereAmI";

import {StaffBuddycard} from "../../../BuddiesNew/containers/StaffBuddycard";
import {useUniversity} from "../../../UniversityProvider/UniversityProvider";
import {BuddiesField, BuddyField} from "../../../../../types/generated";
import {WIDGET_PAGE} from "../../../PageProvider/PageTitles";
import {IntlNamespaces} from "../../../../../translations/types";
import {INTL_CHAT_TO_STAFF, INTL_VIEW_MORE_STAFF} from "../../../../../translations/common";

const StyleIconArrowRight = styled(IconArrowRight)`
    margin-left: 5px;
    position: relative;
`;

const StyledStaffContainer = styled(Box)`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
    @media (width >= 632px) {
        grid-template-columns: 1fr 1fr;
    }
`;

export type StaffSectionProps = {
    data?: {buddies: BuddiesField | null};
    loading: boolean;
    error?: ApolloError;
};

export function StaffSection({data, loading = false, error}: StaffSectionProps) {
    const {slug} = useUniversity();
    const {trackEvent} = useAnalytics();
    const history = useHistory();
    const location = useLocation();
    const {t} = useTranslation(IntlNamespaces.COMMON);
    const staffUrl = `${rootURL(location.pathname)}/buddies/staff`;
    const staffData = data?.buddies?.users || [];
    const [numberOfCardsShown, setNumberOfCardsShown] = useState(1);

    const updateItemsToShow = () => {
        const width = window.innerWidth;
        if (width < 632) {
            setNumberOfCardsShown(1);
        } else {
            setNumberOfCardsShown(2);
        }
    };

    useEffect(() => {
        updateItemsToShow();

        window.addEventListener("resize", updateItemsToShow);

        return () => {
            window.removeEventListener("resize", updateItemsToShow);
        };
        // Empty deps as we only want to initialise the listener once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSearchParamsWithSourceTracking = (buddyPosition: number) => {
        const params = new URLSearchParams(window.location.search);
        params.set("buddyPosition", buddyPosition.toString());
        params.set("buddyGroupType", "staff");
        return params.toString();
    };

    const onAvatarClicked = ({
        buddyId,
        buddyPosition,
    }: {
        buddyId: string;
        buddyPosition: number;
    }) => {
        const params = getSearchParamsWithSourceTracking(buddyPosition);
        trackEvent(sharedEvents.buddyCardAvatarClicked.name, {
            universitySlug: slug,
            position: buddyPosition,
            buddyType: "staff",
            tab: WIDGET_PAGE.WIDGET_STUDENTS,
        });

        history.push(`${rootURL(location.pathname)}/buddies/staff/${buddyId}?${params}`);
    };

    const onChatNow = ({buddyId, buddyPosition}: {buddyId: string; buddyPosition: number}) => {
        const params = getSearchParamsWithSourceTracking(buddyPosition);
        trackEvent(sharedEvents.buddyCardChatNowClicked.name, {
            universitySlug: slug,
            position: buddyPosition,
            buddyType: "staff",
            tab: WIDGET_PAGE.WIDGET_STUDENTS,
        });

        history.push(`${rootURL(location.pathname)}/inbox/chatwith/${buddyId}?${params}`);
    };

    const onSeeMore = ({buddyId, buddyPosition}: {buddyId: string; buddyPosition: number}) => {
        const params = getSearchParamsWithSourceTracking(buddyPosition);
        history.push(`${rootURL(location.pathname)}/buddies/staff/${buddyId}?${params}`);
    };

    const onViewMoreClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        trackEvent(sharedEvents.viewMoreButtonClicked.name, {
            universitySlug: slug,
            sectionType: "staff",
            tab: WIDGET_PAGE.WIDGET_STUDENTS,
        });
        history.push(staffUrl);
    };

    if (loading) return <Text>Loading...</Text>;

    if (error) return null;

    if (!staffData?.length) return null;

    return (
        <Stack space="small-medium">
            <Heading level="2" size="xsmall">
                {t(INTL_CHAT_TO_STAFF.key, INTL_CHAT_TO_STAFF.defaultValue)}
            </Heading>
            <Stack space="xsmall">
                <Box display="flex" flexDirection="row">
                    <StyledStaffContainer width="100%" marginBottom="xsmall">
                        {staffData
                            .slice(0, numberOfCardsShown)
                            .map((buddy: BuddyField, buddyPosition: number) => {
                                return (
                                    <StaffBuddycard
                                        key={buddy.id}
                                        buddy={buddy}
                                        onChatNow={() =>
                                            onChatNow({buddyId: buddy.id, buddyPosition})
                                        }
                                        onSeeMore={() =>
                                            onSeeMore({buddyId: buddy.id, buddyPosition})
                                        }
                                        rootUrl={rootURL(location.pathname)}
                                        onAvatarClicked={() =>
                                            onAvatarClicked({buddyId: buddy.id, buddyPosition})
                                        }
                                    />
                                );
                            })}
                    </StyledStaffContainer>
                </Box>
                <ButtonLink
                    round
                    block
                    clear
                    to={staffUrl}
                    onClick={onViewMoreClick}
                    color="university"
                >
                    {t(INTL_VIEW_MORE_STAFF.key, INTL_VIEW_MORE_STAFF.defaultValue)}
                    <StyleIconArrowRight color="university" />
                </ButtonLink>
            </Stack>
        </Stack>
    );
}
