import React from "react";
import {Box} from "@unibuddy/patron";
import styled from "styled-components";

import {AppContainer} from "../Containers/AppContainer";

type BrandedHeaderProps = {
    logo: string;
    websiteUrl: string;
    colourCode?: string;
};

const StyledImg = styled.img`
    height: 40px;
`;

const StyledAnchor = styled.a`
    color: white;
    font-size: 0.875rem;
    margin-right: 16px;
    text-align: right;

    &:hover {
        color: white;
        font-size: 0.875rem;
        margin-right: 16px;
        text-align: right;
    }
`;

export const BrandedHeader = ({logo, websiteUrl, colourCode = "01D4CF"}: BrandedHeaderProps) => (
    <Box h="70px" bgColor={`#${colourCode}`} display="flex">
        <AppContainer display="flex" alignItems="center" justifyContent="space-between" flex="1">
            <StyledImg src={logo} alt="University Logo" />
            <StyledAnchor href={websiteUrl} rel="noopener noreferrer" target="_blank">
                View on website
            </StyledAnchor>
        </AppContainer>
    </Box>
);
