import React, {Suspense, useEffect} from "react";
import {Query} from "react-apollo";
import get from "lodash/get";
import {useTranslation} from "@unibuddy/intl";
import {Box} from "@unibuddy/patron";

/** Machop Components */
import {ErrorDisplay} from "shared/General/components/ErrorDisplay/ErrorDisplay";
import useWindowTitle from "shared/Experimental/hooks/useWindowTitle";
import {rootURL} from "shared/Utils/WhereAmI";
import {BackButton} from "shared/General/components/BackButton/BackButton";
import BuddyCardSection from "shared/Mentor/components/MentorCardSection/MentorCardSection";
import BuddyCardSectionItem from "shared/Mentor/components/MentorCardSectionItem/MentorCardSectionItem";
import BuddyCardSeparator from "shared/Mentor/components/MentorCardSeparator/MentorCardSeparator";
import ChatNowButton from "shared/Blog/components/ChatNowButton/ChatNowButton";
import BuddyCardSubtitle from "shared/Mentor/components/MentorCardDegree/MentorCardDegree";
import BuddyCardName from "shared/Mentor/components/MentorCardName/MentorCardName";
import {ShareButton} from "shared/General/components/ShareButton/ShareButton";
import BuddyCardBanner from "shared/Mentor/components/MentorCardBanner/MentorCardBanner";
import {HasUniversityFeature} from "shared/Feature/HasUniversityFeature";
import OtherSection from "shared/Mentor/components/sections/OtherSection/OtherSection";
import useAnalytics from "shared/AnalyticsNew/hooks/useAnalytics";
import {isFeatureEnabled} from "shared/Utils/FeatureUtils";

/** Query */
import buddiesProfileQuery from "../../queries/buddiesProfileQuery";

/** Custom components */
import {AmbassadorRemovedCard} from "../../../../shared/components/AmbassadorRemovedCard/AmbassadorRemovedCard";
import {hasAmbassadorRemovedError} from "../../../../shared/components/AmbassadorRemovedCard/helpers";

/** CSS Styles */
import s from "./BuddiesProfile.pcss";

/** Lazy comp */
const MentorCardBlogPosts = React.lazy(() =>
    import("shared/Mentor/components/MentorCardBlogPosts/MentorCardBlogPosts"),
);

//--------------------------------------------------------------------------------

const BuddyProfileWrapper = ({buddyType, buddyId, children, universitySlug}) => {
    const {
        events: {buddyViewed},
    } = useAnalytics();
    useEffect(() => {
        buddyViewed({
            id: buddyId,
            buddyType,
            universitySlug,
        });
    }, [buddyViewed, buddyId, universitySlug, buddyType]);

    return <React.Fragment>{children}</React.Fragment>;
};

export default function BuddyProfile({match, history, location, university}) {
    const title = "Buddy Profile";
    const rootUrl = rootURL(match.url);
    const buddyId = get(match, "params.buddyId");
    const buddyType = get(match, "params.buddyGroupType");
    const isBlogsEnabled = isFeatureEnabled("userGeneratedContent", university);
    const {t} = useTranslation("mentorCard");

    useWindowTitle(title);

    return (
        <Query
            variables={{id: buddyId, isBlogsEnabled}}
            query={buddiesProfileQuery}
            fetchPolicy="cache-first"
        >
            {({loading, data, error}) => {
                if (loading) {
                    return null;
                }
                const buddy = get(data, "buddy", {});
                const customAttributes = buddy.customAttributes || [];
                const hasCustomAttributes = customAttributes.filter((a) => !!a.value).length > 0;
                const posts = get(data, "mentorPosts", []);
                const buddyName = get(buddy, "firstName", "");

                if (!data && hasAmbassadorRemovedError(error)) {
                    return <AmbassadorRemovedCard university={university} />;
                }

                return (
                    <ErrorDisplay error={error}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            paddingBottom="small-medium"
                        >
                            <BackButton />
                            <ShareButton onCopy={() => null} shareUrl={buddy?.shareUrl ?? ""} />
                        </Box>
                        <BuddyProfileWrapper
                            buddyType={buddyType}
                            buddyId={buddyId}
                            universitySlug={get(match, "params.universitySlug")}
                        >
                            <div className={s.container} data-test-id="buddy-card">
                                <BuddyCardBanner
                                    name={buddyName}
                                    profilePhoto={get(buddy, "profilePhoto", "")}
                                    imageClickable={false}
                                />

                                <BuddyCardName name={buddyName} />

                                <BuddyCardSubtitle degree={get(buddy, "staffFields.role", "")} />
                                <BuddyCardSubtitle
                                    degree={get(buddy, "staffFields.department", "")}
                                />
                                {get(buddy, "active") && (
                                    <div className={s.chatNow}>
                                        <ChatNowButton
                                            onChatNow={() =>
                                                history.push(
                                                    `${rootUrl}/inbox/chatwith/${get(
                                                        buddy,
                                                        "id",
                                                        "",
                                                    )}`,
                                                )
                                            }
                                            name={buddyName}
                                            data-test-id={"buddy-card-chat-with"}
                                        />
                                    </div>
                                )}
                                <BuddyCardSeparator />
                                {hasCustomAttributes ? (
                                    <HasUniversityFeature
                                        data-test-id="customAttributesSection"
                                        universitySlug={get(buddy, "university.slug", "")}
                                        feature="customAmbassadorAttributes"
                                    >
                                        <OtherSection customAttributes={customAttributes} />
                                    </HasUniversityFeature>
                                ) : null}
                                <BuddyCardSection title={t("roleDescription", "Role Description")}>
                                    <BuddyCardSectionItem>
                                        {get(buddy, "staffFields.bio", "")}
                                    </BuddyCardSectionItem>
                                </BuddyCardSection>
                                <BuddyCardSeparator />
                                <Suspense fallback={null}>
                                    <MentorCardBlogPosts
                                        blogPosts={posts}
                                        onSelectPost={() => {}}
                                        baseUrl={rootURL(location.pathname)}
                                    />
                                </Suspense>
                            </div>
                        </BuddyProfileWrapper>
                    </ErrorDisplay>
                );
            }}
        </Query>
    );
}
