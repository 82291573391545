import useErrorReporting from "shared/General/components/ErrorReporting/useErrorReporting";

export function usePostMessage() {
    const urlParams = new URLSearchParams(window.location.search);
    const parentUrl = urlParams.get("parentUrl") ?? "*";
    const {reportError} = useErrorReporting();

    const postMessage = (message: string) => {
        try {
            window.parent.postMessage(message, parentUrl.startsWith("file://") ? "*" : parentUrl);
        } catch (error) {
            reportError(
                new Error(`Error posting message from usePostMessage hook: ${error as string}`),
            );
        }
    };

    return {
        postMessage,
    };
}
