import React from "react";
import {Box, Heading, Shimmer, Stack, Text, View} from "@unibuddy/patron";
import {useTranslation} from "@unibuddy/intl";
import {CardButtonLink, Flag} from "@unibuddy/buddycard-ui";

import {lastSeenDate} from "./StudentBuddycard";
import {getInfoArray} from "../../Mentor/utils/getInfoArray";
import {useUniversity} from "../../UniversityProvider/UniversityProvider";
import {MentorField, MentorFilterOptionField} from "../../../../types/generated";
import {getenv} from "../../../../util/getEnv";
import {CardAvatarClickHandler} from "../components/CardAvatarClickHandler";

export type PolaroidBuddycardProps = {
    mentor: MentorField;
    handleAvatarClicked: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    rootUrl: string;
    handleChatButtonLink: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    chatLink: string;
    universityDegreeLevels: MentorFilterOptionField[];
};

export function PolaroidBuddycard({
    mentor,
    handleAvatarClicked,
    rootUrl,
    handleChatButtonLink,
    chatLink,
    universityDegreeLevels,
}: PolaroidBuddycardProps) {
    const {t} = useTranslation("mentorCard");
    const university = useUniversity();
    const isDegreeTypesEnabled = university?.features.get("enableDegreeTypes") ?? false;
    const isShowCountryFlagEnabled = university?.features.get("showCountryFlag") ?? false;
    const ASSETS_URL: string = getenv("ASSETS_URL", "") as string;
    const params = new URLSearchParams(window.location.search);
    const profileLink = `${rootUrl}/buddies/students/${mentor.id}?${params.toString()}`;
    const recentActivityStatus = lastSeenDate(mentor.lastSeen, t);
    let truncatedInfoArray = [];

    const infoArray = getInfoArray(
        mentor,
        university?.isEnterprise ?? false,
        mentor?.university?.name ?? "",
        universityDegreeLevels,
        isDegreeTypesEnabled,
    );
    if (infoArray.length > 1) {
        truncatedInfoArray = [
            {...infoArray[0], color: "grey900", numberOfLines: 1},
            {...infoArray[1], color: "grey600", numberOfLines: 1},
        ];
    }
    const hasValidPrimaryFlag =
        isShowCountryFlagEnabled &&
        !!mentor?.mentorFields?.primaryFlag?.code &&
        !!mentor?.mentorFields?.primaryFlag?.name;
    const hasValidSecondaryFlag =
        isShowCountryFlagEnabled &&
        !!mentor?.mentorFields?.secondaryFlag?.code &&
        !!mentor?.mentorFields?.secondaryFlag?.name;

    return (
        <View
            borderRadius="small-medium"
            overflow="hidden"
            bgColor="white"
            borderWidth={1}
            borderColor="grey150"
            w="100%"
            gap="small-medium"
            padding="small"
        >
            <CardAvatarClickHandler
                link={profileLink}
                handleClick={handleAvatarClicked}
                ariaLabel={`Mentor ${mentor.firstName ?? ""}`}
            >
                <Box h="208px" position="relative">
                    <img
                        src={`${ASSETS_URL}/${mentor.profilePhoto}`}
                        alt={`Ambassador ${mentor.firstName ?? ""} avatar`}
                        style={{
                            borderRadius: "8px",
                            height: "208px",
                            width: "100%",
                            objectFit: "cover",
                            objectPosition: "center",
                        }}
                    />
                    {recentActivityStatus && (
                        <Box
                            position="absolute"
                            bgColor="white"
                            borderRadius="full"
                            bottom={8}
                            left={8}
                            padding="xsmall"
                        >
                            <View justifyContent="center" alignItems="center">
                                <Text size="xsmall">{recentActivityStatus}</Text>
                            </View>
                        </Box>
                    )}
                </Box>
            </CardAvatarClickHandler>
            <Stack space="small">
                <Box display="flex" gap="xsmall">
                    <Heading size="xsmall" level="2" truncate>
                        {mentor.firstName}
                    </Heading>
                    <Box display="flex" gap="xxsmall" alignItems="flex-end" flexShrink={0}>
                        {hasValidPrimaryFlag && (
                            <Flag
                                countryCode={mentor?.mentorFields?.primaryFlag?.code}
                                alt={`Primary country flag ${mentor?.mentorFields?.primaryFlag?.name}`}
                                height={12}
                                width={16}
                                borderWidth={0}
                                rounded={false}
                            />
                        )}
                        {hasValidSecondaryFlag && (
                            <Flag
                                countryCode={mentor?.mentorFields?.secondaryFlag?.code}
                                alt={`Secondary country flag ${mentor?.mentorFields?.secondaryFlag?.name}`}
                                height={12}
                                width={16}
                                borderWidth={0}
                                rounded={false}
                            />
                        )}
                    </Box>
                </Box>
                {truncatedInfoArray?.map((item: {color: string; text: string}, index: number) => {
                    return (
                        <Text
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${mentor.firstName} info ${index.toString()}`}
                            size="small"
                            truncate
                            color={item.color ?? "grey900"}
                        >
                            {item.text}
                        </Text>
                    );
                })}
            </Stack>
            <CardButtonLink to={chatLink} color="university" handleClick={handleChatButtonLink}>
                {t("chatWith", `Chat with ${mentor.firstName}`, {name: mentor.firstName})}
            </CardButtonLink>
        </View>
    );
}

export function PolaroidBuddycardLoading() {
    return (
        <View
            borderRadius="small-medium"
            overflow="hidden"
            bgColor="white"
            borderWidth={1}
            borderColor="grey150"
            w="100%"
            padding="small"
            gap="small-medium"
        >
            <Box height="208px" width="100%" borderRadius="xsmall" overflow="hidden">
                <Shimmer
                    viewBox="0 0 640 208"
                    preserveAspectRatio="none"
                    style={{display: "block", width: "100%", height: "208px"}}
                >
                    <rect x="0" y="0" width="640" height="208" />
                </Shimmer>
            </Box>
            <Stack space="small">
                <Box height="15px" width="100%" maxW="140px" borderRadius="full" overflow="hidden">
                    <Shimmer
                        viewBox="0 0 140 15"
                        preserveAspectRatio="none"
                        style={{display: "block", width: "100%", height: "15px", maxWidth: "140px"}}
                    >
                        <rect x="0" y="0" width="140" height="15" />
                    </Shimmer>
                </Box>
                <Box height="9px" width="100%" maxW="180px" borderRadius="full" overflow="hidden">
                    <Shimmer
                        viewBox="0 0 180 9"
                        preserveAspectRatio="none"
                        style={{display: "block", width: "100%", height: "9px", maxWidth: "180px"}}
                    >
                        <rect x="0" y="0" width="180" height="9" />
                    </Shimmer>
                </Box>
                <Box height="9px" width="100%" maxW="220px" borderRadius="full" overflow="hidden">
                    <Shimmer
                        viewBox="0 0 220 9"
                        preserveAspectRatio="none"
                        style={{display: "block", width: "100%", height: "9px", maxWidth: "220px"}}
                    >
                        <rect x="0" y="0" width="220" height="9" />
                    </Shimmer>
                </Box>
            </Stack>
            <Box height="38px" width="100%" borderRadius="full" overflow="hidden">
                <Shimmer
                    viewBox="0 0 640 38"
                    preserveAspectRatio="none"
                    style={{display: "block", width: "100%", height: "38px"}}
                >
                    <rect x="0" y="0" width="640" height="38" />
                </Shimmer>
            </Box>
        </View>
    );
}
