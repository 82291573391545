import React from "react";
import compose from "lodash/flowRight";
import {connect} from "react-redux";
import {withTranslation} from "@unibuddy/intl";
import {useParams} from "react-router";
import {withApollo} from "react-apollo";
import {Box, SkipNavLink} from "@unibuddy/patron";

import AnalyticsValue from "shared/AnalyticsNew/AnalyticsValue/AnalyticsValue";
import withIdentify from "shared/Auth/withIdentify";
import withAnalytics from "shared/AnalyticsNew/withAnalytics/withAnalytics";
import {productSpec} from "shared/AnalyticsNew/constants/productSpecification";

import {useUniversity} from "../../../../widgets/modules/UniversityProvider/UniversityProvider";
import ProspectWidgetRoutes from "./ProspectWidgetRoutes";
import {Navigation} from "../../../modules/Navigation/container/Navigation";
import {AppContainer} from "../../../shared/components/Containers/AppContainer";
import {ContentContainer} from "../../../shared/components/Containers/ContentContainer";
import {Footer} from "../../../shared/components/Footer/Footer";
import {BrandedHeader} from "../../../shared/components/BrandedHeader/BrandedHeader";
import {IntlNamespaces} from "../../../../translations/types";
import {useInitialProductAnalytics} from "./hooks/useInitialProductAnalytics";

type ProductOpenArgument = {
    name: string;
    universitySlug: string;
    ubLang: string;
};
type ProductVisibleArgument = {
    name: string;
    universitySlug: string;
    visibleTimeInMs: number;
    visiblePercentage: number;
};

type IWidgetProps = {
    analytics: {
        events: {
            productOpened: (properties: ProductOpenArgument) => void;
            productVisible: (properties: ProductVisibleArgument) => void;
        };
    };
    i18n: {
        language?: string;
    };
};

export const ProspectWidgetComponent: React.FC<IWidgetProps> = (props) => {
    useInitialProductAnalytics({
        i18nLanguage: props.i18n.language,
    });

    const params = useParams<{universitySlug?: string}>();
    const universitySlug = params?.universitySlug;
    const university = useUniversity();

    return (
        <AppContainer id="jigglypuff" data-test-id="main-widget" backgroundColor="white">
            <Box style={{display: "none", visibility: "hidden"}}>
                <BrandedHeader
                    logo={university?.whiteLogo}
                    websiteUrl={university?.widgetUrl}
                    colourCode={university?.colour}
                />
            </Box>
            <AnalyticsValue
                name={productSpec.embeddedUniversityWidget.properties.UNIVERSITY_SLUG}
                value={universitySlug}
            />
            <AnalyticsValue
                name={productSpec.embeddedUniversityWidget.properties.PRODUCT}
                value={productSpec.embeddedUniversityWidget.NAME}
            />
            <SkipNavLink contentId={"content"} />
            <Navigation primaryApp="university-jigglypuff" {...props} university={university} />
            <ContentContainer>
                <ProspectWidgetRoutes
                    university={university}
                    universitySlug={universitySlug}
                    primaryApp="university-jigglypuff"
                    {...props}
                />
            </ContentContainer>
            <Footer />
        </AppContainer>
    );
};

const mapStateToProps = (state: {appState: any}): {appState: any} => ({
    appState: state.appState,
});

export default compose(
    connect(mapStateToProps),
    withIdentify,
    withTranslation(IntlNamespaces.COMMON),
    withAnalytics,
    withApollo,
)(ProspectWidgetComponent);
